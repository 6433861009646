import { firestore } from '@/firebase'
import { apolloClient } from '@/gql/authenticatedClient'
import i18n from '@/i18n'

const state = {
  itemInvitations: [],
  itemPermissions: {},
  itemPermissionsLoaded: false,
  myInvitations: [],
  companyInvitations: [],
  permissionObject: null,
}

const getters = {
  itemInvitations: (state) => state.itemInvitations,
  itemPermissions: (state) => state.itemPermissions,
  itemPermissionsLoaded: (state) => state.itemPermissionsLoaded,
  myInvitations: (state) => state.myInvitations,
  companyInvitations: (state) => state.companyInvitations,
  permissionObject: (state) => state.permissionObject,
}

const mutations = {
  SET_ITEMINVITATIONS(state, arr) {
    state.itemInvitations = arr
  },
  SET_ITEMPERMISSIONS(state, obj) {
    state.itemPermissions = obj
  },
  SET_MYINVITATIONS(state, arr) {
    state.myInvitations = arr
  },
  SET_PERMISSION_OBJECT(state, obj) {
    state.permissionObject = obj
  },
  SET_ITEMPERMISSIONSLOADED(state, val) {
    state.itemPermissionsLoaded = val
  },
  SET_COMPANYINVITATIONS(state, val) {
    state.companyInvitations = val
  },
}

let itemInvitationsUnsubscribe
let itemPermissionsUnsubscribe
let myInvitationsUnsubscribe
let companyInvitationsUnsubscribe

const actions = {
  async acceptInvitation({ dispatch }, payload) {
    console.log('Accepting invitation!', payload)
    dispatch('addToast', {
      message: i18n.t('permissions:toast.acceptingInvitation.' + payload.type),
      type: 'pending',
    })
    try {
      await apolloClient.acceptInvitations({ input: { ids: [payload.id] } })
      dispatch('addToast', {
        message: i18n.t('permissions:toast.acceptedInvitation.' + payload.type),
        type: 'good',
      })
      return
    } catch (error) {
      console.log(error)
      dispatch('addToast', {
        message: i18n.t('permissions:toast.acceptInvitationFailed') + error,
        type: 'bad',
      })
    }
  },
  async declineInvitation({ dispatch }, payload) {
    console.log('Declining invitation!', payload.code)
    dispatch('addToast', {
      message: i18n.t('permissions:toast.decliningInvitation.' + payload.type),
      type: 'pending',
    })
    try {
      await apolloClient.rejectInvitation({ input: { id: payload.id } })
      dispatch('addToast', {
        message: i18n.t('permissions:toast.declinedInvitation.' + payload.type),
        type: 'good',
      })
      return
    } catch (error) {
      console.log(error)
      dispatch('addToast', {
        message: i18n.t('permissions:toast.declineInvitationFailed') + error,
        type: 'bad',
      })
    }
  },
  async deleteInvitation({ dispatch }, payload) {
    console.log('Delete invitation', payload)
    if (payload.type != null) {
      dispatch('addToast', {
        message: i18n.t('permissions:toast.removingInvitation.' + payload.type),
        type: 'pending',
      })
    }
    try {
      await apolloClient.deleteInvitation({ input: { id: payload.id } })
      if (payload.type != null) {
        dispatch('addToast', {
          message: i18n.t(
            'permissions:toast.removedInvitation.' + payload.type
          ),
          type: 'good',
        })
      }
    } catch (error) {
      console.log(error)
      dispatch('addToast', {
        message: i18n.t('permissions:toast.removeInvitationFailed') + error,
        type: 'bad',
      })
    }
  },
  subscribeToItemInvitations({ commit }, payload) {
    // console.log("Subscribing to invitations", payload);
    itemInvitationsUnsubscribe = firestore
      .collection('invitations')
      .where('archived', '==', false)
      .where('itemId', '==', payload.itemId)
      .where('type', '==', payload.itemType)
      .onSnapshot((querySnapshot) => {
        const all = querySnapshot.docs
          .map((doc) => {
            return { ...doc.data(), id: doc.id }
          })
          .sort((a, b) => b.timestamp - a.timestamp)
        commit('SET_ITEMINVITATIONS', all)
      })
  },
  unsubscribeToItemInvitations({ commit }) {
    commit('SET_ITEMINVITATIONS', [])
    itemPermissionsUnsubscribe()
  },
  subscribeToItemPermissions({ commit }, payload) {
    itemPermissionsUnsubscribe = firestore
      .collection(payload.itemType)
      .doc(payload.itemId)
      .onSnapshot((doc) => {
        commit('SET_ITEMPERMISSIONS', doc.data().permissions || {})
        commit('SET_ITEMPERMISSIONSLOADED', true)
      })
  },
  unsubscribeToItemPermissions({ commit }) {
    commit('SET_ITEMPERMISSIONS', {})
    commit('SET_ITEMPERMISSIONSLOADED', false)
    itemPermissionsUnsubscribe()
  },
  subscribeToMyInvitations({ commit, rootState }, payload) {
    myInvitationsUnsubscribe = firestore
      .collection('invitations')
      .where('recipient.id', '==', rootState.User.user.uid)
      .where('archived', '==', false)
      .onSnapshot((querySnapshot) => {
        const all = querySnapshot.docs
          .map((doc) => {
            return { ...doc.data(), id: doc.id }
          })
          .sort((a, b) => b.timestamp - a.timestamp)
        commit('SET_MYINVITATIONS', all)
      })
  },
  unsubscribeToMyInvitations({ commit }) {
    commit('SET_MYINVITATIONS', [])
    myInvitationsUnsubscribe()
  },
  async getItemInvitations({ commit }, payload) {
    const invitations = await firestore
      .collection('invitations')
      .where('archived', '==', false)
      .where('itemId', '==', payload.itemId)
      .where('type', '==', payload.itemType)
      .get()

    const invitationDocs = invitations.docs
      .map((doc) => {
        return { ...doc.data(), id: doc.id }
      })
      .sort((a, b) => b.timestamp - a.timestamp)
    commit('SET_ITEMINVITATIONS', invitationDocs)
    return invitations
  },
  async removePermissions(context, payload) {
    try {
      console.log('removePermissions', payload)
      if (payload.type === 'inspections') {
        apolloClient.removeInspectionPermissions({
          input: { inspectionId: payload.itemId, userIds: payload.userIds },
        })
      } else if (payload.type === 'projects') {
        apolloClient.removeProjectPermissions({
          input: { projectId: payload.itemId, userIds: payload.userIds },
        })
      }
    } catch (error) {
      console.log(error)
    }
  },
  openObjectPermissions({ dispatch, commit }, payload) {
    commit('SET_PERMISSION_OBJECT', payload)
    dispatch('subscribeToItemPermissions', {
      itemId: payload.id,
      itemType: payload.itemType,
    })
    dispatch('subscribeToItemInvitations', {
      itemId: payload.id,
      itemType: payload.itemType,
    })
    dispatch('addPanel', { id: 'panel-object-permissions' })
  },
  resetObjectPermissions({ commit, dispatch }) {
    dispatch('unsubscribeToItemInvitations')
    dispatch('unsubscribeToItemPermissions')
    commit('SET_PERMISSION_OBJECT', null)
  },
  async getCompanyInvitations({ rootState }) {
    const invitationQuery = await firestore
      .collection('invitations')
      .where('sender.company.id', '==', rootState.User.user.companyId) // change .where('sender.company.id', '==', rootState.User.user.companyId) .where('sender.id', '==', rootState.User.user.uid)
      .get()
    return invitationQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
  },
  subscribeToCompanyInvitations({ commit, rootState }, { itemType }) {
    companyInvitationsUnsubscribe = firestore
      .collection('invitations')
      .where('sender.company.id', '==', rootState.User.user.companyId)
      .where('type', '==', itemType)
      .where('archived', '==', false)
      .onSnapshot((querySnapshot) => {
        const all = querySnapshot.docs
          .map((doc) => {
            return { ...doc.data(), id: doc.id }
          })
          .sort((a, b) => b.timestamp - a.timestamp)
        commit('SET_COMPANYINVITATIONS', all)
      })
  },
  unsubscribeToCompanyInvitations({ commit }) {
    commit('SET_COMPANYINVITATIONS', [])
    if (typeof companyInvitationsUnsubscribe === 'function') {
      companyInvitationsUnsubscribe()
    }
  },
}

export default {
  namespaced: false,
  state,
  getters,
  mutations,
  actions,
}
