import { firestore } from '@/firebase'
import { apolloClient } from '@/gql/authenticatedClient'
import { isNilOrEmpty } from '@/utils/isNilOrEmpty'
import { nanoid } from 'nanoid'
import { buildQuery } from '../vuexHelpers'

let assignmentSubscription
let assignmentByIdSubscription

const state = {
  assignments: [],
  assignment: null,
}
const mutations = {
  SET_ASSIGNMENTS(state, payload) {
    state.assignments = payload
  },
  SET_ASSIGNMENT(state, payload) {
    state.assignment = payload
  },
}

const getters = {
  getAssignments: (state) => state.assignments,
  getAssignment: (state) => state.assignment,
}

const actions = {
  async fetchAssignments({ commit, rootState }, { inspectionId, projectId }) {
    try {
      let query = firestore
        .collection('assignments')
        .where('archived', '==', false)
        .where('inspectionId', '==', inspectionId)
        .where('projectId', '==', projectId)
      query = buildQuery(query, { rootState })

      const assignments = await query.get()
      const all = assignments.docs.map((doc) => {
        return { ...doc.data(), id: doc.id }
      })
      return all
    } catch (err) {
      console.log('Could not fetch assignments', err)
    }
  },
  async subscribeToAssignments(
    { commit, rootState },
    { inspectionId, projectId }
  ) {
    try {
      let query = firestore
        .collection('assignments')
        .where('archived', '==', false)
        .where('inspectionId', '==', inspectionId)
        .where('projectId', '==', projectId)
      query = buildQuery(query, { rootState })

      assignmentSubscription = query.onSnapshot((querySnapshot) => {
        const all = querySnapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id }
        })
        commit('SET_ASSIGNMENTS', all)
      })
    } catch (err) {
      console.log('Could not subscribe to assignments', err)
    }
  },
  unsubscribeToAssignments({ commit }) {
    try {
      assignmentSubscription()
      commit('SET_ASSIGNMENTS', [])
    } catch {
      console.log('Nothing to unsubscribe')
    }
  },
  subscribeToAssignmentById({ commit }, { assignmentId }) {
    try {
      const query = firestore.collection('assignments').doc(assignmentId)
      assignmentByIdSubscription = query.onSnapshot((doc) => {
        commit('SET_ASSIGNMENT', { ...doc.data(), id: doc.id })
      })
    } catch (err) {
      throw new Error(err)
    }
  },
  unsubscribeToAssignmentById({ commit }) {
    try {
      assignmentByIdSubscription()
      commit('SET_ASSIGNMENT', null)
    } catch {
      console.log('Nothing to unsubscribe')
    }
  },
  async createNoneCaptureAssignment(
    context,
    { inspection, company, assignee, noneCaptureTasks }
  ) {
    const noneCaptures = noneCaptureTasks
      .map((task) => {
        const registrationDrawing = inspection.images.find(
          (img) => img.imageId === task.drawingId
        )

        if (!registrationDrawing) {
          return null
        }
        // Construct new capture.
        const capture = {
          id: nanoid(),
          companyId: company.id,
          xPercentage: task.posXpercentage,
          yPercentage: task.posYpercentage,
          inspectionId: inspection.id,
          inspectionTitle: inspection.title,
          note: task.note,
          projectId: inspection.projectId,
          projectTitle: inspection.projectTitle,
          registrationDrawing: {
            imageId: registrationDrawing.imageId,
            imageMeta: {
              fullPath: registrationDrawing.imageMeta.fullPath,
              name: registrationDrawing.imageMeta.name,
              width: registrationDrawing.imageMeta.width,
              height: registrationDrawing.imageMeta.height,
            },
            imageThumbnailMedium: registrationDrawing.imageThumbnailMedium,
            imageThumbnailSmall: registrationDrawing.imageThumbnailSmall,
            imageUrl: registrationDrawing.imageUrl,
            isNoLocation: registrationDrawing.isNoLocation,
            title: registrationDrawing.title,
          },
          tags: task.tag.tagsArray,
          tagId: task.tag.tagId || '',
        }

        return capture
      })
      .filter((capture) => !!capture)

    const input = {
      assigneeGroup: assignee.group.toUpperCase(),
      assigneeName: assignee.name,
      inspectionId: inspection.id,
      noneCaptures,
    }

    if (!isNilOrEmpty(assignee.email)) {
      input.assigneeEmail = assignee.email
    }

    if (!isNilOrEmpty(assignee.phoneNumber)) {
      input.phoneNumber = assignee.phoneNumber
    }

    try {
      return await apolloClient.createNoneCaptureAssignment({ input })
    } catch (error) {
      return error
    }
  },
  async revokeAssignmentCaptures(_context, input) {
    await apolloClient.revokeAssignmentCaptures({ input })
  },
}

export default {
  namespaced: false,
  state,
  getters,
  mutations,
  actions,
}
