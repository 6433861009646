<template>
  <div id="access-list">
    <div
      v-if="userCount === 0 && itemType && permissionsLoaded"
      class="panel-note"
    >
      {{ $t('permissions:users.note.noUsers' + itemType) }}
    </div>

    <div v-if="userCount > 0" class="panel-section">
      <h2 class="text-header">{{ $t('permissions:header.accessList') }}</h2>
      <div v-show="permissionsReady">
        <AccessListItem
          v-for="permission in permissions"
          :key="permission.userId"
          :disabled="!canDeletePermission(permission)"
          :recipient="permission"
          :user-id="permission.userId"
          :role="permission.role"
          type="permission"
          @click="
            $emit('viewUser', {
              ...$event,
              permission,
              userId: permission.userId,
            })
          "
          @ready="permissionsUsersChecked++"
        />
      </div>
      <LoadingSpinner v-if="!permissionsReady" :small="true" />
    </div>

    <div v-if="invitations.length > 0" class="panel-section">
      <h2 class="text-header">{{ $t('permissions:header.invitations') }}</h2>
      <div v-show="invitationsReady">
        <AccessListItem
          v-for="invitation in invitations"
          :key="'invitation-' + invitation.id"
          :recipient="invitation.recipient"
          :user-id="invitation.recipient.id"
          :role="invitation.role"
          type="invitation"
          @click="viewInvitation(invitation)"
          @ready="invitationUsersChecked++"
        />
      </div>
      <LoadingSpinner v-if="!invitationsReady" :small="true" />
      <!-- we dismissed use of invitation.recipient.payload.actor and instead fetch actor -->
    </div>

    <div class="panel-actions">
      <h2 class="text-header">{{ $t('permissions:header.invite') }}</h2>
      <button
        class="button green"
        type="button"
        @click="addPanel('invite-users-internal')"
      >
        {{ $t('permissions:viewPermissions.button.inviteInternal') }}
      </button>

      <button
        class="button actor"
        type="button"
        @click="addPanel('invite-users-external')"
      >
        {{ $t('permissions:viewPermissions.button.inviteExternal') }}
      </button>

      <button
        v-if="canClearPermissions"
        class="button-plain clear-permissions"
        type="button"
        @click="clearPermissions"
      >
        {{ $t('permissions:clearPermission.button') }}
      </button>
    </div>

    <ConfirmDialog
      :active="clearPermissionsConfirmDialog.isRevealed"
      :title="$t('permissions:clearPermission.confirmationTitle')"
      :processing="processing"
      :confirm-label="$t('permissions:clearPermission.confirmationConfirm')"
      :cancel-label="$t('permissions:clearPermission.confirmationCancel')"
      confirm-theme="green"
      @cancel="clearPermissionsConfirmDialog.cancel"
      @confirm="clearPermissionsConfirmDialog.confirm"
    >
      <p>{{ $t('permissions:clearPermission.confirmation') }}</p>
    </ConfirmDialog>
  </div>
</template>

<script>
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { useConfirmDialog } from '@vueuse/core'
import AccessListItem from './AccessListItem.vue'

export default {
  components: {
    AccessListItem,
    ConfirmDialog,
    LoadingSpinner,
  },
  props: {
    itemId: {
      type: String,
      required: true,
    },
    itemType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      clearPermissionsConfirmDialog: useConfirmDialog(),
      currentInvitation: null,
      invitationUsersChecked: 0,
      permissionsUsersChecked: 0,
      processing: false,
    }
  },
  computed: {
    canClearPermissions() {
      return (
        this.isAdminOrSuperAdmin &&
        (this.invitations.length > 0 || this.permissions.length > 0)
      )
    },
    invitations() {
      return this.$store.getters.itemInvitations
    },
    permissions() {
      const permissions = this.$store.getters.itemPermissions
      const permissionsSorted = Object.keys(permissions)
        .map((userId) => ({
          userId,
          ...permissions[userId],
        }))
        .sort((a, b) => {
          if (!a.name || !b.name) return 0
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
          if (a.name.toLowerCase() < b.name.toLowerCase()) return 1
          return 0
        })
      // console.log(p)
      return permissionsSorted
    },
    permissionsLoaded() {
      return this.$store.getters.itemPermissionsLoaded
    },
    hasMultipleOwners() {
      return (
        this.permissions.filter((permission) => permission.role === 'owner')
          .length > 1
      )
    },
    userCount() {
      return this.permissions.length
    },
    permissionsReady() {
      return (
        this.permissionsUsersChecked >= Object.keys(this.permissions).length
      )
    },
    invitationsReady() {
      return this.invitationUsersChecked >= this.invitations.length
    },
  },
  methods: {
    canDeletePermission(permission) {
      return permission.role !== 'owner' || this.hasMultipleOwners
    },
    async clearPermissions() {
      const { isCanceled } = await this.clearPermissionsConfirmDialog.reveal()
      if (!isCanceled) {
        this.processing = true
        this.trackEvent({
          category: 'Workflow',
          action: 'Revoke permission',
          itemType: this.itemType,
        })

        const promises = []

        const userIds = this.permissions.map((permission) => permission.userId)
        if (userIds.length > 0) {
          promises.push(
            this.$store.dispatch('removePermissions', {
              type: this.itemType,
              itemId: this.itemId,
              userIds,
            })
          )
        }

        this.invitations.forEach((invitation) => {
          promises.push(
            this.$store.dispatch('deleteInvitation', {
              type: invitation.type,
              id: invitation.id,
            })
          )
        })

        await Promise.all(promises)

        this.processing = false
        this.removePanel()
      }
    },
    viewInvitation(invitation) {
      this.$emit('viewInvitation', { invitation })
    },
  },
}
</script>



<style lang="less" scoped>
.clear-permissions {
  color: var(--color-grey-dark);
  font-size: var(--text-sm);
  margin-top: 1rem;

  &:hover {
    color: var(--color-dark);
  }
}
</style>
