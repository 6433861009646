import { firestore } from '@/firebase'
const SUBSCRIPTIONS_SUBCOLLECTION = 'subscriptions'

const state = {
  plan: null,
  limitations: [
    { key: 'watermark' },
    { key: 'admins' },
    { key: 'users' },
    { key: 'inspectionTemplates' },
    { key: 'registrations' },
    { key: 'contacts' },
    { key: 'inspectionsBeta' },
    { key: 'remoteInspections' },
    { key: 'assignmentPlannerBeta' },
    { key: 'categoryForms' },
    { key: 'reportDesigner' },
    { key: 'previewReports' },
    { key: 'captureAI' },
  ],
  plans: [
    { key: 'free' },
    { key: 'starter' },
    { key: 'professional' },
    { key: 'custom' },
  ],
}

const getters = {
  plan: (state) => state.plan,
  featureLimit: (state) => (featureId, count) => {
    if (
      !state.plan ||
      !state.plan.limitations ||
      state.plan.limitations[featureId] === undefined
    )
      return {
        featureId,
        isLimited: false,
        limitation: -1,
        count,
        remaining: -1,
        limitReached: false,
      }
    const limitation = state.plan.limitations[featureId]
    return {
      name: state.plan.name,
      featureId,
      isLimited: true,
      limitation,
      count,
      remaining: limitation - count,
      limitReached: limitation - count <= 0,
    }
  },
  limitations: (state) => state.limitations,
  plans: (state) => state.plans,
}

const mutations = {
  SET_COMPANYPLAN(state, planDocument) {
    state.plan = planDocument
  },
}

const actions = {
  async getActiveSubscriptionSnapshot(context, { companyId }) {
    return await firestore
      .collection('companies')
      .doc(companyId)
      .collection(SUBSCRIPTIONS_SUBCOLLECTION)
      .where('isActive', '==', true)
      .limit(1)
      .get()
  },
  async getCompanyPlan({ commit, dispatch }, { companyId, isCurrentCompany }) {
    try {
      const activeSubscriptionSnapshot = await dispatch(
        'getActiveSubscriptionSnapshot',
        {
          companyId,
        }
      )

      if (activeSubscriptionSnapshot.empty) {
        console.log('No current company plan available')
        return undefined
      }

      const [activeSubscription] = activeSubscriptionSnapshot.docs

      if (activeSubscription.exists) {
        const document = {
          ...activeSubscription.data(),
          id: activeSubscription.id,
        }
        if (isCurrentCompany) commit('SET_COMPANYPLAN', document)
        return document
      }
    } catch (err) {
      console.log('Failed to get plan document', err)
    }
  },
  async updateCompanyPlan(
    { dispatch },
    { companyId, limitations = {}, type = 'free' }
  ) {
    try {
      const activeSubscriptionSnapshot = await dispatch(
        'getActiveSubscriptionSnapshot',
        {
          companyId,
        }
      )

      const newSubscription = {
        archived: false,
        limitations,
        type,
        updated: Date.now(),
        updatedFrom: `Admin:${document.location.href}`,
        companyId,
        /** @TODO add `updatedBy` field */
        /** (I assume it's the userId/companyId of the superadmin/currentUser) */
      }

      // Update active plan
      if (!activeSubscriptionSnapshot.empty) {
        const [activeSubscription] = activeSubscriptionSnapshot.docs

        if (type === 'free') {
          newSubscription.name = 'Free'
        }

        return await activeSubscription.ref.update(newSubscription)
      }

      // or Create new plan
      newSubscription.isActive = true
      newSubscription.created = Date.now()
      await firestore
        .collection('companies')
        .doc(companyId)
        .collection(SUBSCRIPTIONS_SUBCOLLECTION)
        .doc()
        .set(newSubscription)
    } catch (err) {
      console.log('Failed to set plan document', err)
    }
  },
  async deleteCompanyPlan(
    { dispatch },
    { planDocumentId = 'current', companyId }
  ) {
    try {
      const activeSubscriptionSnapshot = await dispatch(
        'getActiveSubscriptionSnapshot',
        {
          companyId,
        }
      )

      if (activeSubscriptionSnapshot.empty) {
        console.log('No current company plan available')
        return undefined
      }

      const [activeSubscription] = activeSubscriptionSnapshot.docs

      if (activeSubscription.exists) {
        const activeSubscriptionData = {
          ...activeSubscription.data(),
          id: activeSubscription.id,
        }

        // Back up active subscription
        if (activeSubscriptionData.created) {
          return await activeSubscription.ref.update({
            originalId: activeSubscription.id,
            isActive: false,
            archived: true,
            updated: Date.now(),
            updatedFrom: `Admin:${document.location.href}`,
            /** @TODO add `updatedBy` field */
            /** (I assume it's the userId/companyId of the superadmin/currentUser) */
          })
        }
      }
      throw new Error('No active subscription found')
    } catch (err) {
      console.log('Could not delete', err)
    }
  },
}

export default {
  namespaced: false,
  state,
  getters,
  mutations,
  actions,
}
