<template>
  <div class="base-search">
    <input
      :id="id"
      :value="value"
      :placeholder="$t('actors:searchActors')"
      :disabled="disable === true"
      type="search"
      autocomplete="false"
      spellcheck="false"
      @input="inputEvent"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    />
    <MagnifyIcon />
  </div>
</template>

<script>
import MagnifyIcon from '@/icons/magnify.svg'
import { nanoid } from 'nanoid'

export default {
  components: { MagnifyIcon },
  props: {
    value: String || Number,
    placeholder: String,
    disable: Boolean,
  },
  data() {
    return {
      id: nanoid(),
    }
  },
  methods: {
    inputEvent(e) {
      this.$emit('input', e.target.value)
    },
  },
}
</script>

<style lang="less" scoped>
.base-search {
  position: relative;

  svg {
    position: absolute;
    left: var(--space-x2);
    top: var(--space-x2);
    transform: translate(-50%, -50%);
    pointer-events: none;
    transition: opacity 0.15s ease-out;
  }

  input[type='search'] {
    padding-left: var(--space-x4);
    border: 1px solid var(--color-form-element-border);
    transition: padding 0.15s ease-out;

    &:focus {
      padding-left: var(--space-x1);
    }

    &:focus + svg {
      opacity: 0;
    }
  }
}
</style>
