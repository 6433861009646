<template>
  <div>
    <div v-for="option in options" :key="option.value">
      <div
        class="option"
        :class="{ selected: option.value === value, disabled: option.disabled }"
        @click="setValue(option.value)"
      >
        <div class="icon">
          <LockIcon v-if="option.disabled" class="icon-symbol" />
          <CheckIcon v-else class="icon-symbol" />
        </div>
        <div class="title">{{ option.label }}</div>
      </div>
      <div v-if="option.info" class="info">
        {{ option.info }}
      </div>
    </div>
  </div>
</template>

<script>
import CheckIcon from '@/icons/check.svg'
import LockIcon from '@/icons/lock.svg'

export default {
  components: { CheckIcon, LockIcon },
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      default: null,
      type: [Number, String],
    },
  },
  methods: {
    setValue(value) {
      if (value === this.value) {
        this.$emit('input', null)
        return
      }
      this.$emit('input', value)
    },
  },
}
</script>

<style lang="less" scoped>
.option {
  display: flex;
  flex-direction: row;
  min-height: var(--space-x4);
  align-items: flex-start;
  justify-content: stretch;
  cursor: pointer;
  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    .title {
      color: var(--color-grey-dark);
    }
    .icon {
      color: var(--color-grey-dark);
      filter: grayscale(100%);
      &-symbol {
        opacity: 1;
      }
    }
  }
  .icon {
    flex: 0 0 var(--space-x4);
    height: var(--space-x4);
    position: relative;
    &-symbol {
      opacity: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
    }
    &:after {
      position: absolute;
      display: block;
      content: '';
      left: 50%;
      top: 50%;
      width: var(--space-x2);
      height: var(--space-x2);
      margin: calc(-1 * var(--space-x1));
      margin-top: calc(-1 * var(--space-x1));
      box-shadow: 0 0 0 2px var(--color-brand);
      border-radius: var(--radius-circle);
      z-index: 5;
    }
  }
  .title {
    flex: 1 1 auto;
    font-size: var(--text-sm);
    padding: var(--space-x1) 0;
    line-height: 1.4;
  }

  &.selected {
    .icon {
      color: var(--color-white);
      &:after {
        background-color: var(--color-brand-fade90);
      }
      &-symbol {
        opacity: 1;
      }
    }
    .title {
      font-weight: var(--font-weight-bd);
    }
  }
}

.info {
  font-size: var(--text-sm);
  padding-left: var(--space-x4);
  margin-bottom: var(--space-x1);
}
</style>
