<template>
  <button
    class="permission-item"
    :class="
      isInternal ? 'permission-item-internal' : 'permission-item-external'
    "
    :disabled="disabled"
    @click="$emit('click', { isInternal, userDetails })"
  >
    <span class="permission-item-row-1">
      <span
        v-if="ready && userDetails"
        class="permission-item-user"
        :title="recipient.name"
        >{{ userDetails.name }}
      </span>
      <span class="permission-role">{{ $t('permissions:roles.' + role) }}</span>
    </span>
    <span v-if="ready && userDetails" class="permission-item-row-2">
      <span v-if="userDetails.email">{{ userDetails.email }}</span>
      <span v-if="userDetails.phoneNumber || userDetails.phone">{{
        userDetails.phoneNumber || userDetails.phone
      }}</span>
      <!--<span v-if="userDetails.companyName">{{ userDetails.companyName }}</span>-->
    </span>
  </button>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      required: false,
    },
    recipient: {
      type: Object,
      required: true,
    },
    role: {
      type: String,
      required: false,
      default: null,
    },
    userId: {
      type: String,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: true,
      validator: (prop) => ['permission', 'invitation'].includes(prop),
    },
  },
  data() {
    return {
      ready: false,
      userDetails: null,
      isInternal: false,
    }
  },
  async mounted() {
    // invitations with actor info are by default external
    if (this.type === 'invitation') {
      this.isInternal = !(
        this.recipient.payload && this.recipient.payload.actor
      )
    }

    // Check if actor exists
    if (
      !this.isInternal &&
      (this.recipient.email ||
        this.recipient.phoneNumber ||
        this.recipient.phone)
    ) {
      this.userDetails = await this.$store.dispatch(
        'getActorByContactDetails',
        {
          email: this.recipient.email,
          phone: this.recipient.phoneNumber || this.recipient.phone,
        }
      )
    }

    // If actor does not exist, check if there's a user in same company (internal)
    if (!this.userDetails && this.userId) {
      try {
        this.userDetails = await this.$store.dispatch('getUserById', {
          userId: this.userId,
        })
        if (this.userDetails) this.isInternal = true
      } catch (err) {
        // console.error(err)
      }
    }

    this.ready = true
    this.$emit('ready')
  },
}
</script>

<style lang="less" scoped>
.permission-item {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: var(--space-half) var(--space-x1);
  height: auto;
  min-height: var(--space-x4);
  width: 100%;
  margin-bottom: 1px;
  border-radius: 0;

  .permission-item-row-1 {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .permission-item-row-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    opacity: 0.5;
    span {
      font-size: var(--text-xs);
    }
  }

  &-internal {
    .permission-role {
      font-weight: var(--font-weight-bd);
      color: var(--color-white);
      background-color: var(--color-brand);
    }
  }
  &-external {
    .permission-role {
      font-weight: var(--font-weight-bd);
      color: var(--color-white);
      background-color: var(--color-actor);
    }
  }

  &-user {
    font-weight: var(--font-weight-bd);
  }
  &-role {
    background-color: var(--color-grey-darker);
    color: var(--color-white);
    text-transform: uppercase;
    font-size: var(--text-xs);
    padding: 0 var(--space-half);
    font-weight: var(--font-weight-bd);
  }
}
</style>
