<template>
  <sidepanel
    v-if="invitation"
    id="view-invitation"
    :title="
      isInternal
        ? $t('permissions:viewInvitation.panelInternal.title')
        : $t('permissions:viewInvitation.panelExternal.title')
    "
    :processing="processing"
    :color-b-g="isInternal ? '#32c9a8' : '#7b35bd'"
    :color-f-g="isInternal ? '#ffffff' : '#ffffff'"
    @open="processing = true"
    @done="reset"
  >
    <template v-if="isInternal">
      <UserInfo
        :user-id="invitation.recipient.id"
        :user-name="invitation.recipient.name"
        :label="invitation.role"
        @loaded="processing = false"
      />
    </template>
    <template v-else>
      <ActorInfo
        :actor-id="invitation.recipient.payload.actor.id"
        :label="invitation.role"
        @loaded="processing = false"
      />
      <div
        v-if="
          invitation.recipient.name !== invitation.recipient.payload.actor.name
        "
        class="panel-note"
      >
        {{
          $t(
            'permissions:viewInvitation.panelExternal.accountExistsForInvitee.explanation',
            {
              contactDetail: actorContactDetail,
              accountName: invitation.recipient.name,
            }
          )
        }}
      </div>
      <div v-else class="panel-note">
        {{
          $t(
            'permissions:viewInvitation.panelExternal.noAccountExistsForInvitee.explanation',
            {
              contactDetail: actorContactDetail,
            }
          )
        }}
      </div>
    </template>
    <div v-if="actorTypes && actorTypes.length > 0" class="actor-type-details">
      <h2 class="text-header">Invited as</h2>
      <div
        v-for="actorType in actorTypes"
        :key="actorType.id"
        class="actor-type-details-item"
      >
        {{ actorType.title }}
      </div>
    </div>
    <div class="panel-actions">
      <button
        class="button red outline"
        type="button"
        @click="deleteInvitation"
      >
        {{ $t('permissions:viewInvitation.withdraw.button') }}
      </button>
    </div>
    <div class="invitation-sender-meta">
      {{ $t('permissions:viewInvitation.invitedBy') }}
      <br />
      {{ invitation.sender.name }}
      <br />
      {{ formatDate(invitation.timestamp, { config: 'dateTime', timeZone }) }}
    </div>
  </sidepanel>
</template>

<script>
import ActorInfo from '../ActorInfo.vue'
import UserInfo from '../UserInfo.vue'

export default {
  components: {
    UserInfo,
    ActorInfo,
  },
  props: {
    invitation: {
      type: Object,
      required: false,
    },
    itemActorTypes: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      processing: false,
      actor: null,
    }
  },
  computed: {
    isInternal() {
      return (
        this.invitation.recipient.company &&
        this.invitation.recipient.company.id &&
        this.invitation.recipient.company.id === this.currentUser.companyId
      )
    },
    actorContactDetail() {
      if (
        !this.invitation.recipient.payload &&
        !this.invitation.recipient.payload.actor
      )
        return ''
      return (
        this.invitation.recipient.payload.actor.email ||
        this.invitation.recipient.payload.actor.phone
      )
    },
    actorTypes() {
      if (!this.invitation.actorTypes || !this.itemActorTypes) return []
      const value = []
      this.invitation.actorTypes.forEach((invitationActorType) => {
        const actorType = this.itemActorTypes.find(
          (iat) => iat.id === invitationActorType
        )
        if (actorType) value.push(actorType)
      })
      return value
    },
    timeZone() {
      return this.getTimeZone(null)
    },
  },
  methods: {
    async deleteInvitation() {
      if (confirm(this.$t('permissions:withdrawInvitation.confirmation'))) {
        this.processing = true
        this.trackEvent({
          category: 'Workflow',
          action: 'Revoke invitation',
        })
        await this.$store.dispatch('deleteInvitation', {
          id: this.invitation.id,
        })
        this.processing = false
        this.removePanel()
      }
    },
    reset() {
      console.log('RESETTING')
      this.actor = null
    },
  },
}
</script>

<style lang="less" scoped>
.invitation-sender-meta {
  margin-top: var(--space-x2);
  font-size: var(--text-xs);
}

.actor-type-details {
  &-item {
    padding: var(--space-half);
    background-color: var(--color-brand-fade10);
    margin-bottom: 1px;
  }
}
</style>
