import VueI18Next from '@panter/vue-i18next'
import daLocale from 'date-fns/locale/da'
import enGBLocale from 'date-fns/locale/en-GB'
import enUSLocale from 'date-fns/locale/en-US'
import setDefaultOptions from 'date-fns/setDefaultOptions'
import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import resourcesToBackend from 'i18next-resources-to-backend'
import Vue from 'vue'
import { getCookieHost } from './utils/cookies'

Vue.use(VueI18Next)

const params = new URLSearchParams(document.location.search)
const locale = params.get('locale')
const fallbackLng = locale || 'en-GB'

i18next
  .use(LanguageDetector)
  .use({
    type: 'postProcessor',
    name: 'decode-text',
    process: (value, key, options, translator) => {
      return value.replace(/&amp;/g, '&')
    },
  })
  .use(
    resourcesToBackend((language, namespace) =>
      import(`./locales/${language}/${namespace}.json`)
    )
  )
  .init({
    compatibilityJSON: 'v3',
    postProcess: 'decode-text',
    initImmediate: false,
    interpolation: {
      format: function (value, format, lng) {
        if (format === 'uppercase') return value.toUpperCase()
        return value
      },
    },
    detection: {
      // order and from where user language' should be detected
      order: ['cookie'],

      // keys or params to lookup language from
      lookupCookie: 'i18next',

      // cache user language on
      caches: ['cookie'],

      // optional expire and domain for set cookie
      cookieMinutes: 10,
      cookieDomain: getCookieHost(),

      // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
      cookieOptions: {
        domain: getCookieHost(),
        sameSite: 'strict',
        secure: true,
        maxAge: 3600,
      },
    },
    ns: [
      'actors',
      'assignments',
      'common',
      'company',
      'dashboard',
      'inspections',
      'permissions',
      'profile',
      'projects',
      'report',
      'reports',
      'report-templates',
      'sharedResources',
      'templates',
      'user',
    ],
    defaultNS: 'common',
    saveMissing: import.meta.env.DEV,
    debug: false, // import.meta.env.DEV,
    fallbackLng,
  })

const i18n = new VueI18Next(i18next)

document.documentElement.lang = i18n.language
i18next.on('languageChanged', function (lang) {
  document.querySelector('html').setAttribute('lang', lang)

  let locale
  if (lang === 'da-DK') {
    locale = daLocale
  } else if (lang === 'en-GB') {
    locale = enGBLocale
  } else {
    locale = enUSLocale
  }

  setDefaultOptions({
    locale,
    weekStartsOn: locale.options.weekStartsOn,
  })
})

export default i18n
