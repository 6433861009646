<template>
  <sidepanel
    id="invite-users-internal"
    :title="$t('permissions:invite.panel.title')"
    :processing="processing"
    @open="restart"
  >
    <template v-if="uninvitedUsers.length > 0">
      <div class="panel-selection">
        <h2 class="text-header">
          {{ $t('permissions:invite.header.selectUsers') }}
        </h2>
        <BaseSelectList v-model="users" :options="uninvitedUsers" />
      </div>
      <div class="panel-selection">
        <h2 class="text-header">
          {{ $t('permissions:invite.header.selectRole') }}
        </h2>
        <BaseRadios v-model="role" :options="roles" />
      </div>
      <div
        v-if="role === 'contributor' && actorTypeOptions.length > 0"
        class="panel-selection"
      >
        <h2 class="text-header">Select actor type(s)</h2>
        <BaseSelectList v-model="actorTypes" :options="actorTypeOptions" />
      </div>
      <div class="panel-actions">
        <button
          class="button green"
          :disabled="role === null || users.length === 0"
          @click="submitInvite"
        >
          {{ $t('permissions:button.invite', { count: users.length }) }}
        </button>
        <button class="secondary-button" type="button" @click="removePanel()">
          {{ $t('common:cancel') }}
        </button>
      </div>
    </template>
    <template v-else>
      <div v-if="itemType" class="note text-sm">
        {{ $t('permissions:invite.allUsersInvitedTo' + itemType) }}
      </div>
    </template>
  </sidepanel>
</template>

<script>
import BaseRadios from '@/components/BaseRadios.vue'
import BaseSelectList from '@/components/BaseSelectList.vue'
import { apolloClient } from '@/gql/authenticatedClient'

export default {
  components: {
    BaseRadios,
    BaseSelectList,
  },
  props: {
    permissions: {
      type: Object,
    },
    itemId: {
      type: String,
    },
    itemType: {
      type: String,
    },
    itemActorTypes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      role: null,
      users: [],
      actorTypes: [],
      processing: false,
    }
  },
  computed: {
    actorTypeOptions() {
      return this.itemActorTypes.map((actorType) => ({
        value: actorType.id,
        label: actorType.title,
        disabled: false,
      }))
    },
    roles() {
      return [
        {
          value: 'owner',
          label: this.$t('permissions:roles.owner'),
          disabled: false,
        },
        {
          value: 'contributor',
          label: this.$t('permissions:roles.contributor'),
          disabled: false,
        },
      ]
      /*            :disabled="
              itemType === 'inspections' &&
                option.id === 'owner' &&
                itemType === 'projects' &&
                option.id === 'contributor'
            " */
    },
    uninvitedUsers() {
      let users = this.companyUsers
      if (this.itemPermissions) {
        users = users.filter((user) => {
          return (
            this.itemPermissions[user.uid] === undefined &&
            this.itemInvitations.filter(
              (invitation) => invitation.recipient.id === user.uid
            ).length === 0
          )
        })
      }
      return users.map((user) => ({
        value: user.uid,
        label: user.name,
      }))
    },
    companyUsers() {
      return this.$store.getters.companyUsers
    },
    itemInvitations() {
      return this.$store.getters.itemInvitations
    },
    itemPermissions() {
      return this.$store.getters.itemPermissions
    },
  },
  mounted() {
    this.$store.dispatch('getCompanyUsers')
  },
  methods: {
    async submitInvite() {
      this.processing = true

      this.trackEvent({
        category: 'Workflow',
        action: 'Create invitation',
        type: this.itemType,
        role: this.role,
        users: this.users.length,
        relation: 'Internal',
      })

      let itemType = this.itemType.toUpperCase()

      if (itemType.endsWith('S')) {
        itemType = itemType.substr(0, itemType.length - 1)
      }

      const input = {
        recipients: this.users.map((id) => ({
          id,
          recipientGroup: 'INTERNAL',
        })),
        role: this.role.toUpperCase(),
        type: itemType,
        itemId: this.itemId,
      }

      try {
        await apolloClient.createInvitations({ input })
        this.processing = false
        this.removePanel()
      } catch (error) {
        this.processing = false
        console.log(error)
      }
    },
    restart() {
      this.users = []
      // this.role = this.itemType === 'inspections' ? 'contributor' : 'owner'
      this.$store.dispatch('getItemInvitations', {
        itemId: this.itemId,
        itemType: this.itemType,
      })
    },
  },
}
</script>

<style lang="less" scoped>
.panel-selection {
  button {
    width: 100%;
    text-align: left;
    &.active {
      background-color: var(--color-brand);
    }
  }
  .panel-selection-options {
    button {
      border-radius: 0;
      margin-bottom: 1px;
      &:first-child {
        border-radius: var(--radius-sm) var(--radius-sm) 0 0;
      }
      &:last-child {
        border-radius: 0 0 var(--radius-sm) var(--radius-sm);
        &:first-child {
          border-radius: var(--radius-sm);
        }
      }
    }
  }
}

.panel-selection + .panel-selection {
  margin-top: 1.5rem;
}
.note {
  background-color: var(--color-yellow);
  padding: var(--space-x2);
  margin: calc(-1 * var(--space-x1));
}
</style>
