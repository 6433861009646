import Vue from 'vue'
import Vuex from 'vuex'
import Actors from './actors'
import Algolia from './algolia'
import Assignments from './assignments'
import Captures from './captures'
import Chrome from './chrome'
import Templates from './templates'
import Projects from './projects'
import Inspections from './inspections'
import Reports from './reports'
import Users from './users'
import Companies from './companies'
import InspectionTimeline from './inspectionTimeline'
import Invitations from './invitations'
import SharedResources from './sharedResources'
import Plan from './plan'
import User from './user'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    Algolia,
    User,
    Plan,
    Chrome,
    Templates,
    Projects,
    Inspections,
    Reports,
    Users,
    Companies,
    Actors,
    Captures,
    Invitations,
    InspectionTimeline,
    Assignments,
    SharedResources,
  },
})
