import DEFAULT_TIMEZONE from '@/constants/defaultTimeZone'
import i18n from '@/i18n'
import { isNilOrEmpty } from '@/utils/isNilOrEmpty'
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'

export function formatDate(
  date,
  { config = 'dateTime', timeZone = DEFAULT_TIMEZONE }
) {
  if (isNilOrEmpty(date)) {
    return ''
  }

  let formatOptions = {}
  if (config === 'date') {
    formatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }
  } else if (config === 'dateShort') {
    formatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }
  } else if (config === 'dateTime') {
    formatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    }
  } else if (config === 'dateTimeShort') {
    formatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
    }
  } else if (config === 'hour') {
    formatOptions = {
      hour: 'numeric',
    }
  } else if (config === 'hourMinute') {
    formatOptions = {
      hour: 'numeric',
      minute: 'numeric',
    }
  } else if (config === 'hourMinuteSecond') {
    formatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    }
  } else if (config === 'monthYear') {
    formatOptions = {
      year: '2-digit',
      month: 'short',
    }
  } else if (config === 'time') {
    formatOptions = {
      hour: 'numeric',
      minute: 'numeric',
    }
  } else if (config === 'weekday') {
    formatOptions = {
      weekday: 'long',
    }
  } else if (config === 'year') {
    formatOptions = {
      year: 'numeric',
    }
  }

  const locale = i18n.i18next.language
  const formatter = Intl.DateTimeFormat(locale, { ...formatOptions, timeZone })
  return formatter.format(date)
}

export function timeAgo(date) {
  return formatDistanceToNowStrict(date, {
    addSuffix: true,
  })
}

export function truncate(text, stop, clamp) {
  return text.slice(0, stop) + (stop < text.length ? clamp || '…' : '')
}
