import { ContributorPermissions } from '../gql/generated/index';
<template>
  <div>
    <div v-if="loaded">
      <div class="panel-object">
        <div
          class="user-info-container"
          :class="{
            'is-actor': user && !isInternal,
            'user-info-container-internal': isInternal,
            'user-info-container-external': !isInternal,
          }"
        >
          <div class="user-name">
            {{ name }}
          </div>
          <div v-if="jobTitle" class="user-text">{{ jobTitle }}</div>
          <div v-if="email" class="user-text">{{ email }}</div>
          <div v-if="phoneNumber" class="user-text">
            {{ phoneNumber }}
          </div>
          <div v-if="!isInternal && actor" class="user-text">
            {{ actor.companyName }}
          </div>
          <div
            v-if="label"
            class="permission-role large"
            :class="
              isInternal
                ? 'permission-role-internal'
                : 'permission-role-external'
            "
          >
            {{ label }}
          </div>
          <!-- <template v-else>
            <div class="user-name">{{ userName }}</div>
            <div
              v-if="label"
              class="permission-role"
              :class="
                isInternal
                  ? 'permission-role-internal'
                  : 'permission-role-external'
              "
            >
              {{ label }}
            </div>
            <div class="user-explanation">
              {{
                $t(
                  'permissions:viewPermission.userFromAnotherCompany.explanation'
                )
              }}
            </div>
          </template> -->
        </div>
      </div>
      <!--<div v-else class="panel-note">{{$t("permissions:viewPermission.couldNotFindUserForPermission.explanation")}}</div>-->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    userName: {
      type: String,
      required: false,
      default: null,
    },
    userEmail: {
      // usually from permission
      type: String,
      required: false,
      default: null,
    },
    userPhone: {
      // usually from permission
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      actor: null,
      user: null,
      loaded: false,
      isInternal: false,
    }
  },
  computed: {
    email() {
      return !this.isInternal && this.actor != null
        ? this.actor.email
        : this.user.email
    },
    jobTitle() {
      return this.isInternal ? this.user.title : null
    },
    name() {
      return !this.isInternal && this.actor != null
        ? this.actor.name
        : this.user.name
    },
    phoneNumber() {
      return !this.isInternal && this.actor != null
        ? this.actor.phone
        : this.user.phoneNumber
    },
  },
  async mounted() {
    try {
      this.user = await this.$store.dispatch('getUserById', {
        userId: this.userId,
      })
      if (this.user?.companyId === this.currentCompany?.id) {
        this.isInternal = true
      }
      // throws a permission error when it's not a user from the same companyId
    } catch (err) {}

    // We need the contact name for external users
    if (!this.isInternal || !this.user) {
      if (this.userEmail || this.userPhone) {
        this.actor = await this.$store.dispatch('getActorByContactDetails', {
          email: this.userEmail,
          phone: this.userPhone,
        })
      }
    }

    this.loaded = true
    this.$emit('loaded')
  },
}
</script>

<style lang="less" scoped>
.user-info-container-internal {
  padding: var(--space-x1);
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &.is-actor {
    .user-name {
      color: var(--color-actor);
    }
  }
}

.user-info-container {
  padding: var(--space-x1);
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.user-info-container-internal {
  border: 2px solid var(--color-brand);
}
.user-info-container-external {
  border: 2px solid var(--color-actor);
}

.user-name {
  font-size: var(--text-sm);
  font-weight: var(--font-weight-bd);
}

.user-explanation {
  background-color: var(--color-yellow);
  font-size: var(--text-sm);
  padding: var(--space-x1);
  border-radius: 0 0 var(--radius-lg) var(--radius-lg);
  margin: calc(-1 * var(--space-x1) * 1.5);
  margin-top: var(--space-x1);
}

.user-text {
  color: var(--color-grey-dark);
  font-size: var(--text-xs);
}

.user-label {
  font-weight: var(--font-weight-bd);
  background-color: var(--color-black);
  color: var(--color-white);
  padding: 0 var(--space-half);
  margin-top: var(--space-half);
  text-transform: uppercase;
}

.permission-role {
  font-weight: var(--font-weight-bd);
  margin-top: var(--space-x1);
}
</style>
