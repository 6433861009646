<template>
  <sidepanel
    id="navigation-invitations"
    :left="true"
    :title="$t('permissions:invitationsPanel.title')"
    color-f-g="#000000"
    color-b-g="#ffffff"
    :processing="processing"
  >
    <div
      v-for="(invitation, index) in invitations"
      :key="index"
      class="invitation-item"
    >
      <h2 v-if="invitation.isRevoked" class="text-header">
        {{
          $t(
            'permissions:invitationsPanel.invitationHeader.' +
              invitation.type +
              'Revoked'
          )
        }}
      </h2>
      <h2 v-else class="text-header">
        {{
          $t('permissions:invitationsPanel.invitationHeader.' + invitation.type)
        }}
      </h2>
      <div class="invitation-subject">
        {{ invitation.itemTitle }} ({{
          $t('permissions:roles.' + invitation.role)
        }})
      </div>
      <div class="invitation-sender">
        {{ $t('permissions:invitationsPanel.invitationFrom') }}:
        {{ invitation.sender.name }}
      </div>
      <div class="invitation-actions">
        <button
          v-if="invitation.isRevoked"
          class="red outline"
          @click="handleInvitation(invitation, 'deleteInvitation')"
        >
          {{ $t('permissions:invitationActions.remove') }}
        </button>
        <button
          v-if="!invitation.isRevoked"
          class="red outline"
          @click="handleInvitation(invitation, 'declineInvitation')"
        >
          {{ $t('permissions:invitationActions.decline') }}
        </button>
        <button
          v-if="!invitation.isRevoked"
          class="button green"
          type="button"
          @click="handleInvitation(invitation, 'acceptInvitation')"
        >
          {{ $t('permissions:invitationActions.accept') }}
        </button>
      </div>
    </div>
  </sidepanel>
</template>

<script>
export default {
  props: {
    invitations: {
      type: Array,
    },
  },
  data() {
    return {
      processing: false,
    }
  },
  methods: {
    async handleInvitation(invitation, action) {
      this.processing = true
      this.trackEvent({
        category: 'Workflow',
        action:
          action === 'acceptInvitation'
            ? 'Accept invitation'
            : 'Decline invitation',
      })
      await this.$store.dispatch(action, invitation)
      this.processing = false
      if (this.invitations.length === 0) this.removePanel()
    },
  },
}
</script>

<style lang="less" scoped>
.invitation-item {
  margin-bottom: var(--space-x1);
  padding: 0 var(--space-x1) var(--space-x1) var(--space-x1);
  border: 1px solid var(--color-grey-light);
  border-radius: var(--radius-lg);
  .invitation-subject {
    font-size: var(--text-sm);
    font-weight: var(--font-weight-bd);
  }
  .invitation-sender {
    font-size: var(--text-sm);
    margin: var(--space-x1) 0;
  }
  .invitation-actions {
    display: flex;
    flex-direction: row;
    button {
      flex: 1;
      &:first-child {
        margin-right: var(--space-half);
      }
      &:last-child {
        margin-left: var(--space-half);
      }
    }
  }
}
</style>
