<template>
  <div
    id="app"
    class="app"
    :class="{
      'nav-collapsed': !isNavigationExpanded,
      'no-nav': !hasMenu || splitMode,
      'split-mode': splitMode,
    }"
  >
    <Toasts></Toasts>
    <div class="nav-wrapper">
      <Navigation v-if="hasMenu" />
    </div>

    <transition name="fade">
      <div
        v-if="panelCount > 0 && !splitMode"
        class="overlay"
        @click="removeAllPanels"
      ></div>
    </transition>

    <PanelInvitations :invitations="invitations" />
    <PermissionSelector />
    <router-view v-if="currentUser || !requiresAuth" :key="$route.name" />
    <div
      v-if="splitMode"
      id="split-block"
      @click="$store.dispatch('toggleSplitMode')"
    >
      {{ $t('inspections:splitMode.disable') }}
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/Navigation'
import PanelInvitations from '@/components/PanelInvitations.vue'
import PermissionSelector from '@/components/PermissionSelector/index.vue'
import Toasts from '@/components/Toasts.vue'
import '@fontsource/mulish'
import '@fontsource/mulish/variable.css'

export default {
  components: {
    Navigation,
    PanelInvitations,
    PermissionSelector,
    Toasts,
  },
  computed: {
    splitMode() {
      return this.$store.getters.getSplitMode
    },
    requiresAuth() {
      return this.$route.meta.protected
    },
    hasMenu() {
      return (
        this.$route.name !== 'sharedResource' &&
        this.$route.name !== 'InspectionReport' &&
        this.$route.name !== 'signin' &&
        this.$route.name !== 'signup' &&
        this.$store.getters.user
      )
    },
    invitations() {
      return this.$store.getters.myInvitations
    },
    panels() {
      return this.$store.getters.sidepanels
    },
    panelCount() {
      return this.$store.getters.sidepanelCount
    },
    isNavigationExpanded() {
      return this.$store.getters.isRootNavigationExpanded
    },
  },
  watch: {
    panels: function (to) {
      if (to.length > 0) {
        document.body.classList.add('lock-scroll')
      } else {
        document.body.classList.remove('lock-scroll')
      }
    },
  },
  mounted() {
    if (import.meta.env.DEV) {
      document.title = 'Captego Setup (DEV)'
    }
    this.removeAllPanels()
    window.addEventListener('keyup', (event) => {
      if (event.code === 'Escape') this.removePanel()
    })
  },
}
</script>

<style lang="less">
@import './styles/common.less';
@import './styles/base/variables.css';
@import './styles/base/typography.css';
@import './styles/base/utilities.css';

body.lock-scroll {
  overflow: hidden;
}

.app {
  display: grid;
  grid-template-areas: 'navigation main';
  grid-template-columns: var(--size-navigation) 1fr;
  transition: all 0.3s ease;
}

.nav-collapsed {
  --size-navigation: var(--size-navigation-collapsed);

  @media (--bp-below-sm) {
    --size-navigation: 0;
  }
}

.no-nav {
  grid-template-columns: 0 1fr;
}

.nav-wrapper {
  grid-area: navigation;
  position: relative;
  z-index: var(--layer-navigation);
}

.page {
  grid-area: main;
}

#split-block {
  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  width: var(--size-splitmode-panel);
  background-color: var(--color-grey-darker);
  color: var(--color-grey-dark);
  cursor: pointer;
}

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  background-color: var(--color-black-fade30);
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
