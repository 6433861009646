<template>
  <BaseDialog
    id="confirm-dialog"
    :active="active"
    :title="title"
    type="alert"
    @show="$emit('show')"
    @hide="$emit('hide')"
  >
    <slot />
    <template #actionsRight>
      <button
        class="button grey"
        :disabled="processing"
        type="button"
        @click="$emit('cancel')"
      >
        {{ cancelLabel }}
      </button>
      <BaseButton
        :class="confirmTheme"
        :processing="processing"
        @click="$emit('confirm')"
      >
        {{ confirmLabel }}
      </BaseButton>
    </template>
  </BaseDialog>
</template>

<script>
import BaseButton from '@/components/BaseButton.vue'
import BaseDialog from '@/components/BaseDialog.vue'

export default {
  components: {
    BaseButton,
    BaseDialog,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    cancelLabel: {
      type: String,
      default: function () {
        return this.$t('common:cancel')
      },
    },
    confirmLabel: {
      type: String,
      default: function () {
        return this.$t('common:continue')
      },
    },
    confirmTheme: {
      type: String,
      default: 'red',
      validator: (prop) => ['green', 'red'].includes(prop),
    },
    processing: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
  },
  emits: ['cancel', 'confirm'],
}
</script>
