import { firestore } from '@/firebase'
import { apolloClient } from '@/gql/authenticatedClient'
import i18n from '@/i18n'

const companyFields = {
  title: 'Company',
  style: {
    colorFG: '#ffffff',
    colorBG: '#0055ff',
  },
  report: {
    footer: '',
  },
}

const state = {
  companies: [],
  editCompany: null,
}

const getters = {
  allCompanies: (state) =>
    state.companies.slice().sort((a, b) => {
      if (a.title < b.title) {
        return -1
      }
      if (a.title > b.title) {
        return 1
      }
      return 0
    }),
  editCompany: (state) => state.editCompany,
}

const mutations = {
  SET_COMPANIES(state, arr) {
    state.companies = arr
  },
  SET_EDITCOMPANY(state, company) {
    state.editCompany = company
  },
}

let companiesUnsubscribe

const actions = {
  async getEditCompany({ commit }, id) {
    await firestore
      .collection('companies')
      .doc(id)
      .get()
      .then((doc) => {
        commit('SET_EDITCOMPANY', {
          ...doc.data(),
          language: doc.data().language || 'da-DK',
          id,
        })
      })
  },
  async saveEditCompany({ state, dispatch }) {
    if (state.editCompany.id) {
      dispatch('addToast', {
        message: i18n.t('company:toast.savingCompany'),
        type: 'pending',
      })
      // GQL: in use?
      await apolloClient.updateCompany({
        input: {
          colorBG: state.editCompany.style.colorBG,
          colorFG: state.editCompany.style.colorFG,
          companyId: state.editCompany.id,
          footer: state.editCompany.report.footer,
          locale: state.editCompany.language,
          logo: state.editCompany.logo,
          name: state.editCompany.title,
        },
      })

      dispatch('addToast', {
        message: i18n.t('company:toast.savedCompanySuccess'),
        type: 'good',
      })
    }
  },
  subscribeToCompanies({ commit }) {
    // console.log('Subscribing')
    companiesUnsubscribe = firestore
      .collection('companies')
      // .where('archived', '==', false)
      .onSnapshot((querySnapshot) => {
        const all = querySnapshot.docs
          .map((doc) => {
            return { ...companyFields, ...doc.data(), id: doc.id }
          })
          .filter((item) => item.archived == null || !item.archived)

        commit('SET_COMPANIES', all)
      })
  },
  unsubscribeToCompanies({ commit }) {
    commit('SET_COMPANIES', [])
    companiesUnsubscribe()
  },
  getCompanies({ commit }) {
    firestore
      .collection('companies')
      .get()
      .then((snap) => {
        const companies = []
        snap.forEach((company) => {
          companies.push({
            ...companyFields,
            ...company.data(),
            id: company.id,
          })
        })
        // Sort Companies
        companies.sort((c1, c2) =>
          (c1.title ?? '')
            .toLowerCase()
            .localeCompare((c2.title ?? '').toLowerCase())
        )
        commit('SET_COMPANIES', companies)
      })
  },
  getCompany({ commit }, payload) {
    return new Promise((resolve, reject) => {
      firestore
        .collection('companies')
        .doc(payload.id)
        .get()
        .then((doc) => {
          resolve({ ...doc.data(), id: doc.id })
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  async getConfigurationDocument({ rootState }, { documentName }) {
    try {
      const varDocument = await firestore
        .collection('companies')
        .doc(rootState.User.user.companyId)
        .collection('configurations')
        .doc(documentName)
        .get()
      if (varDocument.exists) {
        const document = varDocument.data()
        // Find all the used tags
        const tags = Object.keys(document).reduce((total, curr) => {
          total = [...total, ...document[curr].tags]
          return total
        }, [])
        return { document, tags: Array.from(new Set(tags)) }
      }
      return { document: {}, tags: [] }
    } catch (err) {
      console.error(err)
    }
  },
  async saveConfigurationDocument(
    { rootState, dispatch },
    { documentName, document, silent }
  ) {
    try {
      if (!silent) dispatch('addToast', { message: 'Saving', type: 'pending' })
      await firestore
        .collection('companies')
        .doc(rootState.User.user.companyId)
        .collection('configurations')
        .doc(documentName)
        .set(document)
      if (!silent) dispatch('addToast', { message: 'Saved', type: 'good' })
    } catch (err) {
      console.error(err)
      dispatch('addToast', { message: 'Failed to save: ' + err, type: 'bad' })
    }
  },
}

export default {
  namespaced: false,
  state,
  getters,
  mutations,
  actions,
}
