import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'

import { region } from './region'

const config = {
  apiKey: import.meta.env.VUE_APP_API_KEY,
  authDomain: import.meta.env.VUE_APP_AUTH_DOMAIN,
  databaseURL: import.meta.env.VUE_APP_DATABASE_URL,
  projectId: import.meta.env.VUE_APP_PROJECT_ID,
  storageBucket: import.meta.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: import.meta.env.VUE_APP_APP_ID,
}

const firebaseApp = firebase.initializeApp(config)

const firestore = firebaseApp.firestore()

const functions = firebaseApp.functions(region)
// For local testing
// functions.useFunctionsEmulator("http://localhost:5000");

const storage = firebaseApp.storage()

export { config, firebaseApp, firestore, functions, storage }
