import { apolloClient } from '@/gql/authenticatedClient'
import 'firebase/functions'
import { asyncForEach } from '../../vuexHelpers'

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const setBatchPermissions = async (
  { dispatch },
  { users, inspections }
) => {
  dispatch('addToast', {
    message: 'Please wait. May take a bit of time',
    type: 'pending',
  })

  console.log('x', users, inspections)
  const permissionsToSet = []

  inspections.forEach((inspection) => {
    users.forEach(
      ({
        assigneeGroup,
        bypassInvitation,
        uid,
        role,
        contactDetail,
        phoneNumber,
        email,
        templates,
      }) => {
        const templateSettings = templates.find(
          (template) => template.id === inspection.templateId
        )
        let tagAccess = []
        if (
          templateSettings &&
          templateSettings.tags &&
          templateSettings.tags.length
        ) {
          tagAccess = templateSettings.tags
        }
        permissionsToSet.push({
          assigneeGroup,
          bypassInvitation,
          uid,
          contactDetail,
          phoneNumber,
          email,
          itemType: 'inspection',
          itemId: inspection.id,
          role,
          tagAccess,
        })
      }
    )
  })

  await asyncForEach(permissionsToSet, async (permission) => {
    console.log(permission)

    const { assigneeGroup, itemType, itemId, role, uid, tagAccess } = permission
    const tags = tagAccess.map((tag) => tag.id)
    console.log('Setting up tags', tags, {
      recipients: [{ id: uid }],
      role,
      type: itemType,
      itemId,
      tagPermissions: { [uid]: tags },
    })
    const input = {
      recipients: [{ id: uid, recipientGroup: assigneeGroup.toUpperCase() }],
      role: role.toUpperCase(),
      type: itemType.toUpperCase(),
      itemId,
      tagPermissions: { [uid]: tags },
    }
    try {
      const response = await apolloClient.createInvitations({ input })
      console.log('Added', response)
    } catch (err) {
      console.error(err)
    }

    // await sleep(1000)
  })
  dispatch('addToast', { message: 'All good.', type: 'good' })
}
