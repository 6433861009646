import { defaultElements } from '@/components/DataContentForm/FormData.js'
import { nanoid } from 'nanoid'

export const repairDataContent = (dataContent) => {
  if (
    dataContent == null ||
    !Array.isArray(dataContent) ||
    typeof dataContent.forEach !== 'function'
  ) {
    return dataContent
  }

  dataContent.forEach((content) => {
    if (content.value === undefined) {
      console.log('repair', content)
      content.value = defaultElements[content.type].value
    }
  })
  return dataContent
}

const set = (obj, path, value) => {
  // Regex explained: https://regexr.com/58j0k
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g)

  pathArray.reduce((acc, key, i) => {
    if (acc[key] === undefined) acc[key] = {}
    if (i === pathArray.length - 1) acc[key] = value
    return acc[key]
  }, obj)
}

const get = (obj, path, defValue) => {
  // If path is not defined or it has false value
  if (!path) return undefined
  // Check if path is string or array. Regex : ensure that we do not have '.' and brackets.
  // Regex explained: https://regexr.com/58j0k
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g)
  // Find value if exist return otherwise return undefined value;
  return (
    pathArray.reduce((prevObj, key) => prevObj && prevObj[key], obj) || defValue
  )
}

export const buildQuery = function (query, { scope, rootState }) {
  if (!scope) {
    scope =
      rootState.User.user.adminRole === 'super_admin' ||
      rootState.User.user.adminRole === 'admin' ||
      rootState.User.user.adminRole === 'user'
        ? 'company'
        : 'shared'
  }
  if (scope === 'company') {
    if (
      rootState.User.user.adminRole === 'super_admin' ||
      rootState.User.user.adminRole === 'admin' ||
      rootState.User.user.adminRole === 'user'
    ) {
      query = query.where('companyId', '==', rootState.User.user.companyId)
    } else {
      return false
    }
  } else if (scope === 'shared') {
    query = query.where(
      'permissionIds',
      'array-contains',
      rootState.User.user.uid
    )
  } else {
    return false
  }
  return query
}

export const userPermissions = function (rootState, permissionRole) {
  return {
    permissionIds: [rootState.User.user.uid],
    permissions: {
      [rootState.User.user.uid]: {
        exist: true,
        name: rootState.User.user.name,
        role: permissionRole,
      },
    },
  }
}

export const computeStatus = function (docData, noStatusLabel) {
  const statusContrastColor = (col) => {
    /*
    function contrastColor(bgColor, lightColor, darkColor) {
      var color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor
      var r = parseInt(color.substring(0, 2), 16) // hexToR
      var g = parseInt(color.substring(2, 4), 16) // hexToG
      var b = parseInt(color.substring(4, 6), 16) // hexToB
      var uicolors = [r / 255, g / 255, b / 255]
      var c = uicolors.map((col) => {
        if (col <= 0.03928) {
          return col / 12.92
        }
        return Math.pow((col + 0.055) / 1.055, 2.4)
      })
      var L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2]
      return L > 0.179 ? darkColor : lightColor
    }
    */
    function pickTextColorBasedOnBgColorSimple(bgColor, lightColor, darkColor) {
      const color =
        bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor
      const r = parseInt(color.substring(0, 2), 16) // hexToR
      const g = parseInt(color.substring(2, 4), 16) // hexToG
      const b = parseInt(color.substring(4, 6), 16) // hexToB
      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? darkColor : lightColor
    }
    return col
      ? pickTextColorBasedOnBgColorSimple(col, '#ffffff', '#000000')
      : '#ffffff'
  }

  let status = {}
  if (
    docData.labelGroups &&
    docData.labelGroups.length &&
    docData.labelGroups[0].selectedIndex &&
    docData.labelGroups[0].selectedIndex !== 0
  ) {
    status =
      docData.labelGroups[0].labels[docData.labelGroups[0].selectedIndex - 1]
    status.colorContrast = statusContrastColor(status.color)
    status.order = docData.labelGroups[0].selectedIndex
  } else {
    status = {
      title: noStatusLabel || 'No status',
      color: '#aaa',
      colorContrast: '#ffffff',
      order: 9999,
      id: 'nolabel',
    }
  }
  return status
}

export const getContentNumber = function (capture) {
  const regpattern = /@@([^0-9]*)([0-9]+)/g
  const match = regpattern.exec(capture.note)
  if (match) {
    return {
      contentNumberPrefix: match[1],
      contentNumber: parseInt(match[2]),
      noteClean: capture.note
        .replace(regpattern, '')
        .trim()
        .replace('<p></p>', ''),
    }
  } else {
    return {}
  }
}

export const sortCaptures = function (captures, prop, flattenedTemplateTagIDs) {
  captures = captures.sort((a, b) => {
    return a.timestamp - b.timestamp
  })

  function captureSort(capturesToSort, options) {
    function isValue(v) {
      return v !== null && v !== undefined
    }

    return capturesToSort.sort((a, b) => {
      const aPrimary =
        isValue(options.key1meta) && a[options.key1]
          ? a[options.key1][options.key1meta]
          : a[options.key1]
      const bPrimary =
        isValue(options.key1meta) && b[options.key1]
          ? b[options.key1][options.key1meta]
          : b[options.key1]

      if (aPrimary === bPrimary && isValue(options.key2)) {
        // Secondary sorting
        const aSecondary =
          isValue(options.key2meta) && a[options.key2]
            ? a[options.key2][options.key2meta]
            : a[options.key2]
        const bSecondary =
          isValue(options.key2meta) && b[options.key2]
            ? b[options.key2][options.key2meta]
            : b[options.key2]
        return aSecondary > bSecondary ? 1 : aSecondary < bSecondary ? -1 : 0
      }
      return aPrimary > bPrimary ? 1 : aPrimary < bPrimary ? -1 : 0
    })
  }

  function captureSortTagOrder(capturesToSort, correctTagOrder) {
    capturesToSort.sort(function (a, b) {
      if (correctTagOrder.indexOf(a.tagId) > correctTagOrder.indexOf(b.tagId)) {
        return 1
      } else {
        return -1
      }
    })
    return capturesToSort
  }

  // console.log(" sort", prop);
  if (prop.includes('field:')) {
    const [, fieldId] = prop.split(':')
    return captures.sort((a, b) => {
      const aValue = a.fields?.find((field) => field.id === fieldId)?.value
      const bValue = b.fields?.find((field) => field.id === fieldId)?.value

      if (aValue === bValue) {
        return 0
      }
      if (aValue == null) {
        return 1
      }
      if (bValue == null) {
        return -1
      }

      return aValue < bValue ? -1 : 1
      // return aValue < bValue ? 1 : -1
    })
  } else if (prop === 'registrationDrawing_title') {
    return captureSort(captures, {
      key1: 'registrationDrawing',
      key1meta: 'title',
      key2: 'tags',
      key2meta: 0,
    })
  } else if (prop === 'status') {
    return captureSort(captures, {
      key1: 'status',
      key1meta: 'order',
      key2: 'tags',
      key2meta: 0,
    })
  } else if (prop === 'tag1') {
    return captureSort(captures, {
      key1: 'tags',
      key1meta: 0,
      key2: 'tags',
      key2meta: 1,
    })
  } else if (prop === 'tag2') {
    return captureSort(captures, {
      key1: 'tags',
      key1meta: 1,
      key2: 'status',
      key2meta: 'order',
    })
  } else if (prop === 'inspector') {
    return captureSort(captures, {
      key1: 'userName',
      key1meta: null,
      key2: 'status',
      key2meta: 'order',
    })
  } else if (prop === 'tag1_atat_number') {
    return captureSort(captures, {
      key1: 'tags',
      key1meta: 0,
      key2: 'contentNumber',
      key2meta: null,
    })
  } else if (prop === 'template_tags') {
    return captureSortTagOrder(captures, flattenedTemplateTagIDs)
  } else {
    // undefined & timestamp day
    return captureSort(captures, {
      key1: 'timestamp',
      key1meta: null,
      key2: 'tags',
      key2meta: '0',
    })
  }
}

export const trimStrings = (obj) => {
  for (const prop in obj) {
    if (typeof obj[prop] === 'string') {
      obj[prop] = obj[prop].trim()
    }
  }
  return obj
}

export const translateRegistrationCoordsFromDrawingToSquare = function ({
  drawingPosition,
  registrationDrawing,
}) {
  const drawingMeta = registrationDrawing.imageMeta
  let dY = 0
  let dX = 0
  if (drawingMeta.width > drawingMeta.height) {
    dY =
      (100 * (drawingMeta.width - drawingMeta.height)) / drawingMeta.width / 4
  } else {
    dX =
      (100 * (drawingMeta.height - drawingMeta.width)) / drawingMeta.height / 4
  }
  return {
    posXpercentage: drawingPosition.xProcentage - dX,
    posYpercentage: drawingPosition.yProcentage - dY,
  }
}

export const addIDsToTags = function (tagObject, isRoot = true) {
  if ((tagObject.id === undefined || tagObject.id === '') && !isRoot)
    tagObject.id = nanoid()

  if (!tagObject.tags || tagObject.tags.length === 0) return tagObject

  for (let i = 0; i < tagObject.tags.length; i++) {
    addIDsToTags(tagObject.tags[i], false)
  }
  return tagObject
}

export const nestTags = (tagsArray) => {
  if (!tagsArray || tagsArray.length === 0) return null

  function buildTag(
    {
      captureCountMinimum,
      childIds,
      filters,
      fields,
      guidance,
      id,
      noteCounts,
      order,
      root,
      title,
    },
    tagsArray
  ) {
    const tags = tagsArray
      .filter((tag) => childIds.includes(tag.id))
      .sort((a, b) => a.order - b.order)
      .map((tag) => buildTag(tag, tagsArray))

    return {
      captureCountMinimum,
      filters,
      guidance,
      fields,
      id,
      noteCounts,
      order,
      root,
      tags,
      title,
    }
  }
  const rootTag = tagsArray.find((tag) => tag.root)
  if (!rootTag) {
    console.log("*** Something went wrong – there's no root tag ***")
    return { tags: [] }
  }
  const nestedTagsObject = buildTag(rootTag, tagsArray)
  return nestedTagsObject
}

export const removeNotesFromTags = function (tagObject, isRoot = true) {
  if (!isRoot) {
    delete tagObject.notes
    delete tagObject.title
    delete tagObject.filters
    delete tagObject.noteCounts
  }
  for (let i = 0; i < tagObject.tags.length; i++) {
    removeNotesFromTags(tagObject.tags[i], false)
  }
  return tagObject
}

export const getReportCoverPagePhoto = (inspection) => {
  // Try to get cover image from inspection and fallback to project.
  const coverPagePhoto = get(inspection, 'imageUrl')
  /*
  if (!coverPagePhoto) {
    coverPagePhoto = get(inspection, 'projectImageUrl')
  }
*/
  if (coverPagePhoto) {
    set(inspection, 'structure.coverPage.photo', coverPagePhoto)
  }
  return inspection
}

export const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}
