export const languageMap = {
  'en-GB': {
    locale: 'en-GB',
    translations: 'en-GB',
  },
  'en-US': {
    locale: 'en-US',
    translations: 'en-US',
  },
  'da-DK': {
    locale: 'da',
    translations: 'da-DK',
  },
  'default': {
    locale: 'en-GB',
    translations: 'en-GB',
  },
}
