<template>
  <button
    class="button base-button"
    :class="{ processing: processing }"
    type="button"
    @click="$emit('click')"
  >
    <loading-spinner v-if="processing" :small="true" :white="true" />
    <span>
      <slot></slot>
    </span>
  </button>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: 'Label',
    },
    processing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style lang="less" scoped>
.base-button {
  position: relative;
  span {
    transition: all 0.15s ease-out;
  }
  &.processing {
    pointer-events: none;
    span {
      opacity: 0.5;
    }
  }
  .lds-ring {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
