import { firestore } from '@/firebase'
import { apolloClient } from '@/gql/authenticatedClient'
import i18n from '@/i18n'
import { isNilOrEmpty } from '@/utils/isNilOrEmpty'
import {
  addIDsToTags,
  computeStatus,
  nestTags,
  repairDataContent,
} from '../vuexHelpers'
import { getNewTemplate, getStructureDefault } from './structureDefault'

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}

const state = {
  templates: [],
  editTemplate: null,
  allTemplates: [],
  editTemplateNotes: [],
  templateTagNotes: [],
  templateTags: [], // subscribed subcollection
}

const getters = {
  getTemplates: (state) => state.templates,
  getAllTemplates: (state) => state.allTemplates,
  getEditTemplate: (state) => state.editTemplate,
  getEditTemplateNotes: (state) => state.editTemplateNotes,
  getTemplateFields: (state) => state.templateFields,
  getTemplateTagNotes: (state) => state.templateTagNotes,
  getTemplateTags: (state) => state.templateTags,
  getTemplateTagsNested: (state) => {
    return nestTags(state.templateTags)
  },
}

const mutations = {
  SET_TEMPLATES(state, arr) {
    state.templates = arr
  },
  SET_ALLTEMPLATES(state, arr) {
    state.allTemplates = arr
  },
  SET_EDITTEMPLATE(state, doc) {
    if (!doc.dataContent) doc.dataContent = []
    if (!doc.groupBy) doc.groupBy = 'template_tags'
    state.editTemplate = doc
  },
  SET_EDITTEMPLATENOTES(state, arr) {
    state.editTemplateNotes = arr
  },
  SET_TEMPLATETAGNOTES(state, arr) {
    state.templateTagNotes = arr
  },
  SET_TEMPLATETAGS(state, arr) {
    state.templateTags = arr
  },
}

let templateUnsubscription
let templateAllUnsubscription
let templateNotesUnsubscription
let templateTagNotesUnsubscription
let templateTagsUnsubscription

const fixTemplateFormat = (templateData, id) => {
  const structure = { ...getStructureDefault(), ...templateData.structure }
  if (id) templateData.id = id
  const template = { ...getNewTemplate(), ...templateData, structure }

  // Add needed keys to drawings
  const drawingsKey = template.images ? 'images' : 'registrationDrawings'
  if (template[drawingsKey]) {
    template[drawingsKey].forEach((drawing, index) => {
      if (template[drawingsKey][index].isNoLocation === undefined)
        template[drawingsKey][index].isNoLocation = false
    })
  }

  // Add fallback values of contibutor permissions
  template.contributorPermissions = {
    addFromPhone: false,
    addFromProject: false,
    addNoLocation: false,
    addSatelliteImage: false,
    modifyCategories: false,
    modifyDataForm: false,
    ...template.contributorPermissions,
  }

  // If dataContent fields have undefined values, we add default values
  templateData.dataContent = repairDataContent(templateData.dataContent)

  return template
}

const actions = {
  async deleteTemplate({ commit }, payload) {
    await apolloClient.deleteInspectionTemplate({ input: { id: payload.id } })
  },
  async cloneTags(context, { sourceRef, targetRef }) {
    const tags = await sourceRef.collection('tags').get()
    if (tags && tags.docs.length > 0) {
      const tagsBatchArray = []
      tagsBatchArray.push(firestore.batch())
      let tagsOperationCounter = 0
      let tagsBatchIndex = 0

      tags.docs.forEach((tag) => {
        tagsOperationCounter++
        if (tagsOperationCounter === 499) {
          tagsBatchArray.push(firestore.batch())
          tagsBatchIndex++
          tagsOperationCounter = 0
        }
        const tagRef = targetRef.collection('tags').doc(tag.id)
        tagsBatchArray[tagsBatchIndex].set(tagRef, {
          ...tag.data(),
          id: tag.id,
        })
      })
      tagsBatchArray.forEach(async (batch) => await batch.commit())
    }
  },
  async cloneNotes(context, { sourceRef, targetRef }) {
    const notes = await sourceRef.collection('notes').get()
    if (notes && notes.docs.length > 0) {
      const notesBatchArray = []
      notesBatchArray.push(firestore.batch())
      let notesOperationCounter = 0
      let notesBatchIndex = 0

      notes.docs.forEach((note) => {
        notesOperationCounter++
        if (notesOperationCounter === 499) {
          notesBatchArray.push(firestore.batch())
          notesBatchIndex++
          notesOperationCounter = 0
        }
        const noteRef = targetRef.collection('notes').doc(note.id)
        notesBatchArray[notesBatchIndex].set(noteRef, {
          ...note.data(),
          id: note.id,
          updated: Date.now(),
        })
      })
      notesBatchArray.forEach(async (batch) => await batch.commit())
    }
  },
  async convertNotesFromInspectionToTemplate(
    { rootState, dispatch },
    { inspection, targetRef }
  ) {
    const inspectionCaptures = await firestore
      .collection('captures')
      .where('inspectionId', '==', inspection.id)
      .where('companyId', '==', rootState.User.user.companyId)
      .get()
    const captures = inspectionCaptures.docs
      .map((capture) => ({
        ...capture.data(),
        id: capture.id,
        status: computeStatus(capture.data()),
      }))
      .filter((capture) => capture.note !== '')

    const capturesBatchArray = []
    capturesBatchArray.push(firestore.batch())
    let capturesOperationCounter = 0
    let capturesBatchIndex = 0

    captures.forEach((capture) => {
      capturesOperationCounter++
      if (capturesOperationCounter === 499) {
        capturesBatchArray.push(firestore.batch())
        capturesBatchIndex++
        capturesOperationCounter = 0
      }
      const noteRef = targetRef.collection('notes').doc()
      const note = {
        id: noteRef.id,
        copy: capture.note,
        tagId: capture.tagId,
        order: 0,
        labelId: capture.status.id,
        updated: Date.now(),
      }
      capturesBatchArray[capturesBatchIndex].set(noteRef, note)
    })
    capturesBatchArray.forEach(async (batch) => await batch.commit())

    // update capture counts
    const tagsRef = targetRef.collection('tags')
    const notesRef = targetRef.collection('notes')
    const tagsRequest = await tagsRef.get()
    const countNotesOnTags = async () => {
      await asyncForEach(tagsRequest.docs, async (tagDoc) => {
        const tagNotesRequest = await notesRef
          .where('tagId', '==', tagDoc.id)
          .get()
        const tagNotes = tagNotesRequest.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        const uniqueLabelIds = Array.from(
          new Set(tagNotes.map((tagNote) => tagNote.labelId || 'nolabel'))
        )
        const noteCounts = {}
        uniqueLabelIds.forEach((labelId) => {
          noteCounts[labelId] = tagNotes.filter(
            (tagNote) => tagNote.labelId === labelId
          ).length
        })
        await tagsRef.doc(tagDoc.id).update({ noteCounts })
      })
    }
    await countNotesOnTags()
  },
  async addTemplateBasedOnInspection(
    { dispatch },
    { source, templateDocument }
  ) {
    // source.id, source.title templateDocument.id, templateDocument.data
    const sourceRef = firestore.collection('inspections').doc(source.id)
    const targetRef = firestore
      .collection('inspectionTemplates')
      .doc(templateDocument.id)
    const sourceDocument = await sourceRef.get()
    const inspection = { ...sourceDocument.data(), id: sourceDocument.id }
    templateDocument.data.structure = inspection.structure
    if (templateDocument.data.structure.coverPage) {
      templateDocument.data.structure.coverPage.photo = null
    }
    templateDocument.data.labelGroups = inspection.labelGroups
    templateDocument.data.dataContent = inspection.dataContent
    templateDocument.data.groupBy = inspection.groupBy || 'template_tags'
    templateDocument.data.registrationDrawings = inspection.images.map(
      (image) => ({
        ...image,
        autoAddToNewInspections: true,
      })
    ) // FIX images should be copied in storage
    templateDocument.data.baseInspectionId = inspection.id

    await targetRef.set(templateDocument.data)
    await dispatch('cloneTags', { sourceRef, targetRef })
    await dispatch('cloneNotes', { sourceRef, targetRef })

    if (source.includeCaptureNotes) {
      await dispatch('convertNotesFromInspectionToTemplate', {
        inspection,
        targetRef,
      })
    }
  },
  async addTemplate({ dispatch, rootState }, payload) {
    const doc = { ...getNewTemplate() }
    const docRef = firestore.collection('inspectionTemplates').doc()

    doc.archived = false
    doc.companyId = rootState.User.user.companyId
    doc.localisation = { timeConfig: { date: {}, time: {} } }
    if (!isNilOrEmpty(rootState.User.company.language)) {
      doc.localisation.timeConfig.locale = rootState.User.company.language
    }
    if (!isNilOrEmpty(rootState.User.company.timeZone)) {
      doc.localisation.timeConfig.timeZone = rootState.User.company.timeZone
    }
    doc.report.footer = rootState.User.company.report.footer
    doc.title = payload.title
    doc.changed = Date.now()

    if (payload.baseInspection && payload.baseInspection.id) {
      await dispatch('addTemplateBasedOnInspection', {
        source: payload.baseInspection,
        templateDocument: { id: docRef.id, data: doc },
      })
    } else {
      // Set correctly localized section headers
      for (const [key, value] of Object.entries(doc.structure)) {
        if (
          typeof doc.structure[key] === 'object' &&
          doc.structure[key] !== null &&
          doc.structure[key].header !== undefined
        ) {
          doc.structure[key].header = i18n.t(
            `reports:defaultStructure.${key}.header`
          )
        }
      }
      await firestore.collection('inspectionTemplates').doc(docRef.id).set(doc)
    }

    return { id: docRef.id }
  },
  async cloneTemplate(context, { templateId, toCompanyId, templateName }) {
    try {
      const templateRef = firestore
        .collection('inspectionTemplates')
        .doc(templateId)
      const templateSnapshot = await templateRef.get()
      const template = { ...templateSnapshot.data(), id: templateSnapshot.id }
      console.log(template)

      const toCompanySnapshot = await firestore
        .collection('companies')
        .doc(toCompanyId)
        .get()
      const toCompany = {
        ...toCompanySnapshot.data(),
        id: toCompanySnapshot.id,
      }

      const newTemplateRef = firestore.collection('inspectionTemplates').doc()

      const templateOverwrites = {
        changed: Date.now(),
        companyId: toCompanyId,
        report: {
          footer: {
            content: toCompany.report ? toCompany.report.footer : '',
          },
        },
        title: templateName,
        id: newTemplateRef.id,
      }

      const newTemplate = {
        ...template,
        ...templateOverwrites,
      }

      await newTemplateRef.set(newTemplate, { merge: false })

      const tags = await templateRef.collection('tags').get()
      if (tags && tags.docs.length > 0) {
        const tagsBatchArray = []
        tagsBatchArray.push(firestore.batch())
        let tagsOperationCounter = 0
        let tagsBatchIndex = 0

        tags.docs.forEach((tag) => {
          tagsOperationCounter++
          if (tagsOperationCounter === 499) {
            tagsBatchArray.push(firestore.batch())
            tagsBatchIndex++
            tagsOperationCounter = 0
          }
          const tagRef = newTemplateRef.collection('tags').doc(tag.id)
          tagsBatchArray[tagsBatchIndex].set(tagRef, {
            ...tag.data(),
            id: tag.id,
          })
        })
        tagsBatchArray.forEach(async (batch) => await batch.commit())
      }

      const notes = await templateRef.collection('notes').get()
      if (notes && notes.docs.length > 0) {
        const notesBatchArray = []
        notesBatchArray.push(firestore.batch())
        let notesOperationCounter = 0
        let notesBatchIndex = 0

        notes.docs.forEach((note) => {
          notesOperationCounter++
          if (notesOperationCounter === 499) {
            notesBatchArray.push(firestore.batch())
            notesBatchIndex++
            notesOperationCounter = 0
          }
          const noteRef = newTemplateRef.collection('notes').doc(note.id)
          notesBatchArray[notesBatchIndex].set(noteRef, {
            ...note.data(),
            id: note.id,
            updated: Date.now(),
          })
        })
        notesBatchArray.forEach(async (batch) => await batch.commit())
      }
    } catch (err) {
      console.error(err)
    }
  },
  async getTemplateList({ rootState }) {
    const templates = await firestore
      .collection('inspectionTemplates')
      .where('companyId', '==', rootState.User.user.companyId)
      .orderBy('title')
      .get()
    return templates.docs
      .filter((doc) => doc.get('archived') !== true)
      .map((doc) => ({
        id: doc.id,
        title: doc.data().title,
      }))
  },
  fetchTemplates({ commit, rootState }) {
    firestore
      .collection('inspectionTemplates')
      .where('companyId', '==', rootState.User.user.companyId)
      .orderBy('title')
      .get()
      .then((snap) => {
        const templates = []
        snap.docs
          .filter((doc) => doc.get('archived') !== true)
          .forEach((tmp) => {
            templates.push({ ...tmp.data(), id: tmp.id })
          })
        commit('SET_TEMPLATES', templates)
        return templates
      })
  },
  async saveEditTemplate(
    { rootState, commit, dispatch },
    { silent, templateCollection }
  ) {
    if (!silent) {
      dispatch('addToast', {
        message: i18n.t('templates:toast.savingTemplate'),
        type: 'pending',
      })
      try {
        const docId = rootState.Templates.editTemplate.id
        const docRef = firestore.collection(templateCollection).doc(docId)
        let docData = {
          ...rootState.Templates.editTemplate,
          changed: Date.now(),
        }
        docData = addIDsToTags(docData)
        docData.id = docId
        await docRef.set(docData)
        commit('SET_EDITTEMPLATE', docData)
        if (!silent) {
          dispatch('addToast', {
            message: i18n.t('templates:toast.savedTemplateSuccess'),
            type: 'good',
          })
        }
      } catch (err) {
        console.error(err)

        dispatch('addToast', {
          message: 'Failed to save (' + err + ')',
          type: 'bad',
        })
      }
    }
  },
  async saveTemplate({ dispatch }, { silent, templateObj, collection }) {
    console.log('Saving template', templateObj)
    try {
      if (!silent) {
        dispatch('addToast', {
          message: i18n.t('templates:toast.savingTemplate'),
          type: 'pending',
        })
      }
      const templateRef = firestore.collection(collection).doc(templateObj.id)
      const { notes, tags, ...cleanTemplateDoc } = templateObj
      const templateDocToSave = {
        ...fixTemplateFormat(cleanTemplateDoc),
        changed: Date.now(),
      }
      console.log(templateDocToSave)
      await templateRef.update(templateDocToSave)
      if (!silent) {
        dispatch('addToast', {
          message: i18n.t('templates:toast.savedTemplateSuccess'),
          type: 'good',
        })
      }
    } catch (err) {
      console.error(err)
      dispatch('addToast', {
        message: 'Failed saving from store (check console for error). ' + err,
        type: 'bad',
      })
    }
  },
  async getTemplateById({ dispatch }, payload) {
    const collection = payload.templateCollection || 'inspectionTemplates'

    // commit('SET_EDITTEMPLATE', { loading: true });
    try {
      const templateQuery = await firestore
        .collection(collection)
        .doc(payload.id)
        .get()
      const template = fixTemplateFormat(
        { ...templateQuery.data(), id: templateQuery.id },
        payload.id
      )
      // commit('SET_EDITTEMPLATE', template);
      return template
    } catch (error) {
      console.log('Could not get template', error)
      dispatch('addToast', {
        type: 'bad',
        message: 'Template not found',
      })
      return { error }
      // commit('SET_EDITTEMPLATE', {});
    }
  },
  subscribeToTemplates({ commit, rootState }) {
    // console.log("Subscribing");
    templateUnsubscription = firestore
      .collection('inspectionTemplates')
      .where('companyId', '==', rootState.User.user.companyId)
      .orderBy('title')
      .onSnapshot((querySnapshot) => {
        const all = querySnapshot.docs
          .filter((doc) => doc.get('archived') !== true)
          .map((doc) => {
            return fixTemplateFormat(doc.data(), doc.id)
          })
        commit('SET_TEMPLATES', all)
      })
  },
  async updateTagNoteCounts(context, payload) {
    const templateRef = firestore
      .collection(payload.templateCollection)
      .doc(payload.templateId)
    const affectedTagRef = templateRef
      .collection('tags')
      .doc(payload.note.tagId)
    const affectedTag = await affectedTagRef.get()
    const tag = { ...affectedTag.data(), id: affectedTag.id }
    if (!(typeof tag.noteCounts === 'object')) tag.noteCounts = {}
    if (!tag.noteCounts[payload.note.labelId]) {
      tag.noteCounts[payload.note.labelId] = payload.diff
    } else {
      tag.noteCounts[payload.note.labelId] += payload.diff
    }
    if (tag.noteCounts[payload.note.labelId] < 0)
      tag.noteCounts[payload.note.labelId] = 0
    await affectedTagRef.update(tag)
  },
  async addInspectionNote({ dispatch }, payload) {
    await apolloClient.createInspectionNotes({ input: payload })
  },
  async addInspectionTemplateNote({ dispatch }, payload) {
    const templateRef = firestore
      .collection('inspectionTemplates')
      .doc(payload.templateId)
    await templateRef
      .collection('notes')
      .add({ ...payload.note, updated: Date.now() })
    dispatch('updateTagNoteCounts', {
      templateCollection: 'inspectionTemplates',
      ...payload,
      diff: 1,
    })
  },
  async deleteInspectionNote({ dispatch }, payload) {
    await apolloClient.deleteInspectionNote({
      input: {
        inspectionId: payload.inspectionId,
        noteId: payload.note.id,
      },
    })
  },
  async deleteInspectionTemplateNote({ dispatch }, payload) {
    await apolloClient.deleteInspectionTemplateNote({
      input: {
        inspectionTemplateId: payload.inspectionTemplateId,
        noteId: payload.note.id,
      },
    })
  },
  async moveInspectionNote(
    { dispatch },
    { templateId, templateCollection, note, destination }
  ) {
    dispatch('addToast', { message: 'Moving note', type: 'pending' })
    const movedNote = {
      ...note,
      tagId: destination.tagId,
      labelId: destination.labelId,
      order: destination.order,
    }
    await dispatch('updateTagNoteCounts', {
      templateCollection,
      templateId,
      note,
      diff: -1,
    })
    await dispatch('updateTagNoteCounts', {
      templateCollection,
      templateId,
      note: movedNote,
      diff: 1,
    })
    await dispatch('updateInspectionTemplateNote', {
      templateCollection,
      templateId,
      note: movedNote,
    })
    dispatch('addToast', { message: 'Note moved', type: 'good' })
  },
  async moveInspectionTemplateNote(
    { dispatch },
    { templateId, templateCollection, note, destination }
  ) {
    dispatch('addToast', { message: 'Moving note', type: 'pending' })
    const movedNote = {
      ...note,
      tagId: destination.tagId,
      labelId: destination.labelId,
      order: destination.order,
    }
    await dispatch('updateTagNoteCounts', {
      templateCollection,
      templateId,
      note,
      diff: -1,
    })
    await dispatch('updateTagNoteCounts', {
      templateCollection,
      templateId,
      note: movedNote,
      diff: 1,
    })
    await dispatch('updateInspectionTemplateNote', {
      templateCollection,
      templateId,
      note: movedNote,
    })
    dispatch('addToast', { message: 'Note moved', type: 'good' })
  },
  async updateInspectionNote(context, payload) {
    await apolloClient.updateInspectionNote({ input: payload })
  },
  async updateInspectionTemplateNote(context, payload) {
    await firestore
      .collection(payload.templateCollection)
      .doc(payload.templateId)
      .collection('notes')
      .doc(payload.note.id)
      .update(payload.note)
  },
  async getTemplateTagNotes({ commit }, payload) {
    const tagNotes = await firestore
      .collection(payload.templateCollection)
      .doc(payload.templateId)
      .collection('notes')
      .where('tagId', '==', payload.tagId)
      .get()
    const notes = tagNotes.docs.map((note) => {
      return { ...note.data(), id: note.id }
    })
    commit('SET_TEMPLATETAGNOTES', notes)
  },
  subscribeToTemplateTags({ commit }, payload) {
    return new Promise((resolve, reject) => {
      templateTagsUnsubscription = firestore
        .collection(payload.templateCollection)
        .doc(payload.templateId)
        .collection('tags')
        .onSnapshot((querySnapshot) => {
          const all = querySnapshot.docs.map((doc) => {
            return { ...doc.data(), id: doc.id }
          })
          commit('SET_TEMPLATETAGS', all)
          resolve()
        }, reject)
    })
  },
  async getTemplateFields(context, { templateCollection, templateId }) {
    const snapshot = await firestore
      .collection(templateCollection)
      .doc(templateId)
      .collection('fields')
      .get()

    return snapshot.docs.map((doc) => {
      const data = doc.data()
      return {
        ...data,
        id: doc.id,
      }
    })
  },
  async getTemplateTags(context, { templateCollection, templateId, nest }) {
    if (templateCollection === 'reports') {
      const report = await firestore.collection('reports').doc(templateId).get()

      const reportDoc = { ...report.data(), id: report.id }
      return { id: report.id, tags: reportDoc.tags }
    } else {
      const tags = await firestore
        .collection(templateCollection)
        .doc(templateId)
        .collection('tags')
        .get()
      const allTags = tags.docs.map((doc) => {
        const data = doc.data()
        return {
          captureCountMinimum: 0,
          guidance: {
            image: '',
            text: '',
          },
          ...data,
          id: doc.id,
        }
      })
      return nest ? nestTags(allTags) : allTags
    }
  },
  unsubscribeToTemplateTags({ commit }) {
    try {
      templateTagsUnsubscription()
    } catch (err) {}
    commit('SET_TEMPLATETAGS', null)
  },
  subscribeToTemplateTagNotes({ commit }, payload) {
    return new Promise((resolve, reject) => {
      templateTagNotesUnsubscription = firestore
        .collection(payload.templateCollection)
        .doc(payload.templateId)
        .collection('notes')
        .where('tagId', '==', payload.tagId)
        .onSnapshot((querySnapshot) => {
          const all = querySnapshot.docs.map((doc) => {
            return { ...doc.data(), id: doc.id }
          })
          commit('SET_TEMPLATETAGNOTES', all)
          resolve()
        }, reject)
    })
  },
  unsubscribeToTemplateTagNotes({ commit }) {
    try {
      templateTagNotesUnsubscription()
    } catch (err) {}
    commit('SET_TEMPLATETAGNOTES', [])
  },
  async subscribeToTemplateNotes({ commit }, payload) {
    templateNotesUnsubscription = firestore
      .collection(payload.templateCollection)
      .doc(payload.templateId)
      .collection('notes')
      .onSnapshot((querySnapshot) => {
        const all = querySnapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id }
        })
        commit('SET_EDITTEMPLATENOTES', all)
      })
  },
  async getTemplateNotes(context, { templateCollection, templateId }) {
    const notesQuery = await firestore
      .collection(templateCollection)
      .doc(templateId)
      .collection('notes')
      .get()
    const notes = notesQuery.docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
      }
    })
    return notes
  },
  unsubscribeToTemplateNotes({ commit }) {
    try {
      templateNotesUnsubscription()
    } catch (err) {}
    commit('SET_ALLTEMPLATES', [])
  },
  unsubscribeToTemplates() {
    templateUnsubscription()
  },
  subscribeToAllTemplates({ commit, rootState }) {
    // PERMISSION FIX
    templateAllUnsubscription = firestore
      .collection('inspectionTemplates')
      .onSnapshot((querySnapshot) => {
        const all = querySnapshot.docs
          .filter((doc) => doc.get('archived') !== true)
          .map((doc) => {
            return { ...doc.data(), id: doc.id }
          })
        commit('SET_ALLTEMPLATES', all)
      })
  },
  unsubscribeToAllTemplates() {
    templateAllUnsubscription()
  },
  async updateTag(context, payload) {
    await firestore
      .collection(payload.templateCollection)
      .doc(payload.templateId)
      .collection('tags')
      .doc(payload.tagId)
      .update({ [payload.field]: payload.value })
  },
}

export default {
  namespaced: false,
  state,
  getters,
  mutations,
  actions,
}
