import App from '@/App.vue'
import BaseButton from '@/components/BaseButton.vue'
import IconButton from '@/components/IconButton.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import '@/components/Navigation/captego-nav.js'
import Sidepanel from '@/components/Sidepanel.vue'
import DEFAULT_TIMEZONE from '@/constants/defaultTimeZone'
import { firebaseApp } from '@/firebase'
import { apolloClient } from '@/gql/authenticatedClient'
import i18n from '@/i18n'
import router from '@/router'
import setupSentry from '@/sentry'
import store from '@/store'
import {
  deleteLogoutCookie,
  deleteTokenCookie,
  getLogoutCookie,
  getTokenCookie,
  setLogoutCookie,
  setTokenCookie,
} from '@/utils/cookies'
import { formatDate, timeAgo, truncate } from '@/utils/filters'
import { getImgProxyUrl } from '@/utils/getImgProxyUrl'
import { isCaptegoEmail } from '@/utils/isCaptegoEmail'
import { redactIDFromURL } from '@/utils/redactIDFromURL'
import { redirectToManage } from '@/utils/redirectToManage'

import * as Sentry from '@sentry/vue'
import firebase from 'firebase/app'
import FloatingVue from 'floating-vue'
import Plausible from 'plausible-tracker'
import VTooltip from 'v-tooltip'
import Vue from 'vue'
import Affix from 'vue-affix'
import VueCookies from 'vue-cookies'
import VuePhoneNumberInput from 'vue-phone-number-input'

import 'floating-vue/dist/style.css'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

if (import.meta.env.VUE_APP_MAINTENANCE === 'true') {
  redirectToManage()
}

setupSentry(router)

const { trackEvent, trackPageview } = Plausible({
  domain: window.location.hostname,
})

Vue.use(Affix)
FloatingVue.options.distance = 8
Vue.use(FloatingVue, {
  themes: {
    form: {
      $extend: 'dropdown',
      placement: 'bottom-start',
    },
    status: {
      $extend: 'dropdown',
      placement: 'bottom-start',
    },
  },
})
Vue.use(VueCookies, {
  expires: '999d',
  path: '/',
  domain: '.captego.com',
  secure: true,
  sameSite: 'Strict',
})
Vue.use(VTooltip)

Vue.component('VuePhoneNumberInput', VuePhoneNumberInput)

Vue.config.productionTip = false

Vue.component('Sidepanel', Sidepanel)
Vue.component('IconButton', IconButton)
Vue.component('BaseButton', BaseButton)
Vue.component('LoadingSpinner', LoadingSpinner)

Vue.mixin({
  data: function () {
    return {}
  },
  computed: {
    currentUser() {
      return this.$store.getters.user
    },
    currentCompany() {
      return this.$store.getters.company
    },
    isCaptegoEmail() {
      return isCaptegoEmail(this.currentUser.email)
    },
    isNonAdmin() {
      return (
        this.currentUser.adminRole !== 'admin' &&
        this.currentUser.adminRole !== 'super_admin'
      )
    },
    isAdminOrSuperAdmin() {
      return ['admin', 'super_admin'].includes(this.currentUser.adminRole)
    },
    isUser() {
      return this.currentUser.adminRole === 'user'
    },
    isAdmin() {
      return this.currentUser.adminRole === 'admin'
    },
    isSuperAdmin() {
      return this.currentUser.adminRole === 'super_admin'
    },
    hasAdminRole() {
      return ['super_admin', 'admin', 'user'].includes(
        this.currentUser.adminRole
      )
    },
    timeZone() {
      return this.$store.getters.company.timeZone || DEFAULT_TIMEZONE
    },
  },
  methods: {
    getImgProxyUrl(url, props) {
      return getImgProxyUrl(url, props)
    },
    featureLimit(featureId, count) {
      return this.$store.getters.featureLimit(featureId, count)
    },
    hasFeature(featureId) {
      return (
        this.$store.getters.featureLimit(featureId) !== undefined &&
        this.$store.getters.featureLimit(featureId).limitation > 0
      )
    },
    trackEvent(payload) {
      const { category, action, ...eventParams } = payload
      const eventName = [category, action]
        .join('_')
        .toLowerCase()
        .replace(/\s/gi, '_')

      trackEvent(eventName, {
        props: eventParams,
      })
    },
    addPanel: function (panelID, payload) {
      this.$store.dispatch('addPanel', {
        id: panelID,
        payload,
      })
    },
    removeAllPanels: function () {
      this.$store.dispatch('removeAllPanels')
    },
    removePanel: function (panelId) {
      this.$store.dispatch('removePanel', { panelId })
    },
    isCreator(item) {
      if (!item) return false
      return item.userId === this.currentUser.uid
    },
    isOwner(item) {
      if (!item) return false
      return item.permissions?.[this.currentUser.uid]?.role === 'owner'
    },
    hasOwnerRights(item) {
      if (!item) return false
      return (
        this.isOwner(item) ||
        (item.companyId === this.currentUser.companyId &&
          this.isAdminOrSuperAdmin)
      )
    },
    isAssigneeOnly(item) {
      if (!item) return false
      const userPermissions = item.permissions
        ? item.permissions[this.currentUser.uid]
        : ''
      const userRole = userPermissions ? userPermissions.role : ''
      return userRole === 'assignee' && !this.hasOwnerRights(item)
    },
    isContributor(item) {
      if (!item) return false
      const userPermissions = item.permissions
        ? item.permissions[this.currentUser.uid]
        : ''
      if (
        this.currentUser.adminRole !== 'admin' &&
        this.currentUser.adminRole !== 'super_admin' &&
        userPermissions &&
        userPermissions.role === 'contributor'
      )
        return true
      return false
    },
    getTimeZone(item) {
      if (item?.localisation?.timeConfig?.timeZone) {
        return item.localisation.timeConfig.timeZone
      } else if (this.currentCompany?.timeZone) {
        return this.currentCompany?.timeZone
      }
      return DEFAULT_TIMEZONE
    },
    formatDate,
    timeAgo,
    truncate,
  },
})

function getAccessToken() {
  let accessToken

  try {
    const params = new URLSearchParams(window.location.search)
    accessToken = params.get('accessToken')
  } catch (err) {
    // no need to log this error
  }

  // accessToken searchParam takes precedence, token cookie from Manage comes after
  if (accessToken == null) {
    accessToken = getTokenCookie()
  }

  return accessToken
}

const refreshTokenInterval = {
  intervalId: 0,
}
function stopRefreshingIdToken() {
  if (refreshTokenInterval.intervalId) {
    clearInterval(refreshTokenInterval.intervalId)
  }
}
function refreshIdToken(_firebaseApp) {
  stopRefreshingIdToken()
  const intervalInMilliseconds = 58 * 60 * 1000 // 58 minutes
  refreshTokenInterval.intervalId = window.setInterval(async function () {
    if (_firebaseApp.auth().currentUser) {
      const response = await apolloClient.refreshCustomToken()
      if (
        response != null &&
        response.refreshCustomToken != null &&
        response.refreshCustomToken.customToken != null
      ) {
        setTokenCookie(response.refreshCustomToken.customToken)
      }
    }
  }, intervalInMilliseconds)
}

let firstAuth = true

firebaseApp.auth().onAuthStateChanged((user) => {
  store
    .dispatch('authChange', user)
    .then((success) => {
      if (!success) {
        if (firstAuth) {
          firstAuth = false
        } else {
          Sentry.configureScope((scope) => scope.setUser(null))
          stopRefreshingIdToken()
          deleteTokenCookie()
          firebaseApp.auth().signOut()
        }
        return
      }

      const accessToken = getAccessToken()
      const userData = store.getters.user
      const companyData = store.getters.company

      if (!user && !accessToken) {
        // NOT LOGGED IN
        if (router.currentRoute.path !== '/signin') {
          localStorage.removeItem('plausible_ignore')
          stopRefreshingIdToken()
          router.push('/signin').catch(() => {})
        }
      }

      // FIRST TIME HERE
      if (user && !userData) {
        stopRefreshingIdToken()
        router.push('/signup').catch(() => {})
      }

      // Tracking.
      if (userData) {
        refreshIdToken(firebaseApp)

        // Don't track Captego employees.
        if (isCaptegoEmail(user.email)) {
          localStorage.setItem('plausible_ignore', true)
        }

        const userProps = {
          id: userData.uid,
          platform: 'web',
        }
        if (companyData) {
          userProps.companyId = companyData.id
          // userProps.companyTitle = companyData.title
          // if (companyData.logo) userProps.companyLogo = companyData.logo
        }

        Sentry.setUser({ id: userData.uid, companyId: companyData.id })
      }
    })
    .catch((err) => {
      console.log(
        "Looks like you're not an admin user or do not have a company account",
        err
      )
      localStorage.removeItem('plausible_ignore')
      stopRefreshingIdToken()
      deleteTokenCookie()
      firebaseApp.auth().signOut()
      if (router.currentRoute.path !== '/signin') router.push('/signin')
    })
})

router.beforeEach(async (to, from, next) => {
  trackPageview({
    url: redactIDFromURL(to.path, to.params),
  })

  store.dispatch('hideRootNavigation')
  store.dispatch('removeAllPanels')

  if (to.name === 'signout') {
    stopRefreshingIdToken()
    deleteLogoutCookie()
  }

  const accessToken = getAccessToken()
  let isAuthUser = false
  if (accessToken) {
    try {
      await firebaseApp
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      const response = await firebaseApp
        .auth()
        .signInWithCustomToken(accessToken)
      isAuthUser = !!response.user.uid
    } catch (err) {
      console.log('Firebase customToken error', err)
      stopRefreshingIdToken()
      setLogoutCookie()

      deleteTokenCookie()
      deleteLogoutCookie()
      await firebaseApp.auth().signOut()

      redirectToManage()
      return
    }
  } else {
    isAuthUser = Boolean(firebaseApp.auth().currentUser)
  }

  if (getLogoutCookie() === 'manage' && !getTokenCookie()) {
    stopRefreshingIdToken()
    next('/signout')
    return
  }

  if (isAuthUser && to.name === 'signin') {
    next('/')
    return
  }

  if (!isAuthUser && !getTokenCookie()) {
    stopRefreshingIdToken()
    setLogoutCookie()
    await firebaseApp.auth().signOut()

    redirectToManage()
    return
  }
  next()
})

// Configure Vue to ignore web components.
Vue.config.ignoredElements = ['captego-nav']

const app = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
