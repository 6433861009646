import { firestore } from '@/firebase'
import { apolloClient } from '@/gql/authenticatedClient.js'
import i18n from '@/i18n'
import { buildQuery, computeStatus, getContentNumber } from '../vuexHelpers.js'
import getTrackingFields from '@/utils/getTrackingFields'

let captureSubscription

const state = {
  captures: [],
  captureHistory: null,
}
const mutations = {
  SET_CAPTURES(state, payload) {
    state.captures = payload
  },
  SET_CAPTURE_HISTORY(state, payload) {
    state.captureHistory = payload
  },
}

const captureDefault = {
  registrationDrawing: {
    title: 'N/A',
    imageId: 'missing',
    imageUrl: '',
    imageMeta: {
      fullPath: '',
      height: 1,
      width: 1,
      name: '',
    },
  },
}

const getters = {
  getCaptures: (state) => {
    // Builds a parent/child/root object
    return state.captures.map((capture) => {
      const history = {
        ...capture.history,
        root:
          capture.history && capture.history.root
            ? capture.history.root
            : state.captures.find(
                (captureWithRoot) =>
                  captureWithRoot.history &&
                  captureWithRoot.history.parent === capture.id
              )
            ? state.captures.find(
                (captureWithRoot) =>
                  captureWithRoot.history &&
                  captureWithRoot.history.parent === capture.id
              ).history.root
            : null,
        children: state.captures
          .filter(
            (captureToFilter) =>
              captureToFilter.history &&
              captureToFilter.history.parent === capture.id
          )
          .map((captureToMap) => captureToMap.id),
        childrenPending: state.captures.filter(
          (captureToFilter) =>
            captureToFilter.history &&
            captureToFilter.history.parent === capture.id &&
            captureToFilter.pending
        ),
        childrenApproved: state.captures
          .filter(
            (captureToFilter) =>
              captureToFilter.history &&
              captureToFilter.history.parent === capture.id &&
              !captureToFilter.pending
          )
          .map((captureToMap) => captureToMap.id),
      }
      history.allCapturesInHistory = [
        ...new Set(
          [
            history.parent,
            ...history.children,
            history.root,
            capture.id,
          ].filter((id) => id !== undefined && id !== null)
        ),
      ]
      return {
        ...capture,
        history,
      }
    })
  },
}

const actions = {
  async setCaptureValue({ rootState }, payload) {
    const result = payload.values.reduce(function (map, obj) {
      map[obj.key] = obj.value || null
      return map
    }, {})

    let ref
    console.log(payload)
    if (payload.captureCollection && payload.captureCollection === 'reports') {
      ref = firestore
        .collection('reports')
        .doc(payload.captureCollectionDocumentId)
        .collection('captures')
    } else {
      ref = firestore.collection(payload.isDraft ? 'captureDrafts' : 'captures')
    }

    return await ref.doc(payload.captureId).update({
      ...getTrackingFields(
        rootState.User.user.uid,
        'stores.captures.setCaptureValue'
      ),
      ...result,
    })
  },
  async updateCapture({ rootState }, payload) {
    let ref
    if (payload.captureCollection && payload.captureCollection === 'reports') {
      ref = firestore
        .collection('reports')
        .doc(payload.captureCollectionDocumentId)
        .collection('captures')
    } else {
      ref = firestore.collection(payload.isDraft ? 'captureDrafts' : 'captures')
    }

    return ref.doc(payload.captureId).update({
      ...getTrackingFields(
        rootState.User.user.uid,
        'stores.captures.updateCapture'
      ),
      ...payload.captureData,
    })
  },
  async getEnrichedInspectionCaptures({ rootState }, { inspectionId }) {
    try {
      const inspection = await firestore
        .collection('inspections')
        .doc(inspectionId)
        .get()

      // Figure out companyId of company that created inspection
      if (!inspection || !inspection.data()) throw 'Inspection not found'
      const inspectionCompanyId = inspection.data().companyId

      // If user is admin and inspection is created by the company, scope is company
      const isAdmin =
        rootState.User.user.adminRole === 'admin' ||
        rootState.User.user.adminRole === 'super_admin'
      const scope =
        rootState.User.company.id === inspectionCompanyId && isAdmin
          ? 'company'
          : 'shared'

      let query = firestore
        .collection('captures')
        .where('inspectionId', '==', inspectionId)
        .where('archived', '==', false)

      /*
      if (pending !== undefined) {
        query = query.where('pending', '==', pending)
      }
      */

      query = buildQuery(query, {
        scope,
        rootState,
      })
      const captures = await query.get()
      return captures.docs.map((capture) => {
        const {
          companyId,
          permissions,
          archived,
          permissionIds,
          userId,
          userCompanyId,
          imageMeta,
          ...captureData
        } = capture.data()
        return { ...captureData, status: computeStatus(captureData) }
      })
    } catch (err) {
      console.log('Could not subscribe to captures', err)
    }
  },
  async subscribeToCaptures(
    { commit, rootState },
    { inspectionId, type: captureType }
  ) {
    try {
      const inspection = await firestore
        .collection('inspections')
        .doc(inspectionId)
        .get()

      // Figure out companyId of company that created inspection
      if (!inspection || !inspection.data()) throw 'Inspection not found'
      const inspectionCompanyId = inspection.data().companyId

      // If user is admin and inspection is created by the company, scope is company
      const isAdmin =
        rootState.User.user.adminRole === 'admin' ||
        rootState.User.user.adminRole === 'super_admin'
      const scope =
        rootState.User.company.id === inspectionCompanyId && isAdmin
          ? 'company'
          : 'shared'

      let query = firestore
        .collection('captures')
        .where('inspectionId', '==', inspectionId)
        .where('archived', '==', false)

      /*
      if (pending !== undefined) {
        query = query.where('pending', '==', pending)
      }
      */

      query = buildQuery(query, {
        scope,
        rootState,
      })
      return new Promise((resolve, reject) => {
        var resolveOnce = (doc) => {
          resolveOnce = () => {}
          resolve(doc)
        }
        captureSubscription = query.onSnapshot((querySnapshot) => {
          const all = querySnapshot.docs
            .map((doc) => {
              // CALCULATE THE REAL STATUS AND PUT TO ROOT
              const docData = doc.data()
              return {
                ...captureDefault,
                ...docData,
                id: doc.id,
                status: computeStatus(docData),
                ...getContentNumber(doc.data()),
              }
            })
            .filter(
              (capture) =>
                capture.type !== 'nonecapture' || captureType === 'nonecapture'
            )
          resolveOnce()
          commit('SET_CAPTURES', all)
        })
      })
    } catch (err) {
      console.log('Could not subscribe to captures', err)
    }
  },
  unsubscribeToCaptures({ commit }) {
    try {
      captureSubscription()
      commit('SET_CAPTURES', [])
    } catch {
      console.log('Nothing to unsubscribe')
    }
  },
  async setCaptureArchiveStatus(context, payload) {
    console.log('archiving', payload)
    // GQL later: delete multiple captures endpoint.
    const promises = payload.captures.map((capture) => {
      return apolloClient.deleteCapture({ input: { id: capture.id } })
    })
    await Promise.all(promises)
  },
  resetCaptureHistory({ commit }) {
    commit('SET_CAPTURE_HISTORY', null)
  },
  async deleteCaptures({ dispatch }, { captureIdArray }) {
    dispatch('addToast', {
      message: i18n.t('inspections:toast.deleteCapturesPending', {
        count: captureIdArray.length,
      }),
      type: 'pending',
    })
    try {
      // FIRST SET ARCHIVED FOR FAST REMOVAL FROM UI, THEN HANDLE WITH CALLABLE
      console.log(
        'ID Array',
        captureIdArray.map((id) => ({ id }))
      )
      dispatch('setCaptureArchiveStatus', {
        captures: captureIdArray.map((id) => ({ id })),
        value: true,
      })

      dispatch('addToast', {
        message: i18n.t('inspections:toast.deletedCapture', {
          count: captureIdArray.length,
        }),
        type: 'good',
      })
      return
    } catch (error) {
      console.log(error)
      dispatch('addToast', {
        message:
          i18n.t('inspections:toast.deleteCapturesFailed', {
            count: captureIdArray.length,
          }) + error,
        type: 'bad',
      })
    }
  },
  async recalculateCaptureTags({ dispatch, rootState }, { inspectionId }) {
    const makePaths = (tags, res = {}, prefix = []) => {
      tags.forEach((tag) => {
        const values = [...prefix, tag.title]
        Object.assign(res, { [tag.id]: values })
        if (tag.tags) {
          makePaths(tag.tags, res, values)
        }
      })
      return res
    }

    try {
      const inspectionTags = await dispatch('fetchInspectionTags', {
        inspectionId,
        nest: true,
      })

      const pathMap = makePaths(inspectionTags)

      const captures = await firestore
        .collection('captures')
        .where('inspectionId', '==', inspectionId)
        .where('companyId', '==', rootState.User.company.id)
        .get()

      const batch = firestore.batch()
      captures.forEach((capture) => {
        let { tags: captureTags, tagId } = capture.data()
        let newTagId = false

        // If capture does not have tagId (old captego capture) then map tag array to tagId
        if (!tagId) {
          newTagId = true
          const captureValueStr = JSON.stringify(captureTags)
          const pathObject = Object.keys(pathMap).find(
            (key) => JSON.stringify(pathMap[key]) === captureValueStr
          )
          tagId = pathObject
        }

        // UPDATE if new tag path or if tagId has been attached AND tagId exists in pathMap
        if (
          tagId &&
          pathMap[tagId] &&
          (JSON.stringify(captureTags) !== JSON.stringify(pathMap[tagId]) ||
            newTagId)
        ) {
          batch.update(firestore.collection('captures').doc(capture.id), {
            ...getTrackingFields(
              rootState.User.user.uid,
              'stores.captures.recalculateCaptureTags'
            ),
            tags: pathMap[tagId],
            tagId,
          })
        }
      })
      batch
        .commit()
        .then(() => {
          console.log('Recalculated capture tags')
        })
        .catch((err) => {
          console.error(err)
          throw new Error(err)
        })
    } catch (err) {
      console.error(err)
      throw new Error(err)
    }
  },
}

export default {
  namespaced: false,
  state,
  getters,
  mutations,
  actions,
}
