<template>
  <div>
    <sidepanel
      id="panel-object-permissions"
      :title="'Set permissions'"
      :processing="!permissionsLoaded"
      @done="closePermissions"
    >
      <div class="panel-object">
        <div>
          <strong class="text-sm">{{
            permissionObject ? permissionObject.title : ''
          }}</strong>
        </div>
      </div>
      <AccessList
        v-if="permissionObject"
        :item-id="permissionObject.id"
        :item-type="permissionObject.itemType"
        @viewUser="viewUser"
        @viewInvitation="viewInvitation"
      />
    </sidepanel>

    <template v-if="permissionObject">
      <PanelViewUser
        :user="viewUserObject"
        :item-type="permissionObject.itemType"
        :item-id="permissionObject.id"
      />
      <PanelInviteUsersInternal
        :permissions="permissionObject.permissions"
        :item-type="permissionObject.itemType"
        :item-id="permissionObject.id"
      />
      <PanelInviteUsersExternal
        :permissions="permissionObject.permissions"
        :item-type="permissionObject.itemType"
        :item-id="permissionObject.id"
      />
      <PanelViewInvitation :invitation="viewInvitationObject" />
    </template>
  </div>
</template>

<script>
import AccessList from './AccessList.vue'
import PanelInviteUsersExternal from './PanelInviteUsersExternal.vue'
import PanelInviteUsersInternal from './PanelInviteUsersInternal.vue'
import PanelViewInvitation from './PanelViewInvitation.vue'
import PanelViewUser from './PanelViewUser.vue'

export default {
  components: {
    AccessList,
    PanelViewUser,
    PanelInviteUsersInternal,
    PanelInviteUsersExternal,
    PanelViewInvitation,
  },
  data() {
    return {
      editActorData: null,
      viewUserObject: null,
      viewInvitationObject: null,
    }
  },
  computed: {
    permissionObject() {
      return this.$store.getters.permissionObject
    },
    permissionsLoaded() {
      return this.$store.getters.itemPermissionsLoaded
    },
  },
  methods: {
    viewUser(payload) {
      // console.log('view user', payload)
      this.viewUserObject = payload
      this.addPanel('userinfo-panel', { ...payload })
    },
    viewInvitation({ invitation }) {
      this.viewInvitationObject = invitation
      this.addPanel('view-invitation')
    },
    closePermissions() {
      this.$store.dispatch('resetObjectPermissions')
    },
  },
}
</script>
