const getTrackingFields = (userId, operation) => {
  return {
    updated: Date.now(),
    updatedAt: Date.now(),
    updatedBy: userId,
    updatedFrom: {
      origin: 'Admin',
      operation,
    },
  }
}

export default getTrackingFields
