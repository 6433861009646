<template>
  <sidepanel
    v-if="user"
    id="userinfo-panel"
    :title="$t('permissions:viewUser.panel.title')"
    :processing="processing"
    :color-b-g="user.isInternal ? '#32c9a8' : '#7b35bd'"
    :color-f-g="user.isInternal ? '#ffffff' : '#ffffff'"
  >
    <UserInfo
      :user-id="user.userId"
      :user-name="user.permission.name"
      :user-email="user.permission.email"
      :user-phone="user.permission.phone"
      :label="$t('permissions:roles.' + user.permission.role)"
      :is-internal="user.isInternal"
      @loaded="processing = false"
    />
    <div class="panel-actions">
      <button
        class="button red outline"
        type="button"
        @click="removePermission"
      >
        {{ $t('permissions:viewUser.button.removePermission') }}
      </button>
    </div>
  </sidepanel>
</template>

<script>
import UserInfo from '../UserInfo.vue'

export default {
  components: {
    UserInfo,
  },
  props: {
    user: {
      type: Object,
      required: false,
      default: null,
    },
    itemId: {
      type: String,
      required: true,
    },
    itemType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      processing: false,
    }
  },
  mounted() {
    this.processing = true
  },
  methods: {
    async removePermission() {
      if (confirm(this.$t('permissions:removePermission.confirmation'))) {
        this.processing = true
        this.trackEvent({
          category: 'Workflow',
          action: 'Revoke permission',
          itemType: this.itemType,
        })
        await this.$store.dispatch('removePermissions', {
          type: this.itemType,
          itemId: this.itemId,
          userIds: [this.user.userId],
        })
        this.processing = false
        this.removePanel()
      }
    },
  },
}
</script>
