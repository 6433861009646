import { getSdk } from './generated'

const validDocDefOps = ['mutation', 'query', 'subscription']

export function getSdkApollo(client) {
  const requester = async (doc, variables, options) => {
    // Valid document should contain *single* query or mutation unless it's has a fragment
    if (
      doc.definitions.filter(
        (d) =>
          d.kind === 'OperationDefinition' &&
          validDocDefOps.includes(d.operation)
      ).length !== 1
    ) {
      throw new Error(
        'DocumentNode passed to Apollo Client must contain single query or mutation'
      )
    }
    const definition = doc.definitions[0]
    // Valid document should contain *OperationDefinition*
    if (definition.kind !== 'OperationDefinition') {
      throw new Error(
        'DocumentNode passed to Apollo Client must contain single query or mutation'
      )
    }
    switch (definition.operation) {
      case 'mutation': {
        const response = await client.mutate(
          Object.assign({ mutation: doc, variables }, options)
        )
        if (response.errors) {
          console.error(response.errors)
          // throw new ApolloRequestError(response.errors)
        }
        if (response.data === undefined || response.data === null) {
          throw new Error('No data presented in the GraphQL response')
        }
        return response.data
      }
      case 'query': {
        const response = await client.query(
          Object.assign({ query: doc, variables }, options)
        )
        if (response.errors) {
          console.error(response.errors)
          // throw new ApolloRequestError(response.errors)
        }
        if (response.data === undefined || response.data === null) {
          throw new Error('No data presented in the GraphQL response')
        }
        return response.data
      }
      case 'subscription': {
        throw new Error(
          'Subscription requests through SDK interface are not supported'
        )
      }
    }
  }
  return getSdk(requester)
}
