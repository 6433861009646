import { asyncForEach } from '../../vuexHelpers.js'

export const revokeBatchInvitations = async (
  { dispatch },
  { usersWithInvitations }
) => {
  const actions = []
  usersWithInvitations.forEach((user) => {
    user.invitations.forEach((invitation) => {
      actions.push({
        code: invitation.code,
        type: 'inspections',
      })
    })
  })
  await asyncForEach(actions, async (action) => {
    await dispatch('deleteInvitation', {
      type: action.type,
      code: action.code,
    })
  })
}
