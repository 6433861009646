import { firestore, storage } from '@/firebase'
import { apolloClient } from '@/gql/authenticatedClient'
import i18n from '@/i18n'

const state = {
  projects: [],
  editProject: null,
  projectChanges: {},
}

const getters = {
  getProjects: (state) => state.projects,
  getEditProject: (state) => state.editProject,
}

const mutations = {
  SET_PROJECTS(state, arr) {
    state.projects = arr
  },
  SET_EDITPROJECT(state, doc) {
    state.editProject = doc
  },
  UPDATE_PROJECT(state, payload) {
    const idx = state.projects.findIndex((obj) => obj.id === payload.projectId)
    state.projects[idx].archived = payload.archived
    if (state.editProject.id === payload.projectId) {
      state.editProject.archived = payload.archived
    }
  },
  UPDATE_EDITPROJECT(state) {
    const idx = state.projects.findIndex(
      (obj) => obj.id === state.editProject.id
    )
    state.projects[idx] = state.editProject
  },
  DELETE_PROJECTREGISTRATIONDRAWING(state, payload) {
    const idx = state.projects.findIndex((obj) => obj.id === payload.projectId)
    const drawings = state.projects[idx].registrationDrawings
    const drawingIndex = drawings.findIndex(
      (obj) => obj.imageId === payload.imageId
    )
    state.projects[idx].registrationDrawings.splice(drawingIndex, 1)
  },
  PROJECT_CHANGE(state, { projectId, change }) {
    state.projectChanges[projectId] = change
  },
}

let projectsListUnsubscription
let projectUnsubscription

const actions = {
  async addProject(
    { rootState, dispatch },
    { title, variableTags, addAutoVariables }
  ) {
    dispatch('addToast', {
      message: i18n.t('projects:toast.creatingProject'),
      type: 'pending',
    })
    try {
      const response = await apolloClient.createProject({
        input: {
          title,
          variableTags,
        },
      })

      const project = response.createProject.project

      dispatch('addToast', {
        message: i18n.t('projects:toast.createProjectSuccess'),
        type: 'good',
      })

      return project
    } catch (err) {
      console.log('Error creating project', err)
      dispatch('addToast', {
        message: i18n.t('projects:toast.createProjectFail', { error: err }),
        type: 'bad',
      })
      throw new Error(err)
    }
  },
  deleteStorageFile(context, payload) {
    const fileRef = storage.ref(payload.fullPath)
    console.log('delete', fileRef, payload.fullPath)
    // return fileRef.delete();
  },
  async deleteProject({ commit }, payload) {
    try {
      await apolloClient.deleteProject({ input: { id: payload.projectId } })
      commit('PROJECT_CHANGE', {
        projectId: payload.projectId,
        change: 'delete',
      })
      console.log('Deleted project')
      return
    } catch (err) {
      throw new Error(err)
    }
  },
  async setProjectTitle({ rootState, dispatch, commit }, payload) {
    await apolloClient.setProjectTitle({
      input: payload,
    })
  },
  async setProjectImage({ rootState, dispatch, commit }, payload) {
    await apolloClient.setProjectImage({
      input: payload,
    })
  },
  async removeProjectImage({ rootState, dispatch, commit }, payload) {
    await apolloClient.removeProjectImage({
      input: payload,
    })
  },
  async addProjectRegistrationDrawings(
    { rootState, dispatch, commit },
    payload
  ) {
    await apolloClient.addProjectRegistrationDrawings({
      input: payload,
    })
  },
  async removeProjectRegistrationDrawings(
    { rootState, dispatch, commit },
    payload
  ) {
    await apolloClient.removeProjectRegistrationDrawings({
      input: payload,
    })
  },
  async updateProjectRegistrationDrawingTitle(
    { rootState, dispatch, commit },
    payload
  ) {
    await apolloClient.updateProjectRegistrationDrawingTitle({
      input: payload,
    })
  },
  async updateProjectRegistrationDrawingIsNoLocation(
    { rootState, dispatch, commit },
    payload
  ) {
    await apolloClient.updateProjectRegistrationDrawingIsNoLocation({
      input: payload,
    })
  },
  async updateProjectRegistrationDrawingAutoAddToNewInspections(
    { rootState, dispatch, commit },
    payload
  ) {
    await apolloClient.updateProjectRegistrationDrawingAutoAddToNewInspections({
      input: payload,
    })
  },
  saveProjectVariables({ rootState, dispatch, commit }, payload) {
    dispatch('addToast', {
      message: i18n.t('projects:toast.savingProject'),
      type: 'pending',
    })
    const docId = payload.id
    const variables = payload.variables

    if (docId && variables) {
      firestore
        .collection('projects')
        .doc(docId)
        .update({ variables, updated: Date.now() })
        .then(() => {
          dispatch('addToast', {
            message: i18n.t('projects:toast.savedProjectSuccess'),
            type: 'good',
          })
          // commit("UPDATE_EDITPROJECT");
          return true
        })
        .catch((error) => {
          console.log(error)
          dispatch('addToast', {
            message: 'Failed to save (' + error + ')',
            type: 'bad',
          })
        })
    }
  },
  async setEditProject({ commit }, id) {
    projectUnsubscription = await firestore
      .collection('projects')
      .doc(id)
      .onSnapshot((doc) => {
        commit('SET_EDITPROJECT', { ...doc.data(), id: doc.id })
      })
  },
  async unsetEditProject({ commit }) {
    projectUnsubscription()
    commit('SET_EDITPROJECT', null)
  },
  async getProjectById(context, id) {
    if (id) {
      const snap = await firestore.collection('projects').doc(id).get()

      if (snap.exists) {
        return { ...snap.data(), id: snap.id }
      } else {
        return Promise.reject('Could not get project')
      }
    } else {
      return Promise.reject('No project ID provided')
    }
  },
  async searchProjects(
    { rootGetters, rootState, state },
    { query, hitsPerPage, page = 0, adminOrOwnerOnly, onlyCompanyOwned = false }
  ) {
    const algoliaClient = rootGetters['Algolia/client']
    algoliaClient.clearCache()
    const searchIndex = algoliaClient.initIndex(
      `projects__${
        import.meta.env.VUE_APP_PROJECT_ID === 'captego-prod-eu'
          ? 'production'
          : 'development'
      }`
    )
    try {
      let filters
      if (rootState.User.user.adminRole === 'user') {
        // USER (non-admin)
        filters = `permissionIds:${rootState.User.user.uid}`
      } else {
        // ADMIN
        if (onlyCompanyOwned) {
          filters = `companyId:${rootState.User.user.companyId}`
        } else {
          filters = `permissionIds:${rootState.User.user.uid} OR companyId:${rootState.User.user.companyId}`
        }
      }

      const response = await searchIndex.search(query, {
        filters,
        page,
        hitsPerPage,
      })
      let hits
      if (adminOrOwnerOnly) {
        // FILTER OUR PROJECTS THAT THE USER DOES NOT OWN OR IS ADMIN FOR
        hits = response.hits.filter((project) => {
          const userProjectRole =
            project.permissions && project.permissions[rootState.User.user.uid]
              ? project.permissions[rootState.User.user.uid].role
              : null
          return (
            (project.companyId === rootState.User.user.companyId &&
              rootState.User.user.adminRole === 'admin') ||
            userProjectRole === 'owner'
          )
        })
      } else {
        hits = response.hits
      }

      hits = hits
        .map((project) => {
          if (project.id == null && project.objectID != null) {
            project.id = project.objectID
          }
          return project
        })
        .filter((project) => state.projectChanges[project.id] !== 'delete')

      return {
        ...response,
        hits,
      }
    } catch (err) {
      console.log('Failed to search', err)
      return err
    }
  },
}

export default {
  namespaced: false,
  state,
  getters,
  mutations,
  actions,
}
