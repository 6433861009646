import { Replay } from '@sentry/replay'
import { BrowserTracing } from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import Vue from 'vue'

export default function (router) {
  let environment = ''
  try {
    environment = window.location.hostname
  } catch {}

  if (import.meta.env.PROD) {
    Sentry.init({
      Vue,
      environment,
      debug: false,
      dsn: 'https://b9b379e4dff7454e95e1c1468f49f52e@o234970.ingest.sentry.io/5947394',
      tracesSampleRate: 0.2,
      release: 'admin@3.58.0',
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: [
            'setup.captego.com',
            'captego-prod-eu.ew.r.appspot.com',
            'captego-dev-eu.ew.r.appspot.com',
            /^\//,
          ],
        }),
        new Replay({
          // Capture 10% of all sessions
          sessionSampleRate: 0.1,

          // Of the remaining 90% of sessions, if an error happens start capturing
          errorSampleRate: 1.0,
        }),
      ],
      ignoreErrors: [
        'Network request failed',
        'A network error (such as timeout, interrupted connection or unreachable host) has occurred.',
      ],
    })
  }
}
