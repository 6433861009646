<template>
  <div class="panel-object">
    <div
      v-if="actor !== null"
      class="user-info-container user-info-container-external"
    >
      <div class="user-name">{{ actor.name }}</div>
      <div v-if="actor.address" class="user-title">{{ actor.address }}</div>
      <div v-if="actor.email" class="user-text">{{ actor.email }}</div>
      <div v-if="actor.phone" class="user-text">{{ actor.phone }}</div>
      <div v-if="actor.companyName" class="user-text">
        {{ actor.companyName }}
      </div>
      <div v-if="label" class="permission-role permission-role-external large">
        {{ label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    actorId: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      actor: null,
    }
  },
  async mounted() {
    try {
      this.actor = await this.$store.dispatch('getActorById', {
        actorId: this.actorId,
      })
      this.$emit('loaded')
    } catch (error) {
      console.log(error)
    }
  },
}
</script>

<style lang="less" scoped>
.user-info-container-external {
  border: 2px solid var(--color-actor);
  padding: var(--space-x1);
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.user-name {
  font-size: var(--text-sm);
  font-weight: var(--font-weight-bd);
}

.user-text {
  color: var(--color-grey-dark);
  font-size: var(--text-xs);
}

.user-label {
  font-weight: var(--font-weight-bd);
  background-color: var(--color-black);
  color: var(--color-white);
  padding: 0 var(--space-half);
  margin-top: var(--space-half);
  text-transform: uppercase;
}

.permission-role {
  margin-top: var(--space-x1);
}
</style>
