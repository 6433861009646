<template>
  <div>
    <captego-nav
      :color-scheme="colorScheme"
      :company-id="currentCompany?.id"
      :company-name="currentCompany?.title"
      :custom-reports-url="customReportsUrl"
      :invitations="invitations.length"
      :is-expanded="isNavigationExpanded"
      :is-loading="!currentCompany"
      :locale="locale"
      :logo="currentCompany?.logo"
      :route-path="routePath"
      :show-access-control="showAccessControl"
      :show-accounts="isSuperAdmin"
      :show-company="isAdminOrSuperAdmin"
      :show-custom-reports="showCustomReports"
      :show-report-templates="showReportTemplates"
      :show-templates="isAdminOrSuperAdmin"
      :user-email="currentUser.email"
      :user-id="currentUser.id"
      :user-name="currentUser.name"
      :user-phone-number="currentUser.phoneNumber"
    ></captego-nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      locale: this.$i18n.i18next.language,
    }
  },
  computed: {
    colorScheme() {
      return this.$store.getters.colorScheme
    },
    customReportsUrl() {
      return `${import.meta.env.VUE_APP_MANAGE_URL}/reports`
    },
    previewReportsUrl() {
      return `${import.meta.env.VUE_APP_MANAGE_URL}/preview-reports`
    },
    invitations() {
      return this.$store.getters.myInvitations
    },
    isNavigationExpanded() {
      return this.$store.getters.isRootNavigationExpanded
    },
    routePath() {
      return this.$route.path
    },
    showAccessControl() {
      const inspectionsBeta = this.featureLimit('inspectionsBeta')
      return inspectionsBeta.isLimited && inspectionsBeta.limitation > 0
    },
    showCustomReports() {
      const reportDesigner = this.featureLimit('reportDesigner')
      return reportDesigner.isLimited && reportDesigner.limitation > 0
    },
    showReportTemplates() {
      const limits = this.featureLimit('previewReports')
      return limits.isLimited && limits.limitation > 0
    },
  },
  mounted() {
    this.$store.dispatch('subscribeToMyInvitations')
    document.addEventListener('nav-navigate', this.handleNavigation)
    document.addEventListener('nav-show-invitations', this.showInvitations)
    document.addEventListener('nav-toggle-color-scheme', this.toggleColorScheme)
    document.addEventListener('nav-toggle', this.toggleNavigation)
    this.$i18n.i18next.on('languageChanged', this.languageChanged)
  },
  beforeDestroy() {
    this.$store.dispatch('unsubscribeToMyInvitations')
    document.removeEventListener('nav-navigate', this.handleNavigation)
    document.removeEventListener('nav-show-invitations', this.showInvitations)
    document.removeEventListener(
      'nav-toggle-color-scheme',
      this.toggleColorScheme
    )
    document.removeEventListener('nav-toggle', this.toggleNavigation)
    this.$i18n.i18next.off('languageChanged', this.languageChanged)
  },
  methods: {
    handleNavigation({ detail: { href } }) {
      if (href != null) {
        if (href === this.$route.path) {
          this.$router.go(href)
        } else {
          this.$router.push(href).catch((err) => {
            console.log(err)
          })
        }
      }
    },
    languageChanged(lang) {
      this.locale = lang
    },
    showInvitations() {
      this.addPanel('navigation-invitations')
    },
    toggleColorScheme() {
      this.$store.dispatch('toggleColorScheme')
    },
    toggleNavigation() {
      this.$store.dispatch('toggleRootNavigationSize')
    },
  },
}
</script>
