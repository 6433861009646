import { apolloClient } from '@/gql/authenticatedClient'
import algoliasearch from 'algoliasearch'

const state = {
  algoliaClient: undefined,
  prevUserId: undefined,
}

const getters = {
  client: (state) => state.algoliaClient,
}

const mutations = {
  INIT_ALGOLIA(state, { algoliaClient, uid }) {
    state.algoliaClient = algoliaClient
    state.prevUserId = uid
  },
  DESTROY_ALGOLIA(state) {
    state.algoliaClient = undefined
    state.prevUserId = undefined
  },
}

const actions = {
  async authChange({ commit, state }, uid) {
    if (!!uid && uid !== state.prevUserId) {
      try {
        const response = await apolloClient.getAlgoliaSearchKey()
        const searchKey = response.getAlgoliaSearchKey
        const algoliaClient = algoliasearch(
          import.meta.env.VUE_APP_ALGOLIA_APP_ID,
          searchKey
        )
        commit('INIT_ALGOLIA', { algoliaClient, uid })
      } catch (err) {
        console.error(err)
      }
    } else if (!uid && !!state.prevProfileId) {
      commit('DESTROY_ALGOLIA')
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
