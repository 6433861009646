import { languageMap } from '@/constants/languageMap'
import { firebaseApp, firestore } from '@/firebase'
import { apolloClient } from '@/gql/authenticatedClient'
import i18n from '@/i18n'
import { setCookie } from '@/utils/cookies'

const state = {
  user: null,
  company: null,
  locale: 'en-GB',
}

const getters = {
  colorScheme: (state) => state.user?.colorScheme ?? 'light',
  user: (state) => state.user,
  company: (state) => state.company,
}

const mutations = {
  SET_USER(state, user) {
    state.user = user
  },
  SET_COMPANY(state, company) {
    state.company = company
  },
  SET_COMPANY_LANGUAGE(state, lng) {
    state.company.language = lng
  },
  SET_COLOR_SCHEME(state, colorScheme) {
    state.user.colorScheme = colorScheme
  },
}

const actions = {
  async createNewCompany({ commit }, payload) {
    const ref = firestore.collection('companies').doc()
    const id = ref.id
    const newCompany = { ...payload, id, created: Date.now() }
    return firestore
      .collection('companies')
      .doc(id)
      .set(newCompany)
      .then(() => {
        commit('SET_COMPANY', newCompany)
      })
  },
  async createNewUser({ commit }, payload) {
    return firestore
      .collection('users')
      .doc(payload.uid)
      .set({ ...payload, created: Date.now() })
      .then(() => {
        return commit('SET_USER', payload)
      })
  },
  setCompanyLanguage({ commit, dispatch }, lng) {
    commit('SET_COMPANY_LANGUAGE', lng)
    dispatch('setLocale')
  },
  async signup({ dispatch, state }, payload) {
    await dispatch('createNewCompany', payload.newCompany)
    payload.newUser.companyId = state.company.id
    await dispatch('createNewUser', payload.newUser)
  },
  async setCompany({ commit }, companyId) {
    const companyDoc = await firestore
      .collection('companies')
      .doc(companyId)
      .get()
    const companyData = { ...companyDoc.data(), id: companyDoc.id }
    if (!companyData) return false
    commit('SET_COMPANY', {
      id: companyDoc.id,
      ...companyData,
      language: companyData.language || 'da-DK',
    })
    return companyData
  },
  async setLocale({ state }) {
    // set i18n language
    const params = new URLSearchParams(document.location.search)
    const locale = params.get('locale')
    const userLanguage = state.user.language
    const companyLanguage = state.company.language

    if (!companyLanguage) {
      console.log('Company language missing, using default')
    }
    const languageSettings =
      languageMap[locale || userLanguage || companyLanguage] ||
      languageMap.default
    i18n.i18next.changeLanguage(languageSettings.translations)
  },
  async authChange({ dispatch, commit }, user) {
    if (user) {
      // GET USER DATA IN FS
      const userDoc = await firestore.collection('users').doc(user.uid).get()
      if (userDoc) {
        const userData = { ...userDoc.data(), id: userDoc.id }
        if (!userData.created) {
          userData.created = user.metadata.creationTime
        }

        // USER IS ADMIN, SET USER AND COMPANY
        await dispatch('Algolia/authChange', user.uid, { root: true })
        commit('SET_USER', { ...userData, uid: user.uid })
        await dispatch('setCompany', userData.companyId)

        await dispatch('getCompanyPlan', {
          companyId: userData.companyId,
          isCurrentCompany: true,
        })

        await dispatch('setLocale') // Must be called after setCompany

        return true
      }
    }

    // NO USER
    commit('SET_USER', null)
    commit('SET_COMPANY', null)
    dispatch('Algolia/authChange', null, { root: true })
  },
  async saveCompany({ commit, dispatch }, payload) {
    try {
      dispatch('addToast', {
        message: i18n.t('company:toast.savingCompany'),
        type: 'pending',
      })

      await apolloClient.updateCompany({
        input: {
          colorBG: payload.style.colorBG,
          colorFG: payload.style.colorFG,
          companyId: payload.id,
          dashboardUrl: payload.dashboardUrl,
          footer: payload.report.footer,
          locale: payload.language,
          timeZone: payload.timeZone,
          logo: payload.logo,
          name: payload.title,
        },
      })

      dispatch('setCompanyLanguage', payload.language)

      commit('SET_COMPANY', payload)
      dispatch('addToast', {
        message: i18n.t('company:toast.savedCompanySuccess'),
        type: 'good',
      })
    } catch (err) {
      console.error(err)
      dispatch('addToast', {
        message: 'Failed to save company',
        type: 'bad',
      })
    }
  },
  async saveProfile({ commit, dispatch }, payload) {
    try {
      const promises = []

      if (state.user.name !== payload.name) {
        promises.push(
          apolloClient.setUserName({
            input: {
              name: payload.name,
            },
          })
        )
      }

      if (state.user.language !== payload.language) {
        promises.push(
          apolloClient.setUserLocale({
            input: {
              locale: payload.language,
            },
          })
        )
      }

      if (promises.length > 0) {
        dispatch('addToast', {
          message: i18n.t('profile:toast.savingProfile'),
          type: 'pending',
        })

        await Promise.all(promises)

        commit('SET_USER', { ...state.user, ...payload })
        dispatch('setLocale')
        dispatch('addToast', {
          message: i18n.t('profile:toast.savedProfileSuccess'),
          type: 'good',
        })
      }
    } catch (err) {
      console.error(err)
      dispatch('addToast', {
        message: 'Failed to save profile',
        type: 'bad',
      })
    }
  },
  async savePassword({ commit, dispatch }, payload) {
    try {
      if (payload.currentPassword !== 0 && payload.newPassword.length >= 8) {
        dispatch('addToast', {
          message: i18n.t('profile:toast.savingPassword'),
          type: 'pending',
        })

        const response = await apolloClient.changePassword({
          input: {
            email: state.user.email,
            currentPassword: payload.currentPassword,
            newPassword: payload.newPassword,
          },
        })

        await firebaseApp
          .auth()
          .signInWithCustomToken(response.changePassword.customToken ?? '')

        dispatch('addToast', {
          message: i18n.t('profile:toast.savedPasswordSuccess'),
          type: 'good',
        })
      }
    } catch (err) {
      console.error(err)
      dispatch('addToast', {
        message: err.message,
        type: 'bad',
      })
    }
  },
  async toggleColorScheme(context) {
    const currentColorScheme = state.user.colorScheme
    const newColorScheme = currentColorScheme === 'dark' ? 'light' : 'dark'
    try {
      context.commit('SET_COLOR_SCHEME', newColorScheme)
      const response = await apolloClient.setUserColorScheme({
        input: { colorScheme: newColorScheme },
      })
      setCookie('color-mode', newColorScheme)
    } catch (error) {
      context.commit('SET_COLOR_SCHEME', currentColorScheme)
    }
  },
}

export default {
  namespaced: false,
  state,
  getters,
  mutations,
  actions,
}
