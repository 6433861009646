<template>
  <div class="lds-ring" :class="{ small: small, white: white }">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<script>
export default {
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    white: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="less" scoped>
.lds-ring {
  display: inline-block;
  position: relative;
  width: var(--space-x8);
  height: var(--space-x8);
  &.small {
    width: var(--space-x4);
    height: var(--space-x4);
    div {
      width: var(--space-x2);
      height: var(--space-x2);
      margin: var(--space-x1);
    }
  }
  &.white {
    div {
      border: 3px solid var(--color-white);
      border-color: #fff transparent transparent transparent;
    }
  }
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: var(--space-x4);
    height: var(--space-x4);
    margin: var(--space-x2);
    border: 3px solid var(--color-black);
    border-radius: var(--radius-circle);
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #000 transparent transparent transparent;
    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
