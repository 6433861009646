import { firestore } from '@/firebase'
import { apolloClient } from '@/gql/authenticatedClient'
import { isNilOrEmpty } from '@/utils/isNilOrEmpty'
import { isValidEmail } from '@/utils/isValidEmail'
import isNil from 'lodash.isnil'
import omitBy from 'lodash.omitby'

const state = {
  all: [],
  allKeys: {},
}

const getters = {
  allActors: (state) => state.all,
  allActorsKeyed: (state) =>
    state.all.reduce((obj, item) => {
      obj[item.id] = item
      return obj
    }, {}),
}

const mutations = {
  SET_ACTORS(state, payload) {
    state.all = payload
  },
}

const actions = {
  async getActors({ commit, rootState }) {
    try {
      const input = {}
      const response = await apolloClient.getContacts({ input })
      const contacts = response.getContacts.contacts.map((contact) => {
        return omitBy(contact, isNil)
      })
      commit('SET_ACTORS', contacts)
      return contacts
    } catch (err) {
      console.error(err)
    }
  },
  async getActorByContactDetails({ rootState }, { email, phone }) {
    let actors
    try {
      if (email) {
        actors = await firestore
          .collection('actors')
          .where('email', '==', email)
          .where('companyId', '==', rootState.User.user.companyId)
          .get()
      }
      if ((!actors || actors.docs.length === 0) && phone) {
        actors = await firestore
          .collection('actors')
          .where('phone', '==', phone)
          .where('companyId', '==', rootState.User.user.companyId)
          .get()
      }
      if (actors.docs.length > 0) {
        return { ...actors.docs[0].data(), id: actors.docs[0].id }
      } else {
        return null
      }
    } catch (err) {
      console.log('ERROR', err)
      return null
    }
  },
  async getActorById(context, payload) {
    try {
      const input = {
        id: payload.actorId,
      }
      const response = await apolloClient.getContact({ input })
      return response.getContact.contact
    } catch (err) {
      console.error(err)
    }
  },
  async addActor({ dispatch, rootState }, payload) {
    if (!isNilOrEmpty(payload.email) && !isValidEmail(payload.email)) {
      throw new Error('Email is not valid')
    }

    const input = {
      address: payload.address,
      companyName: payload.companyName,
      country: payload.country,
      email: payload.email.trim() === '' ? undefined : payload.email.trim(),
      entityType: payload.entityType,
      name: payload.name,
      phone: payload.phone === '' ? undefined : payload.phone,
      registrationNumber: payload.registrationNumber,
    }

    try {
      await apolloClient.createContact({ input })
      dispatch('getActors')
    } catch (err) {
      console.error(err)
    }
  },
  async updateActor({ dispatch, rootState }, payload) {
    const input = {
      id: payload.id,
      address: payload.address,
      companyName: payload.companyName,
      country: payload.country,
      email: payload.email.trim() === '' ? undefined : payload.email.trim(),
      entityType: payload.entityType,
      name: payload.name,
      phone: payload.phone === '' ? undefined : payload.phone,
      registrationNumber: payload.registrationNumber,
    }

    try {
      await apolloClient.updateContact({ input })
      dispatch('getActors')
    } catch (err) {
      console.error(err)
    }
  },
  async deleteActor({ dispatch }, payload) {
    try {
      await apolloClient.deleteContact({ input: { id: payload } })
      dispatch('getActors')
    } catch (err) {
      console.error(err)
    }
  },
}

export default {
  namespaced: false,
  state,
  getters,
  mutations,
  actions,
}
