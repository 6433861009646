import { firebaseApp } from '@/firebase'
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import 'firebase/auth'
import { getSdkApollo } from './apollo-requester'

const httpLink = createHttpLink({
  uri: import.meta.env.VUE_APP_GRAPHQL_URL,
})

const authLink = setContext(async (_, { headers }) => {
  const idtoken = await firebaseApp.auth().currentUser.getIdToken(true)
  // console.log('idtoken', idtoken)

  return {
    headers: {
      ...headers,
      idtoken,
    },
  }
})

const authenticatedClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    mutate: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
  },
})

export const apolloClient = getSdkApollo(authenticatedClient)

export default authenticatedClient
