const defaultElements = {
  header: {
    key: 'header',
    label: '',
    value: 'Header',
    type: 'header',
    user_input: true,
  },
  paragraph: {
    key: 'paragraph',
    label: '',
    value: 'Paragraph text',
    type: 'paragraph',
    user_input: true,
  },
  string: {
    key: 'stringkey',
    value: '',
    label: 'Some label',
    type: 'string',
    user_input: true,
  },
  text: {
    key: 'text_input',
    label: 'Write below',
    value: '',
    type: 'text',
    user_input: true,
  },
  number: {
    key: 'numberkey',
    value: 0,
    label: 'Some number',
    type: 'number',
    user_input: true,
  },
  options: {
    key: 'optionkey',
    value: '',
    label: 'Select',
    type: 'options',
    user_input: true,
    options: [],
  },
  optionsMultiselect: {
    key: 'multiselectkey',
    value: [],
    label: 'Select one or more',
    type: 'optionsMultiselect',
    user_input: true,
    options: [],
  },
  submit: {
    key: 'submit',
    label: 'Submit',
    value: '',
    type: 'submit',
    user_input: true,
  },
  signature: {
    key: 'signature',
    label: 'Signee name',
    value: null,
    type: 'signature',
    user_input: true,
    meta: {
      timestamp: null,
    },
  },
  score: {
    key: 'score',
    label: 'Score',
    value: null,
    type: 'score',
    user_input: true,
    options: [],
  },
  date: {
    key: 'date',
    label: 'Date',
    value: null,
    type: 'date',
    user_input: true,
  },
}

export { defaultElements }
