const paramsToKeep = ['pagesubtab', 'pagetab', 'panel', 'subsection', 'subtab']

export function redactIDFromURL(url, params) {
  let path = url
  Object.entries(params).forEach(([key, value]) => {
    if (!paramsToKeep.includes(key)) {
      path = path.replaceAll(value, '_ID_')
    }
  })
  return path
}
