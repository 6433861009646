<template>
  <div class="base-input">
    <label v-if="label" :for="id" class="text-sm"
      >{{ label }}<span v-if="required"> *</span></label
    >
    <input
      :id="id"
      :value="value"
      :autofocus="autofocus"
      :placeholder="placeholder"
      :disabled="disable === true"
      :readonly="readonly"
      :type="type"
      :required="required"
      @input="inputEvent"
      @focus="$emit('focus')"
      @change="changeEvent"
      @blur="$emit('blur')"
    />
    <div v-if="info" class="base-input-info">{{ info }}</div>
  </div>
</template>

<script>
import { nanoid } from 'nanoid'

export default {
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    label: {
      type: [String, null],
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    disable: Boolean,
    type: {
      type: String,
      default: 'text',
      required: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
      required: false,
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
      required: false,
    },
    info: {
      type: [String, null],
      required: false,
      default: null,
    },
  },
  data() {
    return {
      id: nanoid(),
    }
  },
  methods: {
    inputEvent(e) {
      this.$emit('input', e.target.value)
    },
    changeEvent(e) {
      this.$emit('change', e.target.value)
    },
  },
}
</script>

<style lang="less" scoped>
@import '../styles/baseform.less';
</style>
