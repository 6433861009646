import { firestore } from '@/firebase'
import { apolloClient } from '@/gql/authenticatedClient'
import i18n from '@/i18n'
import { isCaptegoEmail } from '@/utils/isCaptegoEmail'
import { isNilOrEmpty } from '@/utils/isNilOrEmpty'
import { isValidEmail } from '@/utils/isValidEmail'
import 'firebase/auth'
import { trimStrings } from '../vuexHelpers'

const NO_ROLE = {
  id: '',
  title: 'none',
}

const state = {
  users: [],
  companyUsers: [],
  adminRoles: [
    {
      id: 'admin',
      title: 'admin',
    },
    {
      id: 'user',
      title: 'user',
    },
    {
      id: 'super_admin',
      title: 'super_admin',
    },
    NO_ROLE,
  ],
}

const getters = {
  allUsers: (state) => state.users,
  companyUsers: (state) => state.companyUsers,
  nonCaptegoCompanyUsers: (state) =>
    state.companyUsers.filter((user) => !isCaptegoEmail(user.email)),
  adminRoles: (state) => state.adminRoles,
  adminRole: (state) => (roleId) =>
    state.adminRoles.find((role) => role.id === roleId) || NO_ROLE,
}

const mutations = {
  SET_USERS(state, arr) {
    state.users = arr
  },
  SET_COMPANYUSERS(state, arr) {
    state.companyUsers = arr
  },
}

let usersUnsubscribe

const actions = {
  async deleteUser({ dispatch }, user) {
    dispatch('addToast', {
      message: i18n.t('user:toast.deletingUser'),
      type: 'pending',
    })
    try {
      console.log('deleting', user)
      await apolloClient.deleteUser({ input: { id: user.uid } })
      await dispatch('getCompanyUsers')
      dispatch('addToast', {
        message: i18n.t('user:toast.deletedUser'),
        type: 'good',
      })
      return
    } catch (err) {
      console.error(err)
    }
  },
  async addUser({ rootState, rootGetters }, payload) {
    if (!isNilOrEmpty(payload.email) && !isValidEmail(payload.email)) {
      throw new Error('Email is not valid')
    }

    payload.email = payload.email.toLowerCase()
    payload = trimStrings(payload)

    if (payload.title === '') {
      delete payload.title
    }

    const response = await apolloClient.checkIfUserExists({
      input: { email: payload.email },
    })
    if (response.checkIfUserExists.userExists) {
      return alert('User already exists')
    }

    await apolloClient.createUserWithEmail({ input: payload })
  },
  async saveUser({ dispatch, getters }, payload) {
    if (payload.newUser) {
      delete payload.newUser
      await dispatch('addUser', payload)
    } else {
      payload = trimStrings(payload)
      const userId = payload.uid

      const promises = []

      if (payload.adminRole !== 'super_admin') {
        promises.push(
          apolloClient.setUserAdminRole({
            input: { userId, adminRole: payload.adminRole },
          })
        )
      }

      if (
        getters.user.adminRole === 'super_admin' &&
        payload.companyId != null
      ) {
        promises.push(
          apolloClient.setUserCompanyId({
            input: { userId, companyId: payload.companyId },
          })
        )
      }

      if (payload.name != null && payload.name !== '') {
        promises.push(
          apolloClient.setUserName({
            input: { userId, name: payload.name },
          })
        )
      }

      if (payload.title != null) {
        promises.push(
          apolloClient.setUserTitle({
            input: { userId, title: payload.title },
          })
        )
      }
      promises.push(
        apolloClient.setUserClearCache({
          input: { userId, clearCache: payload.clearCache || false },
        })
      )
      promises.push(
        apolloClient.setUserRecoveryMode({
          input: { userId, recoveryMode: payload.recoveryMode || false },
        })
      )
      promises.push(
        apolloClient.setUserInvitationNotifications({
          input: {
            userId,
            invitationNotifications: payload.invitationNotifications || false,
          },
        })
      )

      await Promise.all(promises)
    }
    dispatch('getCompanyUsers')
  },
  getCompanyUsers({ rootState, commit }) {
    const companyId = rootState.User.user.companyId
    firestore
      .collection('users')
      .where('archived', '==', false)
      .where('companyId', '==', companyId)
      .get()
      .then((snap) => {
        let users = []
        snap.forEach((user) => {
          const adminRole = user.data().adminRole || NO_ROLE.id
          users.push({ ...user.data(), adminRole, uid: user.id })
        })

        users = users.sort(function (a, b) {
          const nameA = a.name != null ? a.name.toUpperCase() : ''
          const nameB = b.name != null ? b.name.toUpperCase() : ''
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
        commit('SET_COMPANYUSERS', users)
        return users
      })
  },
  subscribeToUsers({ commit }) {
    // PERMISSION FIX
    usersUnsubscribe = firestore
      .collection('users')
      .onSnapshot((querySnapshot) => {
        const all = querySnapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id }
        })
        commit('SET_USERS', all)
      })
  },
  unsubscribeToUsers({ commit }) {
    commit('SET_USERS', [])
    usersUnsubscribe()
  },
  async getUserById(context, payload) {
    const userDoc = await firestore
      .collection('users')
      .doc(payload.userId)
      .get()
    if (userDoc) return { ...userDoc.data(), id: userDoc.id }
    return null
  },
}

export default {
  namespaced: false,
  state,
  getters,
  mutations,
  actions,
}
