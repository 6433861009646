<template>
  <sidepanel
    :id="panelId"
    :title="
      editing
        ? $t('actors:editActor.panel.title')
        : $t('actors:addActor.panel.title')
    "
    :processing="saving"
    color-b-g="#7b35bd"
    color-f-g="#ffffff"
    @open="init"
  >
    <BaseInput
      v-model="actor.name"
      :label="$t('actors:addActor.formLabel.personName')"
      :required="true"
    />
    <BaseInput
      v-model="actor.country"
      :label="$t('actors:addActor.formLabel.country')"
    />
    <BaseInput
      v-model="actor.address"
      :label="$t('actors:addActor.formLabel.address')"
    />
    <BaseInput
      v-model="actor.email"
      :label="$t('actors:addActor.formLabel.email')"
      :required="true"
    />

    <div class="phone-wrapper">
      <label class="text-sm">{{ $t('actors:addActor.formLabel.phone') }}</label>
      <vue-phone-number-input
        v-model="phone"
        :translations="phoneInputTranslations"
        :preferred-countries="['DK', 'GB', 'DE', 'NO', 'SE', 'PL', 'US']"
        :default-country-code="countryCode"
        @update="updatePhone"
      />
    </div>

    <BaseInput
      v-model="actor.companyName"
      :label="$t('actors:addActor.formLabel.businessName')"
    />
    <BaseInput
      v-model="actor.registrationNumber"
      :label="$t('actors:addActor.formLabel.registrationNumber')"
    />
    <!--<base-boolean
      v-model="actor.invite"
      :label="$t('actors:addActor.formLabel.agreeToInvite')"
      :list="true"
    />-->
    <button
      v-if="!editing"
      class="button full green"
      :disabled="submitDisabled"
      type="button"
      @click="addActor"
    >
      {{ $t('actors:addActor.button.add') }}
    </button>
    <button
      v-if="editing"
      class="button full green"
      :disabled="submitDisabled"
      type="button"
      @click="updateActor"
    >
      {{ $t('actors:addActor.button.update') }}
    </button>
    <button
      v-if="editing"
      class="button full red outline"
      :disabled="submitDisabled"
      type="button"
      @click="deleteActor"
    >
      {{ $t('actors:addActor.button.delete') }}
    </button>

    <ConfirmDialog
      :active="confirmDialog.isRevealed"
      :title="$t('actors:confirmDeleteTitle', { name: actor.name })"
      @cancel="confirmDialog.cancel"
      @confirm="confirmDialog.confirm"
    >
      <p>{{ $t('actors:confirmDelete', { name: actor.name }) }}</p>
    </ConfirmDialog>
  </sidepanel>
</template>

<script>
import BaseInput from '@/components/BaseInput.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import { isNilOrEmpty } from '@/utils/isNilOrEmpty'
import { isValidEmail } from '@/utils/isValidEmail'
import { useConfirmDialog } from '@vueuse/core'
import i18next from 'i18next'
import parsePhoneNumber, { isPossiblePhoneNumber } from 'libphonenumber-js'

export default {
  components: {
    BaseInput,
    ConfirmDialog,
  },
  props: {
    panelId: {
      type: String,
      required: true,
    },
    actor: {
      type: Object,
      required: true,
    },
    editing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      confirmDialog: useConfirmDialog(),
      saving: false,
      phone: '',
      formattedPhone: '',
      phoneInputTranslations: {
        countrySelectorLabel: this.$t('common:phoneInput.countryLabel'),
        countrySelectorError: this.$t('common:phoneInput.countrySelectError'),
        phoneNumberLabel: this.$t('common:phoneInput.phoneNumber'),
        example: this.$t('common:phoneInput.example'),
      },
    }
  },
  computed: {
    countryCode() {
      const parsedPhoneNumber = parsePhoneNumber(this.actor.phone || '')
      const fallbackCountryCode = i18next.language.substring(3)
      return parsedPhoneNumber != null
        ? parsedPhoneNumber.country
        : fallbackCountryCode
    },
    submitDisabled() {
      if (!isNilOrEmpty(this.actor.email) && !isValidEmail(this.actor.email)) {
        return true
      }
      if (
        !isNilOrEmpty(this.phone) &&
        (this.phone.length < 3 || !isPossiblePhoneNumber(this.formattedPhone))
      ) {
        return true
      }
      if (isNilOrEmpty(this.actor.name)) {
        return true
      }
      if (isNilOrEmpty(this.actor.email)) {
        return true
      }
      return false
    },
  },
  methods: {
    init() {
      this.phone = this.actor.phone || ''
      this.formattedPhone = this.actor.phone || ''
    },
    async addActor() {
      this.saving = true
      this.trackEvent({
        category: 'Actors',
        action: 'Create actor',
      })
      try {
        const newActor = await this.$store.dispatch('addActor', {
          ...this.actor,
          phone: this.formattedPhone,
        })
        this.$emit('addedActor', newActor)
        this.removePanel()
        this.saving = false
      } catch (err) {
        console.error(err)
        alert(err)
      } finally {
        this.saving = false
        this.removePanel()
      }
    },
    async updateActor() {
      this.saving = true
      this.trackEvent({
        category: 'Actors',
        action: 'Update actor',
      })

      try {
        await this.$store.dispatch('updateActor', {
          ...this.actor,
          phone: this.formattedPhone,
        })
        this.$emit('updatedActor')
      } catch (err) {
        console.error(err)
        alert(err)
      } finally {
        this.saving = false
        this.removePanel()
      }
    },
    async deleteActor() {
      const { isCanceled } = await this.confirmDialog.reveal()
      if (!isCanceled) {
        this.saving = true
        this.trackEvent({
          category: 'Actors',
          action: 'Delete actor',
        })

        try {
          await this.$store.dispatch('deleteActor', this.actor.id)
          this.$emit('deletedActor')
        } catch (err) {
          console.error(err)
          alert(err)
        } finally {
          this.saving = false
          this.removePanel()
        }
      }
      return false
    },
    updatePhone(e) {
      this.formattedPhone = e.formattedNumber
    },
  },
}
</script>

<style lang="less" scoped>
.phone-wrapper {
  margin-bottom: var(--space-x2);
}
</style>
