import { apolloClient } from '@/gql/authenticatedClient'
import i18n from '@/i18n'

export const changeInspectionProject = async (
  { dispatch },
  { inspectionId, targetProjectId, currentProjectId }
) => {
  if (currentProjectId === targetProjectId) return
  try {
    const response = await apolloClient.moveInspectionToProject({
      input: { inspectionId, targetProjectId },
    })
    if (
      response.moveInspectionToProject &&
      response.moveInspectionToProject.success
    ) {
      dispatch('addToast', {
        message: i18n.t('inspections:toast.moveToNewProjectSuccess'),
        type: 'good',
      })
    } else {
      dispatch('addToast', {
        message: i18n.t('inspections:toast.moveToNewProjectFailed'),
        type: 'bad',
      })
    }
    console.log(response)
  } catch (err) {
    return dispatch('addToast', {
      message: i18n.t('inspections:toast.moveToNewProjectFailed') + ': ' + err,
      type: 'bad',
    })
  }
}
