<template>
  <div class="actor-item" :class="{ active: active }" @click="$emit('click')">
    <div class="actor-name">{{ actor.name }}</div>
    <div v-if="actor.companyName" class="actor-company">
      {{ actor.companyName }}
    </div>
    <div class="actor-contact">
      <span v-if="actor.phoneNumber && actor.phoneNumber !== ''">{{
        actor.phoneNumber
      }}</span>
      <span v-if="actor.email && actor.email !== ''">{{ actor.email }}</span>
    </div>
    <CloseCircleIcon v-if="icon === 'close-circle'" />
    <PlusIcon v-else-if="icon === 'plus'" />
  </div>
</template>

<script>
import CloseCircleIcon from '@/icons/close-circle.svg'
import PlusIcon from '@/icons/plus.svg'

export default {
  components: { CloseCircleIcon, PlusIcon },
  props: {
    actor: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
    },
    icon: {
      type: String,
      required: false,
    },
  },
}
</script>

<style lang="less" scoped>
.actor-item {
  padding: var(--space-half) var(--space-x1);
  background-color: var(--color-grey-lighter);
  margin-bottom: 1px;
  border-radius: var(--radius-sm);
  cursor: pointer;
  position: relative;
  &.active {
    background-color: var(--color-actor);
    color: var(--color-white);
  }
  .actor-name {
    font-weight: var(--font-weight-bd);
  }
  .actor-company {
    font-size: var(--text-xs);
  }
  .actor-contact {
    font-size: var(--text-xs);
    span {
      display: block;
    }
  }
  svg {
    position: absolute;
    right: var(--space-x1);
    top: var(--space-x1);
  }
}
</style>
