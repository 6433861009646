import { firestore, functions } from '@/firebase'
import { apolloClient } from '@/gql/authenticatedClient'
import i18n from '@/i18n'
import { getStructureDefault } from '@/store/templates/structureDefault'
import getAdminHost from '@/utils/getAdminHost'
import { nanoid } from 'nanoid'
import {
  buildQuery,
  computeStatus,
  getContentNumber,
  getReportCoverPagePhoto,
} from '../vuexHelpers'

const state = {
  all: [],
  currentReport: null,
  groupOptions: [
    'template_tags',
    'tag1',
    'tag2',
    'status',
    'inspector',
    'timestamp_day',
    'registrationDrawing_title',
    'tag1_atat_number',
  ],
  defaultGroupOption: 0,
}

const getters = {
  getCurrentReport: (state) => state.currentReport,
  getReportById: (state) => (id) => state.all.filter((item) => item.id === id),
  getReportGroupOptions: (state) => state.groupOptions,
  getReportGroupOptionDefault: (state) =>
    state.groupOptions[state.defaultGroupOption],
}

const mutations = {
  SET_CURRENT_REPORT(state, doc) {
    state.currentReport = doc
  },
  RESET_CURRENT_REPORT(state) {
    state.currentReport = null
  },
}

let reportUnsubscription

const actions = {
  async createReportPDF({ dispatch }, report) {
    console.log('Creating report PDF', { report })
    dispatch('addToast', {
      message: i18n.t('inspections:createPDF.generatingPDFfile'),
      type: 'pending',
    })
    try {
      const site = getAdminHost(
        typeof window !== 'undefined'
          ? window.location.origin
          : import.meta.env.VUE_APP_HOST
      )

      await apolloClient.generateQuickReportPdf({
        input: {
          locale: i18n.i18next.language,
          inspectionId: report.inspectionId,
          reportId: report.id,
          site,
        },
      })

      dispatch('addToast', {
        message: i18n.t('inspections:createPDF.generatingPDFfileCompleted'),
        type: 'pending',
      })
    } catch (err) {
      console.log('Failed to create report PDF', err)
      dispatch('addToast', {
        message: `${i18n.t('inspections:createPDF.toast.failed')}: ${err}`,
        type: 'bad',
      })
    }
  },
  async createReport({ dispatch, rootState }, payload) {
    try {
      const inspectionReport = await dispatch('fetchInspectionReport', {
        inspectionId: payload.inspectionId,
      })
      let captures = payload.captures

      if (payload.template === 'empty') {
        // USUALLY when creating a sub report, users can select an empty template
        const coverPhotoOriginal = inspectionReport.structure.coverPage.photo
        inspectionReport.structure = getStructureDefault()
        inspectionReport.structure.coverPage.photo = coverPhotoOriginal
      }

      if (payload.reportName) inspectionReport.reportName = payload.reportName
      if (!captures) {
        // We need to get the inspection companyId, as we cannot trust the inspectionReport companyId (when shared)
        const inspection = await firestore
          .collection('inspections')
          .doc(payload.inspectionId)
          .get()

        // Figure out companyId of company that created inspection
        if (!inspection || !inspection.data()) throw 'Inspection not found'
        const inspectionCompanyId = inspection.data().companyId

        // If user is admin and inspection is created by the company, scope is company
        const isAdmin =
          rootState.User.user.adminRole === 'admin' ||
          rootState.User.user.adminRole === 'super_admin'
        const scope =
          rootState.User.company.id === inspectionCompanyId && isAdmin
            ? 'company'
            : 'shared'

        let query = firestore
          .collection('captures')
          .where('inspectionId', '==', inspection.id)
          .where('archived', '==', false)

        /*
      if (pending !== undefined) {
        query = query.where('pending', '==', pending)
      }
      */

        query = buildQuery(query, {
          scope,
          rootState,
        })

        const captureSnapshot = await query.get()
        captures = captureSnapshot.docs
          .map((captureSnapshot) => ({
            ...captureSnapshot.data(),
            id: captureSnapshot.id,
          }))
          .filter((capture) => !capture.disabled && !capture.archived)
      }

      const report = { ...inspectionReport, captures, id: nanoid() }

      // The timeout for the "createOrUpdateReport" callable is 540 seconds.
      const timeoutSeconds = 540
      const createReport = functions.httpsCallable('createOrUpdateReport', {
        timeout: timeoutSeconds * 1000,
      })
      return await createReport(report)
    } catch (err) {
      console.log('Failed to create report', err)
    }
  },
  async fetchInspectionReport({ getters, dispatch, rootState }, payload) {
    let inspection = payload.inspection
    if (!inspection) {
      inspection = await dispatch('fetchInspection', {
        id: payload.inspectionId,
      })
    }
    const inspectionProject = await firestore
      .collection('projects')
      .doc(inspection.projectId)
      .get()
    const project = { ...inspectionProject.data(), id: inspectionProject.id }
    inspection.projectTitle = project.title
    inspection.projectImageUrl = project.imageUrl

    inspection = getReportCoverPagePhoto(inspection)

    const zoom =
      inspection.layoutView && inspection.layoutView.zoom
        ? inspection.layoutView.zoom
        : null

    const captures = []
    const report = {
      id: inspection.id,
      inspectionId: inspection.id,
      reportName: inspection.title,
      reportProject: inspection.projectTitle,
      reportDate: Date.now(),
      company: rootState.User.company,
      companyId: rootState.User.company.id,
      dataContent: inspection.dataContent,
      labelGroups: inspection.labelGroups,
      footer: {
        content: rootState.User.company.report.footer,
        pageNumber: true,
      },
      structure: inspection.structure,
      localisation: inspection.localisation || {},
      timestamp: Date.now(),
      captures,
      latestPDF: '',
      pdfs: [],
      groupBy: inspection.groupBy || getters.getReportGroupOptionDefault,
      registrationDrawings: inspection.images,
      tags: inspection.tags,
      zoom,
    }
    return report
  },
  async sendReportFileLink({ dispatch }, payload) {
    dispatch('addToast', {
      message: i18n.t('reports:sharePDF.sharing'),
      type: 'pending',
    })

    try {
      await apolloClient.shareQuickReportPdf({
        input: {
          generatedTime: payload.generatedTime,
          recipients: payload.recipients,
          reportId: payload.reportId,
        },
      })

      dispatch('addToast', {
        message: i18n.t('reports:sharePDF.sharingComplete'),
        type: 'good',
      })
    } catch (error) {}
  },
  async getInspectionReports({ rootState }, payload) {
    const query = firestore
      .collection('reports')
      .where('inspectionId', '==', payload.inspectionId)

    /*
    query = buildQuery(query, {
      rootState,
    })
    */

    const querySnapshot = await query.get()
    return querySnapshot.docs
      .map((doc) => ({ ...doc.data() }))
      .filter((doc) => !doc.archived)
      .sort((a, b) => {
        if (a.timestamp > b.timestamp) return -1
        if (a.timestamp <= b.timestamp) return 1
      })
  },
  async regenerateReport({ rootState, dispatch }, payload) {
    dispatch('addToast', {
      message: i18n.t('reports:regenerateReport.toast.started'),
      type: 'pending',
    })
    try {
      const report = await firestore.collection('reports').doc(payload.id).get()
      const reportData = { ...report.data(), id: report.id }
      const inspection = await firestore
        .collection('inspections')
        .doc(reportData.inspectionId)
        .get()
      const inspectionData = { ...inspection.data(), id: inspection.id }
      let captures = firestore
        .collection('captures')
        .where('inspectionId', '==', reportData.inspectionId)
        .where('archived', '==', false)

      const isAdmin =
        rootState.User.user.adminRole === 'admin' ||
        rootState.User.user.adminRole === 'super_admin'
      const inspectionCompanyId = inspection.data().companyId
      const scope =
        rootState.User.company.id === inspectionCompanyId && isAdmin
          ? 'company'
          : 'shared'
      captures = buildQuery(captures, {
        scope,
        rootState,
      })
      const capturesDocs = await captures.get()
      const capturesData = capturesDocs.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
      // console.log(inspectionData, capturesData)
      await dispatch('createReport', {
        reportId: payload.id,
        inspectionId: reportData.inspectionId,
        inspection: inspectionData,
        captures: capturesData,
        reportName: reportData.reportName,
        groupBy: report.groupBy || inspectionData.groupBy,
      })
      dispatch('addToast', {
        message: i18n.t('reports:regenerateReport.toast.success'),
        type: 'good',
      })
    } catch (err) {
      dispatch('addToast', {
        message: err,
        type: 'bad',
      })
    }
  },
  async getReport({ commit, rootState }, payload) {
    try {
      const reportRef = firestore.collection('reports').doc(payload.id)
      const reportSnap = await reportRef.get()

      const report = { ...reportSnap.data(), id: reportSnap.id }

      if (!report.captures) {
        // IF SHARED WITH ME
        const captureQueryPermissions = await reportRef
          .collection('captures')
          .where('permissionIds', 'array-contains', rootState.User.user.uid)
          .get()
        const capturesByPermission = captureQueryPermissions.docs.map((doc) => {
          const docData = doc.data()
          return {
            ...docData,
            id: doc.id,
            status: computeStatus(docData),
            ...getContentNumber(docData),
          }
        })

        // IF ADMIN
        let capturesByCompany = []
        if (['admin', 'super_admin'].includes(rootState.User.user.adminRole)) {
          const captureQueryCompany = await reportRef
            .collection('captures')
            .where('companyId', '==', rootState.User.company.id)
            .get()
          capturesByCompany = captureQueryCompany.docs.map((doc) => {
            const docData = doc.data()
            return {
              ...docData,
              id: doc.id,
              status: computeStatus(docData),
              ...getContentNumber(docData),
            }
          })
        }

        // Remove duplicates
        report.captures = [
          ...capturesByPermission,
          ...capturesByCompany,
        ].reduce((acc, current) => {
          const x = acc.find((item) => item.id === current.id)
          if (!x) {
            return acc.concat([current])
          } else {
            return acc
          }
        }, [])
      }

      commit('SET_CURRENT_REPORT', report)
      return report
    } catch (err) {
      console.error(err)
    }
  },
  async deleteReport(context, payload) {
    await apolloClient.deleteReport({ input: { id: payload.id } })
  },
  resetCurrentReport({ commit }) {
    commit('RESET_CURRENT_REPORT')
  },
  updateReport(context, payload) {
    firestore
      .collection('reports')
      .doc(payload.id)
      .update({
        [payload.field]: payload.value,
      })
  },
}

export default {
  namespaced: false,
  state,
  getters,
  mutations,
  actions,
}
