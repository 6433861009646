const hosts = {
  'dev-eu': 'https://setup-dev.captego.com',
  'prod-eu': 'https://setup.captego.com',

  'feature-dev-eu': 'https://setup-feature-dev.captego.com',
  'feature-prod-eu': 'https://setup-feature-prod.captego.com',

  'localhost': 'https://setup.dev.captego.com:8080',
}

export default function getAdminHost(url) {
  const host = Object.entries(hosts).find(([_key, value]) => value === url)
  return host == null ? null : host[0]
}
