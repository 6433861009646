<template>
  <button class="icon" type="button" @click="$emit('click')">
    <ArrowLeftIcon v-if="icon === 'arrow-left'" />
    <CloseIcon v-else-if="icon === 'close'" />
    <PlusIcon v-else-if="icon === 'plus'" />
  </button>
</template>

<script>
import ArrowLeftIcon from '@/icons/arrow-left.svg'
import CloseIcon from '@/icons/close.svg'
import PlusIcon from '@/icons/plus.svg'

export default {
  components: { ArrowLeftIcon, CloseIcon, PlusIcon },
  props: ['icon'],
}
</script>

<style lang="less" scoped>
button.icon {
  width: var(--space-x4);
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}
</style>
