<template>
  <div>
    <sidepanel
      id="invite-users-external"
      :title="$t('permissions:inviteExternal.panel.title')"
      :processing="processing"
      color-b-g="#7b35bd"
      color-f-g="#ffffff"
      @open="restart"
    >
      <template v-if="!processing">
        <template v-if="uninvitedActors.length > 0">
          <div class="panel-selection">
            <h2 class="text-header">
              {{ $t('permissions:invite.header.selectActors') }}
            </h2>
            <div class="panel-selected-options">
              <ActorItem
                v-for="actor in selectedActors"
                :key="'added-' + actor.id"
                :actor="actor"
                icon="close-circle"
                :active="true"
                @click="toggleActor(actor)"
              />
            </div>
            <BaseSearch v-model="query" @focus="showOptions = true" />

            <button class="link small actor" type="button" @click="addActor">
              {{ $t('actors:addActor.button.add') }}
            </button>

            <div v-if="query.length > 0" class="panel-selection-options">
              <ActorItem
                v-for="actor in searchResult"
                :key="actor.id"
                class="actor-button"
                :actor="actor"
                :class="{
                  active: selectedActors.map((a) => a.id).includes(actor.id),
                }"
                icon="plus"
                @click="toggleActor(actor)"
              ></ActorItem>
            </div>
            <!--<button @click="addPanel('actor-panel')" class="actor outline">Add actor</button>-->
          </div>
          <div class="panel-selection">
            <h2 class="text-header">
              {{ $t('permissions:invite.header.selectRole') }}
            </h2>
            <BaseRadios v-model="role" :options="roles" />
          </div>
          <div
            v-if="role === 'contributor' && actorTypeOptions.length > 0"
            class="panel-selection"
          >
            <h2 class="text-header">Select actor type(s)</h2>
            <BaseSelectList v-model="actorTypes" :options="actorTypeOptions" />
          </div>
          <div class="panel-actions">
            <button
              class="button black"
              :disabled="role === null || selectedActors.length === 0"
              type="button"
              @click="submitInvite"
            >
              {{
                $t('permissions:button.invite', {
                  count: selectedActors.length,
                })
              }}
            </button>
            <button
              class="secondary-button"
              type="button"
              @click="removePanel()"
            >
              {{ $t('common:cancel') }}
            </button>
          </div>
        </template>
        <template v-else>
          <div v-if="itemType" class="note text-sm">
            {{ $t('permissions:invite.allUsersInvitedTo' + itemType) }}
          </div>
        </template>
      </template>
    </sidepanel>
    <ActorPanel
      panel-id="actor-panel"
      :actor="editActorData"
      @addedActor="addedActor"
    />
  </div>
</template>

<script>
import BaseRadios from '@/components/BaseRadios.vue'
import BaseSearch from '@/components/BaseSearch.vue'
import BaseSelectList from '@/components/BaseSelectList.vue'
import { apolloClient } from '@/gql/authenticatedClient'
import ActorPanel from '@/pages/Company/Actors/actor-panel.vue'
import { isNilOrEmpty } from '@/utils/isNilOrEmpty'
import ActorItem from '../ActorItem.vue'

const EMPTY_ACTOR = {
  entityType: 'Business',
  name: '',
  email: '',
  country: '',
  address: '',
  phone: '',
  companyName: '',
  registrationNumber: '',
}

export default {
  components: {
    BaseSearch,
    BaseRadios,
    ActorItem,
    ActorPanel,
    BaseSelectList,
  },
  props: {
    permissions: {
      type: Object,
    },
    itemId: {
      type: String,
    },
    itemType: {
      type: String,
    },
    itemActorTypes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showOptions: false,
      roles: [
        {
          value: 'owner',
          label: this.$t('permissions:roles.owner'),
          info: this.$t('permissions:roles.details.owner.' + this.itemType),
        },
        {
          value: 'contributor',
          label: this.$t('permissions:roles.contributor'),
          info: this.$t(
            'permissions:roles.details.contributor.' + this.itemType
          ),
        },
      ],
      role: null,
      selectedActors: [],
      actorTypes: [],
      processing: false,
      query: '',
      editActorData: EMPTY_ACTOR,
    }
  },
  computed: {
    actorTypeOptions() {
      return this.itemActorTypes.map((actorType) => ({
        value: actorType.id,
        label: actorType.title,
        disabled: false,
      }))
    },
    searchResult() {
      const query = this.query.toLowerCase().trim()

      if (query === '') {
        return []
      }

      // Filter out actors that are already selected
      const unselectedActors = this.uninvitedActors.filter(
        (actor) =>
          this.selectedActors.find(
            (selectedActor) => selectedActor.id === actor.id
          ) == null
      )

      return unselectedActors.filter((actor) => {
        const companyMatch =
          !isNilOrEmpty(actor.companyName) &&
          actor.companyName.toLowerCase().includes(query)
        const emailMatch =
          !isNilOrEmpty(actor.email) &&
          actor.email.toLowerCase().includes(query)
        const nameMatch =
          !isNilOrEmpty(actor.name) && actor.name.toLowerCase().includes(query)
        const phoneMatch =
          !isNilOrEmpty(actor.phone) &&
          actor.phone.toLowerCase().includes(query)

        return companyMatch || emailMatch || nameMatch || phoneMatch
      })
    },

    uninvitedActors() {
      // Filter out actors without contact details we need
      return this.actors.filter((actor) => {
        if (isNilOrEmpty(actor.email) && isNilOrEmpty(actor.phone)) return false

        // Filter out already invited actors
        if (
          this.itemInvitations.filter(
            (invitation) =>
              invitation.recipient &&
              invitation.recipient.payload &&
              invitation.recipient.payload.actor &&
              invitation.recipient.payload.actor.id === actor.id
          ).length > 0
        )
          return false

        // FIX we also need to check through permissions to see if accepted invitations to actors should remove some possible actors from the list

        return true
      })
    },

    actors() {
      return this.$store.getters.allActors
    },
    itemInvitations() {
      return this.$store.getters.itemInvitations
    },
    itemPermissions() {
      return this.$store.getters.itemPermissions
    },
  },
  mounted() {
    // this.$store.dispatch("getActors");
  },
  methods: {
    addActor() {
      this.editActorData = {
        ...EMPTY_ACTOR,
      }
      this.addPanel('actor-panel')
    },
    async addedActor(newActor) {
      await this.$store.dispatch('getActors')
      const selectedActor = this.actors.filter(
        (actor) => actor.id === newActor.id
      )
      if (selectedActor.length > 0) {
        console.log('SELECTED ACTOR', selectedActor[0])
        this.selectedActors.push(selectedActor[0])
      }
    },
    toggleActor(actor) {
      if (!this.selectedActors.map((a) => a.id).includes(actor.id)) {
        this.selectedActors.push(actor)
      } else {
        this.selectedActors = this.selectedActors.filter(
          (a) => a.id !== actor.id
        )
      }
      this.query = ''
      this.showOptions = false
    },
    async submitInvite() {
      this.processing = true

      const recipients = this.selectedActors.map((actor) => {
        const recipient = {
          recipientGroup: 'EXTERNAL',
          name: actor.name,
        }

        if (actor.email != null && actor.email !== '') {
          recipient.email = actor.email
        }

        if (actor.phone != null && actor.phone !== '') {
          recipient.phoneNumber = actor.phone
        }
        return recipient
      })

      let itemType = this.itemType.toUpperCase()

      if (itemType.endsWith('S')) {
        itemType = itemType.substr(0, itemType.length - 1)
      }

      const input = {
        recipients,
        type: itemType,
        itemId: this.itemId,
        role: this.role.toUpperCase(),
      }

      this.trackEvent({
        category: 'Workflow',
        action: 'Create invitation',
        type: this.itemType,
        users: 1,
        actorTypes: this.actorTypes,
        role: this.role,
        relation: 'External',
      })

      try {
        const response = await apolloClient.createInvitations({ input })
        this.processing = false
        this.removePanel()
        console.log('Created external invitations', response)
      } catch (error) {
        this.processing = false
        this.$store.dispatch('addToast', {
          message: this.$t('inspections:createInvitationsFailed'),
          type: 'bad',
        })
        console.log(error)
      }
    },
    async restart() {
      this.processing = true
      this.selectedActors = []
      // this.role = this.itemType === 'inspections' ? 'contributor' : 'owner'
      await Promise.all([
        this.$store.dispatch('getItemInvitations', {
          itemId: this.itemId,
          itemType: this.itemType,
        }),
        this.$store.dispatch('getActors'),
      ])
      this.processing = false
    },
  },
}
</script>

<style lang="less" scoped>
.panel-selection {
  button {
    width: 100%;
    text-align: left;

    &.active {
      background-color: var(--color-brand);
    }
  }

  .panel-selection-options {
    button {
      border-radius: 0;
      margin-bottom: 1px;
      height: auto;
      padding: var(--space-x1);

      &:first-child {
        border-radius: var(--radius-sm) var(--radius-sm) 0 0;
      }

      &:last-child {
        border-radius: 0 0 var(--radius-sm) var(--radius-sm);

        &:first-child {
          border-radius: var(--radius-sm);
        }
      }
    }
  }
}

.panel-selected-options {
  margin-bottom: var(--space-x1);

  button {
    background-color: var(--color-brand);
    color: var(--color-white);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: var(--font-weight-bd);
    margin-bottom: 1px;
  }
}

.note {
  background-color: var(--color-yellow);
  padding: var(--space-x2);
  margin: calc(-1 * var(--space-x1));
}

.actor-button {
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  min-height: var(--space-x4);
  padding: var(--space-half) var(--space-x1);

  & > span {
    &:last-child {
      font-size: var(--text-xs);
      color: var(--color-black-fade50);

      span + span {
        margin-left: var(--space-x1);
      }
    }
  }
}
</style>
