export function getCookieHost() {
  try {
    return window.location.hostname.endsWith('.captego.com')
      ? '.captego.com'
      : '.web.app'
    // eslint-disable-next-line no-empty
  } catch {}
  return '.captego.com'
}

/**
 * @see https://stackoverflow.com/a/16061433
 */
export function getCookie(name) {
  try {
    const match = (document.cookie + ';').match(new RegExp(`${name}=.*;`))
    if (match) {
      return match[0].split(/=|;/)[1]
    }
    return ''
    // eslint-disable-next-line no-empty
  } catch {}
}

export function setCookie(name, value, maxAge = 60 * 60 * 24 * 365 * 2) {
  if (name == null) return

  try {
    document.cookie = `${name}=${encodeURIComponent(
      value
    )};domain=${getCookieHost()};max-age=${maxAge};samesite=strict;secure`
    // eslint-disable-next-line no-empty
  } catch {}
}

function getTokenCookieName() {
  return `__token_${import.meta.env.VUE_APP_PROJECT_ID}`
}
function getLogoutCookieName() {
  return `__logout_${import.meta.env.VUE_APP_PROJECT_ID}`
}

export function deleteTokenCookie() {
  setCookie(getTokenCookieName(), '', 0)
}
export function deleteLogoutCookie() {
  setCookie(getLogoutCookieName(), '', 0)
}

export function setTokenCookie(customToken) {
  setCookie(getTokenCookieName(), customToken)
  deleteLogoutCookie()
}
export function setLogoutCookie() {
  deleteTokenCookie()
  setCookie(getLogoutCookieName(), 'manage')
}

export function getTokenCookie() {
  return getCookie(getTokenCookieName())
}
export function getLogoutCookie() {
  return getCookie(getLogoutCookieName())
}
