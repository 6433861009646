import { gql } from '@apollo/client';
export var AliasDataType;
(function (AliasDataType) {
    AliasDataType["Array"] = "ARRAY";
    AliasDataType["Date"] = "DATE";
    AliasDataType["Image"] = "IMAGE";
    AliasDataType["ImageUrl"] = "IMAGE_URL";
    AliasDataType["Number"] = "NUMBER";
    AliasDataType["Object"] = "OBJECT";
    AliasDataType["String"] = "STRING";
    AliasDataType["Undefined"] = "UNDEFINED";
})(AliasDataType || (AliasDataType = {}));
export var AnnotationType;
(function (AnnotationType) {
    AnnotationType["Arrow"] = "ARROW";
})(AnnotationType || (AnnotationType = {}));
export var AssigneeGroup;
(function (AssigneeGroup) {
    AssigneeGroup["External"] = "EXTERNAL";
    AssigneeGroup["Internal"] = "INTERNAL";
})(AssigneeGroup || (AssigneeGroup = {}));
export var AssigneeType;
(function (AssigneeType) {
    AssigneeType["Contact"] = "CONTACT";
    AssigneeType["User"] = "USER";
})(AssigneeType || (AssigneeType = {}));
export var AssignmentFilterField;
(function (AssignmentFilterField) {
    /** String representing inspection ID */
    AssignmentFilterField["InspectionId"] = "INSPECTION_ID";
    /** Timestamp (Number of milliseconds since the ECMAScript epoch) representing when a registration was last updated */
    AssignmentFilterField["UpdatedAt"] = "UPDATED_AT";
})(AssignmentFilterField || (AssignmentFilterField = {}));
export var AssignmentOrderByField;
(function (AssignmentOrderByField) {
    AssignmentOrderByField["UpdatedAt"] = "UPDATED_AT";
})(AssignmentOrderByField || (AssignmentOrderByField = {}));
export var AssignmentPermissionRole;
(function (AssignmentPermissionRole) {
    AssignmentPermissionRole["Assignee"] = "ASSIGNEE";
    AssignmentPermissionRole["Owner"] = "OWNER";
})(AssignmentPermissionRole || (AssignmentPermissionRole = {}));
export var AssignmentStatus;
(function (AssignmentStatus) {
    AssignmentStatus["Accepted"] = "ACCEPTED";
    AssignmentStatus["Pending"] = "PENDING";
    AssignmentStatus["Rejected"] = "REJECTED";
})(AssignmentStatus || (AssignmentStatus = {}));
export var AssignmentTaskStatus;
(function (AssignmentTaskStatus) {
    AssignmentTaskStatus["Complete"] = "COMPLETE";
    AssignmentTaskStatus["Incomplete"] = "INCOMPLETE";
})(AssignmentTaskStatus || (AssignmentTaskStatus = {}));
export var BillingInterval;
(function (BillingInterval) {
    BillingInterval["Monthly"] = "MONTHLY";
    BillingInterval["Yearly"] = "YEARLY";
})(BillingInterval || (BillingInterval = {}));
export var CaptureFilterField;
(function (CaptureFilterField) {
    CaptureFilterField["CompanyId"] = "COMPANY_ID";
    CaptureFilterField["InspectionId"] = "INSPECTION_ID";
    CaptureFilterField["ProjectId"] = "PROJECT_ID";
    CaptureFilterField["Status"] = "STATUS";
    CaptureFilterField["Timestamp"] = "TIMESTAMP";
    CaptureFilterField["UserId"] = "USER_ID";
})(CaptureFilterField || (CaptureFilterField = {}));
export var CaptureImageSourceType;
(function (CaptureImageSourceType) {
    CaptureImageSourceType["AdminUpload"] = "ADMIN_UPLOAD";
    CaptureImageSourceType["ApiUpload"] = "API_UPLOAD";
    CaptureImageSourceType["AppCamera"] = "APP_CAMERA";
    CaptureImageSourceType["AppStorage"] = "APP_STORAGE";
    CaptureImageSourceType["RemoteWebAppCamera"] = "REMOTE_WEB_APP_CAMERA";
})(CaptureImageSourceType || (CaptureImageSourceType = {}));
export var CaptureOrderByField;
(function (CaptureOrderByField) {
    CaptureOrderByField["Status"] = "STATUS";
    CaptureOrderByField["Timestamp"] = "TIMESTAMP";
})(CaptureOrderByField || (CaptureOrderByField = {}));
export var CaptureType;
(function (CaptureType) {
    CaptureType["Capture"] = "capture";
    CaptureType["Nonecapture"] = "nonecapture";
    CaptureType["Recapture"] = "recapture";
})(CaptureType || (CaptureType = {}));
export var CompanyFilterField;
(function (CompanyFilterField) {
    CompanyFilterField["Created"] = "CREATED";
})(CompanyFilterField || (CompanyFilterField = {}));
export var CompanyIndustry;
(function (CompanyIndustry) {
    CompanyIndustry["Automotive"] = "AUTOMOTIVE";
    CompanyIndustry["Insurance"] = "INSURANCE";
    CompanyIndustry["Logistics"] = "LOGISTICS";
    CompanyIndustry["Manufacturing"] = "MANUFACTURING";
    CompanyIndustry["Other"] = "OTHER";
    CompanyIndustry["PropertyConstruction"] = "PROPERTY_CONSTRUCTION";
    CompanyIndustry["PropertyOwnershipRental"] = "PROPERTY_OWNERSHIP_RENTAL";
    CompanyIndustry["PublicSector"] = "PUBLIC_SECTOR";
    CompanyIndustry["Retail"] = "RETAIL";
    CompanyIndustry["SpecialEngineeringServices"] = "SPECIAL_ENGINEERING_SERVICES";
    CompanyIndustry["SustainableEnergy"] = "SUSTAINABLE_ENERGY";
})(CompanyIndustry || (CompanyIndustry = {}));
export var CompanyOrderByField;
(function (CompanyOrderByField) {
    CompanyOrderByField["Created"] = "CREATED";
    CompanyOrderByField["Name"] = "NAME";
})(CompanyOrderByField || (CompanyOrderByField = {}));
export var CompanyVariableType;
(function (CompanyVariableType) {
    CompanyVariableType["Number"] = "NUMBER";
    CompanyVariableType["String"] = "STRING";
})(CompanyVariableType || (CompanyVariableType = {}));
export var CompanyWebhookService;
(function (CompanyWebhookService) {
    CompanyWebhookService["Direct"] = "DIRECT";
    CompanyWebhookService["Make"] = "MAKE";
    CompanyWebhookService["Zapier"] = "ZAPIER";
})(CompanyWebhookService || (CompanyWebhookService = {}));
export var CompanyWebhookType;
(function (CompanyWebhookType) {
    CompanyWebhookType["CaptureUpdated"] = "CAPTURE_UPDATED";
    CompanyWebhookType["InspectionDataFormValuesUpdated"] = "INSPECTION_DATA_FORM_VALUES_UPDATED";
    CompanyWebhookType["InspectionHideInAppChanged"] = "INSPECTION_HIDE_IN_APP_CHANGED";
    CompanyWebhookType["PdfCreated"] = "PDF_CREATED";
})(CompanyWebhookType || (CompanyWebhookType = {}));
export var ContactFilterField;
(function (ContactFilterField) {
    ContactFilterField["Id"] = "ID";
})(ContactFilterField || (ContactFilterField = {}));
export var ContactOrderByField;
(function (ContactOrderByField) {
    ContactOrderByField["Name"] = "NAME";
})(ContactOrderByField || (ContactOrderByField = {}));
export var Currency;
(function (Currency) {
    Currency["Dkk"] = "DKK";
    Currency["Eur"] = "EUR";
    Currency["Gbp"] = "GBP";
    Currency["Usd"] = "USD";
})(Currency || (Currency = {}));
export var CustomReportFilterField;
(function (CustomReportFilterField) {
    CustomReportFilterField["CompanyId"] = "COMPANY_ID";
    CustomReportFilterField["CreatedAt"] = "CREATED_AT";
    CustomReportFilterField["InspectionIds"] = "INSPECTION_IDS";
    CustomReportFilterField["UpdatedAt"] = "UPDATED_AT";
})(CustomReportFilterField || (CustomReportFilterField = {}));
export var CustomReportOrderByField;
(function (CustomReportOrderByField) {
    CustomReportOrderByField["CreatedAt"] = "CREATED_AT";
    CustomReportOrderByField["UpdatedAt"] = "UPDATED_AT";
})(CustomReportOrderByField || (CustomReportOrderByField = {}));
export var CustomReportTemplateFilterField;
(function (CustomReportTemplateFilterField) {
    CustomReportTemplateFilterField["CompanyId"] = "COMPANY_ID";
    CustomReportTemplateFilterField["CreatedAt"] = "CREATED_AT";
    CustomReportTemplateFilterField["UpdatedAt"] = "UPDATED_AT";
})(CustomReportTemplateFilterField || (CustomReportTemplateFilterField = {}));
export var CustomReportTemplateOrderByField;
(function (CustomReportTemplateOrderByField) {
    CustomReportTemplateOrderByField["CreatedAt"] = "CREATED_AT";
    CustomReportTemplateOrderByField["UpdatedAt"] = "UPDATED_AT";
})(CustomReportTemplateOrderByField || (CustomReportTemplateOrderByField = {}));
export var DataContentType;
(function (DataContentType) {
    DataContentType["Date"] = "date";
    DataContentType["Header"] = "header";
    DataContentType["Number"] = "number";
    DataContentType["Options"] = "options";
    DataContentType["OptionsMultiselect"] = "optionsMultiselect";
    DataContentType["Paragraph"] = "paragraph";
    DataContentType["Rating"] = "rating";
    DataContentType["Score"] = "score";
    DataContentType["Signature"] = "signature";
    DataContentType["String"] = "string";
    DataContentType["Text"] = "text";
})(DataContentType || (DataContentType = {}));
export var DataProcessingLocation;
(function (DataProcessingLocation) {
    DataProcessingLocation["Eu"] = "EU";
    DataProcessingLocation["Us"] = "US";
})(DataProcessingLocation || (DataProcessingLocation = {}));
export var DataSourceType;
(function (DataSourceType) {
    DataSourceType["Inspection"] = "INSPECTION";
})(DataSourceType || (DataSourceType = {}));
export var EntityType;
(function (EntityType) {
    EntityType["Business"] = "Business";
})(EntityType || (EntityType = {}));
export var FilterOperator;
(function (FilterOperator) {
    FilterOperator["ArrayContains"] = "ARRAY_CONTAINS";
    FilterOperator["ArrayContainsAny"] = "ARRAY_CONTAINS_ANY";
    FilterOperator["GreaterThan"] = "GREATER_THAN";
    FilterOperator["GreaterThanOrEqual"] = "GREATER_THAN_OR_EQUAL";
    FilterOperator["In"] = "IN";
    FilterOperator["Is"] = "IS";
    FilterOperator["IsNot"] = "IS_NOT";
    FilterOperator["LessThan"] = "LESS_THAN";
    FilterOperator["LessThanOrEqual"] = "LESS_THAN_OR_EQUAL";
})(FilterOperator || (FilterOperator = {}));
export var ImageSize;
(function (ImageSize) {
    ImageSize["Large"] = "LARGE";
    ImageSize["Medium"] = "MEDIUM";
})(ImageSize || (ImageSize = {}));
export var InspectionFilterField;
(function (InspectionFilterField) {
    InspectionFilterField["Archived"] = "ARCHIVED";
    InspectionFilterField["CompanyId"] = "COMPANY_ID";
    InspectionFilterField["ProjectId"] = "PROJECT_ID";
    InspectionFilterField["Timestamp"] = "TIMESTAMP";
    InspectionFilterField["Updated"] = "UPDATED";
})(InspectionFilterField || (InspectionFilterField = {}));
export var InspectionGroupBy;
(function (InspectionGroupBy) {
    InspectionGroupBy["Inspector"] = "inspector";
    InspectionGroupBy["RegistrationDrawingTitle"] = "registrationDrawing_title";
    InspectionGroupBy["Status"] = "status";
    InspectionGroupBy["Tag1"] = "tag1";
    InspectionGroupBy["Tag1AtatNumber"] = "tag1_atat_number";
    InspectionGroupBy["Tag2"] = "tag2";
    InspectionGroupBy["TemplateTags"] = "template_tags";
    InspectionGroupBy["TimestampDay"] = "timestamp_day";
})(InspectionGroupBy || (InspectionGroupBy = {}));
export var InspectionOrderByField;
(function (InspectionOrderByField) {
    InspectionOrderByField["CreatedAt"] = "CREATED_AT";
    InspectionOrderByField["Timestamp"] = "TIMESTAMP";
})(InspectionOrderByField || (InspectionOrderByField = {}));
export var InspectionTemplateFilterField;
(function (InspectionTemplateFilterField) {
    InspectionTemplateFilterField["CompanyId"] = "COMPANY_ID";
    InspectionTemplateFilterField["CreatedAt"] = "CREATED_AT";
    InspectionTemplateFilterField["Timestamp"] = "TIMESTAMP";
})(InspectionTemplateFilterField || (InspectionTemplateFilterField = {}));
export var InspectionTemplateOrderByField;
(function (InspectionTemplateOrderByField) {
    InspectionTemplateOrderByField["Title"] = "TITLE";
})(InspectionTemplateOrderByField || (InspectionTemplateOrderByField = {}));
export var InvitationOrderByField;
(function (InvitationOrderByField) {
    InvitationOrderByField["CreatedAt"] = "CREATED_AT";
})(InvitationOrderByField || (InvitationOrderByField = {}));
export var InvitationPermissionRole;
(function (InvitationPermissionRole) {
    InvitationPermissionRole["Assignee"] = "ASSIGNEE";
    InvitationPermissionRole["Contributor"] = "CONTRIBUTOR";
    InvitationPermissionRole["Owner"] = "OWNER";
})(InvitationPermissionRole || (InvitationPermissionRole = {}));
export var InvitationRecipientGroup;
(function (InvitationRecipientGroup) {
    InvitationRecipientGroup["External"] = "EXTERNAL";
    InvitationRecipientGroup["Internal"] = "INTERNAL";
})(InvitationRecipientGroup || (InvitationRecipientGroup = {}));
export var InvitationType;
(function (InvitationType) {
    InvitationType["Assignment"] = "ASSIGNMENT";
    InvitationType["Inspection"] = "INSPECTION";
    InvitationType["Project"] = "PROJECT";
})(InvitationType || (InvitationType = {}));
export var MembershipStatus;
(function (MembershipStatus) {
    MembershipStatus["Canceled"] = "CANCELED";
    MembershipStatus["Paid"] = "PAID";
    MembershipStatus["PendingPayment"] = "PENDING_PAYMENT";
})(MembershipStatus || (MembershipStatus = {}));
export var PageNumberPosition;
(function (PageNumberPosition) {
    PageNumberPosition["BottomLeft"] = "BOTTOM_LEFT";
    PageNumberPosition["BottomRight"] = "BOTTOM_RIGHT";
    PageNumberPosition["TopLeft"] = "TOP_LEFT";
    PageNumberPosition["TopRight"] = "TOP_RIGHT";
})(PageNumberPosition || (PageNumberPosition = {}));
export var PageOrientation;
(function (PageOrientation) {
    PageOrientation["Landscape"] = "LANDSCAPE";
    PageOrientation["Portrait"] = "PORTRAIT";
})(PageOrientation || (PageOrientation = {}));
export var PageType;
(function (PageType) {
    PageType["A3"] = "A3";
    PageType["A4"] = "A4";
    PageType["A5"] = "A5";
    PageType["Custom"] = "CUSTOM";
})(PageType || (PageType = {}));
export var PermissionRole;
(function (PermissionRole) {
    PermissionRole["Assignee"] = "ASSIGNEE";
    PermissionRole["Contributor"] = "CONTRIBUTOR";
    PermissionRole["Owner"] = "OWNER";
})(PermissionRole || (PermissionRole = {}));
export var PlanType;
(function (PlanType) {
    PlanType["Free"] = "FREE";
    PlanType["Membership"] = "MEMBERSHIP";
})(PlanType || (PlanType = {}));
export var ProjectFilterField;
(function (ProjectFilterField) {
    ProjectFilterField["CompanyId"] = "COMPANY_ID";
    ProjectFilterField["CreatedAt"] = "CREATED_AT";
    ProjectFilterField["Timestamp"] = "TIMESTAMP";
    ProjectFilterField["Updated"] = "UPDATED";
})(ProjectFilterField || (ProjectFilterField = {}));
export var ProjectOrderByField;
(function (ProjectOrderByField) {
    ProjectOrderByField["CreatedAt"] = "CREATED_AT";
    ProjectOrderByField["Timestamp"] = "TIMESTAMP";
    ProjectOrderByField["Updated"] = "UPDATED";
})(ProjectOrderByField || (ProjectOrderByField = {}));
export var ProjectVariableType;
(function (ProjectVariableType) {
    ProjectVariableType["Number"] = "NUMBER";
    ProjectVariableType["String"] = "STRING";
})(ProjectVariableType || (ProjectVariableType = {}));
export var PublicResourceFilterField;
(function (PublicResourceFilterField) {
    PublicResourceFilterField["Industries"] = "INDUSTRIES";
})(PublicResourceFilterField || (PublicResourceFilterField = {}));
export var PublicResourceOrderByField;
(function (PublicResourceOrderByField) {
    PublicResourceOrderByField["Name"] = "NAME";
    PublicResourceOrderByField["Timestamp"] = "TIMESTAMP";
})(PublicResourceOrderByField || (PublicResourceOrderByField = {}));
export var RegistrationFieldType;
(function (RegistrationFieldType) {
    RegistrationFieldType["Date"] = "DATE";
    RegistrationFieldType["Image"] = "IMAGE";
    RegistrationFieldType["Number"] = "NUMBER";
    RegistrationFieldType["Select"] = "SELECT";
    RegistrationFieldType["SelectMulti"] = "SELECT_MULTI";
    RegistrationFieldType["Text"] = "TEXT";
    RegistrationFieldType["TextMulti"] = "TEXT_MULTI";
})(RegistrationFieldType || (RegistrationFieldType = {}));
export var ReportFilterField;
(function (ReportFilterField) {
    ReportFilterField["CompanyId"] = "COMPANY_ID";
    ReportFilterField["InspectionId"] = "INSPECTION_ID";
    ReportFilterField["Timestamp"] = "TIMESTAMP";
})(ReportFilterField || (ReportFilterField = {}));
export var ReportOrderByField;
(function (ReportOrderByField) {
    ReportOrderByField["Timestamp"] = "TIMESTAMP";
})(ReportOrderByField || (ReportOrderByField = {}));
/** Enum representing fields for filtering report templates. */
export var ReportTemplateFilterField;
(function (ReportTemplateFilterField) {
    /** Return templates owned by a specific user. */
    ReportTemplateFilterField["Owner"] = "OWNER";
})(ReportTemplateFilterField || (ReportTemplateFilterField = {}));
/** Enum for ordering report templates. */
export var ReportTemplateOrderByField;
(function (ReportTemplateOrderByField) {
    ReportTemplateOrderByField["Id"] = "ID";
})(ReportTemplateOrderByField || (ReportTemplateOrderByField = {}));
/** Enum representing fields for filtering reports. */
export var ReportV2FilterField;
(function (ReportV2FilterField) {
    /** Retrieve only reports with certain external data definition. */
    ReportV2FilterField["ExternalData"] = "EXTERNAL_DATA";
})(ReportV2FilterField || (ReportV2FilterField = {}));
/** Enum for ordering reports. */
export var ReportV2OrderByField;
(function (ReportV2OrderByField) {
    ReportV2OrderByField["CreatedAt"] = "CREATED_AT";
    ReportV2OrderByField["UpdatedAt"] = "UPDATED_AT";
})(ReportV2OrderByField || (ReportV2OrderByField = {}));
export var SelectedActorsPermissionRole;
(function (SelectedActorsPermissionRole) {
    SelectedActorsPermissionRole["Contributor"] = "CONTRIBUTOR";
    SelectedActorsPermissionRole["Owner"] = "OWNER";
})(SelectedActorsPermissionRole || (SelectedActorsPermissionRole = {}));
export var SortOrder;
(function (SortOrder) {
    SortOrder["Ascending"] = "ASCENDING";
    SortOrder["Descending"] = "DESCENDING";
})(SortOrder || (SortOrder = {}));
export var SubscribedChangeType;
(function (SubscribedChangeType) {
    SubscribedChangeType["Added"] = "ADDED";
    SubscribedChangeType["Modified"] = "MODIFIED";
    SubscribedChangeType["Removed"] = "REMOVED";
})(SubscribedChangeType || (SubscribedChangeType = {}));
export var SubscribedInspectionChangeType;
(function (SubscribedInspectionChangeType) {
    SubscribedInspectionChangeType["Added"] = "ADDED";
    SubscribedInspectionChangeType["Modified"] = "MODIFIED";
    SubscribedInspectionChangeType["Removed"] = "REMOVED";
})(SubscribedInspectionChangeType || (SubscribedInspectionChangeType = {}));
export var SubscribedInspectionPermissionRole;
(function (SubscribedInspectionPermissionRole) {
    SubscribedInspectionPermissionRole["Assignee"] = "ASSIGNEE";
    SubscribedInspectionPermissionRole["Contributor"] = "CONTRIBUTOR";
    SubscribedInspectionPermissionRole["Owner"] = "OWNER";
})(SubscribedInspectionPermissionRole || (SubscribedInspectionPermissionRole = {}));
export var UserAdminRole;
(function (UserAdminRole) {
    UserAdminRole["Admin"] = "admin";
    UserAdminRole["SuperAdmin"] = "super_admin";
    UserAdminRole["User"] = "user";
})(UserAdminRole || (UserAdminRole = {}));
export var UserColorScheme;
(function (UserColorScheme) {
    UserColorScheme["Dark"] = "dark";
    UserColorScheme["Light"] = "light";
})(UserColorScheme || (UserColorScheme = {}));
export var UserFilterField;
(function (UserFilterField) {
    UserFilterField["CompanyId"] = "COMPANY_ID";
})(UserFilterField || (UserFilterField = {}));
export var UserNotificationType;
(function (UserNotificationType) {
    UserNotificationType["Invitation"] = "invitation";
})(UserNotificationType || (UserNotificationType = {}));
export var UserOrderByField;
(function (UserOrderByField) {
    UserOrderByField["Name"] = "NAME";
})(UserOrderByField || (UserOrderByField = {}));
export var UserRequiredAction;
(function (UserRequiredAction) {
    UserRequiredAction["AcceptTerms"] = "ACCEPT_TERMS";
    UserRequiredAction["AddCompany"] = "ADD_COMPANY";
    UserRequiredAction["AddName"] = "ADD_NAME";
    UserRequiredAction["ChangePassword"] = "CHANGE_PASSWORD";
    UserRequiredAction["VerifyEmailAddress"] = "VERIFY_EMAIL_ADDRESS";
})(UserRequiredAction || (UserRequiredAction = {}));
export const ContactFragmentDoc = gql `
    fragment contact on Contact {
  address
  companyName
  country
  email
  id
  name
  phone
  registrationNumber
}
    `;
export const ContributorPermissionsFragmentDoc = gql `
    fragment contributorPermissions on ContributorPermissions {
  addFromPhone
  addFromProject
  addNoLocation
  addSatelliteImage
  modifyCategories
  modifyDataForm
}
    `;
export const DataContentOptionFragmentDoc = gql `
    fragment dataContentOption on DataContentOption {
  label
  value
}
    `;
export const DataContentFragmentDoc = gql `
    fragment dataContent on DataContent {
  dataVariable
  key
  label
  options {
    ...dataContentOption
  }
  order
  type
  userInput
  value
}
    ${DataContentOptionFragmentDoc}`;
export const ImageMetaFragmentDoc = gql `
    fragment imageMeta on ImageMeta {
  fullPath
  name
  height
  width
}
    `;
export const ImageOldFragmentDoc = gql `
    fragment imageOld on ImageOld {
  imageUrl
  imageThumbnailMedium
  imageThumbnailSmall
}
    `;
export const LabelFragmentDoc = gql `
    fragment label on Label {
  id
  color
  colorContrast
  title
  order
}
    `;
export const LabelGroupFragmentDoc = gql `
    fragment labelGroup on LabelGroup {
  labels {
    ...label
  }
  selectedIndex
  title
}
    ${LabelFragmentDoc}`;
export const InspectionNoteFragmentDoc = gql `
    fragment inspectionNote on InspectionNote {
  id
  copy
  label {
    ...label
  }
  order
  tagId
  title
}
    ${LabelFragmentDoc}`;
export const RegistrationDrawingFragmentDoc = gql `
    fragment registrationDrawing on RegistrationDrawing {
  autoAddToNewInspections
  imageId
  imageUrl
  imageThumbnailSmall
  imageThumbnailMedium
  title
  isNoLocation
}
    `;
export const ReportStructurePageFragmentDoc = gql `
    fragment reportStructurePage on ReportStructurePage {
  content
  enabled
  header
  photo
  order
  itemsPerPage
}
    `;
export const ReportStructureFragmentDoc = gql `
    fragment reportStructure on ReportStructure {
  abstractPage {
    ...reportStructurePage
  }
  actorsPage {
    ...reportStructurePage
  }
  capturesPages {
    ...reportStructurePage
  }
  closingPage {
    ...reportStructurePage
  }
  coverPage {
    ...reportStructurePage
  }
  introductionPage {
    ...reportStructurePage
  }
  plansPage {
    ...reportStructurePage
  }
  tocPage {
    ...reportStructurePage
  }
  widePages
}
    ${ReportStructurePageFragmentDoc}`;
export const PermissionFragmentDoc = gql `
    fragment permission on Permission {
  userId
  name
  title
  companyId
  role
  exist
}
    `;
export const InspectionTagFragmentDoc = gql `
    fragment inspectionTag on InspectionTag {
  childIds
  id
  order
  root
  title
}
    `;
export const InspectionFragmentDoc = gql `
    fragment inspection on Inspection {
  archived
  captureCount
  company {
    id
    name
  }
  dataContent {
    ...dataContent
  }
  dataContentUseHeadersAsTOC
  groupBy
  id
  imageMeta {
    ...imageMeta
  }
  imageOld {
    ...imageOld
  }
  imageThumbnailMedium
  imageThumbnailSmall
  imageUrl
  labelGroups {
    ...labelGroup
  }
  notes {
    ...inspectionNote
  }
  project {
    id
    imageUrl
    title
  }
  registrationDrawings {
    ...registrationDrawing
  }
  reportCount
  structure {
    ...reportStructure
  }
  permissions {
    ...permission
  }
  tags {
    ...inspectionTag
  }
  template {
    id
  }
  timestamp
  title
  updated
  user {
    id
    name
  }
}
    ${DataContentFragmentDoc}
${ImageMetaFragmentDoc}
${ImageOldFragmentDoc}
${LabelGroupFragmentDoc}
${InspectionNoteFragmentDoc}
${RegistrationDrawingFragmentDoc}
${ReportStructureFragmentDoc}
${PermissionFragmentDoc}
${InspectionTagFragmentDoc}`;
export const TimeConfigDateFragmentDoc = gql `
    fragment timeConfigDate on TimeConfigDate {
  day
  weekday
  month
  year
}
    `;
export const TimeConfigTimeFragmentDoc = gql `
    fragment timeConfigTime on TimeConfigTime {
  enabled
  hour12
  hour
  minute
  second
}
    `;
export const TimeConfigFragmentDoc = gql `
    fragment timeConfig on TimeConfig {
  locale
  date {
    ...timeConfigDate
  }
  time {
    ...timeConfigTime
  }
  timeZone
}
    ${TimeConfigDateFragmentDoc}
${TimeConfigTimeFragmentDoc}`;
export const ReportLocalisationFragmentDoc = gql `
    fragment reportLocalisation on ReportLocalisation {
  abstract
  actors
  captureBy
  captureTime
  conclusion
  cover
  introduction
  labelNoStatus
  locationGPS
  locationGPSenabled
  page
  pageFooter
  plan
  registrationDrawing
  signatureRequest
  signatureTime
  tableOfContents
  timeConfig {
    ...timeConfig
  }
}
    ${TimeConfigFragmentDoc}`;
export const InspectionTemplateFragmentDoc = gql `
    fragment inspectionTemplate on InspectionTemplate {
  company {
    id
  }
  count
  dataContent {
    ...dataContent
  }
  dataContentUseHeadersAsTOC
  groupBy
  id
  labelGroups {
    ...labelGroup
  }
  localisation {
    ...reportLocalisation
  }
  notes {
    ...inspectionNote
  }
  registrationDrawings {
    ...registrationDrawing
  }
  reportFooter
  structure {
    ...reportStructure
  }
  tags {
    ...inspectionTag
  }
  title
}
    ${DataContentFragmentDoc}
${LabelGroupFragmentDoc}
${ReportLocalisationFragmentDoc}
${InspectionNoteFragmentDoc}
${RegistrationDrawingFragmentDoc}
${ReportStructureFragmentDoc}
${InspectionTagFragmentDoc}`;
export const InvitationRecipientFragmentDoc = gql `
    fragment invitationRecipient on InvitationRecipient {
  companyId
  email
  id
  phoneNumber
  name
}
    `;
export const InvitationSenderFragmentDoc = gql `
    fragment invitationSender on InvitationSender {
  companyId
  companyName
  id
  name
}
    `;
export const InvitationTagPermissionFragmentDoc = gql `
    fragment invitationTagPermission on InvitationTagPermission {
  updated
  tags
}
    `;
export const InvitationFragmentDoc = gql `
    fragment invitation on Invitation {
  archived
  code
  id
  image
  isRevoked
  itemId
  itemTitle
  recipient {
    ...invitationRecipient
  }
  role
  sender {
    ...invitationSender
  }
  tagPermissions {
    ...invitationTagPermission
  }
  timestamp
  title
  type
}
    ${InvitationRecipientFragmentDoc}
${InvitationSenderFragmentDoc}
${InvitationTagPermissionFragmentDoc}`;
export const ProjectFragmentDoc = gql `
    fragment project on Project {
  archived
  company {
    id
    name
  }
  id
  imageMeta {
    ...imageMeta
  }
  imageOld {
    ...imageOld
  }
  imageThumbnailMedium
  imageThumbnailSmall
  imageUrl
  inspectionCount
  inspectionImage
  permissions {
    ...permission
  }
  registrationDrawings {
    ...registrationDrawing
  }
  timestamp
  title
  updated
  user {
    id
    name
  }
}
    ${ImageMetaFragmentDoc}
${ImageOldFragmentDoc}
${PermissionFragmentDoc}
${RegistrationDrawingFragmentDoc}`;
export const AudioNoteFragmentDoc = gql `
    fragment audioNote on AudioNote {
  file
  duration
  fullPath
}
    `;
export const CaptureDrawingPositionFragmentDoc = gql `
    fragment captureDrawingPosition on CaptureDrawingPosition {
  duration
  scale
  xPercentage
  yPercentage
  x
  y
}
    `;
export const CaptureHistoryFragmentDoc = gql `
    fragment captureHistory on CaptureHistory {
  root
  parent
  children
}
    `;
export const CaptureLocationFragmentDoc = gql `
    fragment captureLocation on CaptureLocation {
  latitude
  longitude
  accuracy
}
    `;
export const CaptureStatusFragmentDoc = gql `
    fragment captureStatus on CaptureStatus {
  color
  colorContrast
  id
  order
  title
}
    `;
export const CaptureFragmentDoc = gql `
    fragment capture on Capture {
  archived
  audio {
    ...audioNote
  }
  companyId
  disabled
  drawingPosition {
    ...captureDrawingPosition
  }
  history {
    ...captureHistory
  }
  id
  imageId
  imageMeta {
    ...imageMeta
  }
  imageSourceType
  imageThumbnailMedium
  imageThumbnailSmall
  imageUrl
  inspection {
    id
    title
  }
  inspectionId
  inspectionTitle
  labelGroups {
    ...labelGroup
  }
  location {
    ...captureLocation
  }
  note
  pending
  permissions {
    ...permission
  }
  project {
    id
    title
  }
  projectId
  projectTitle
  registrationDrawing {
    ...registrationDrawing
  }
  remoteInspectionId
  remoteLocation {
    ...captureLocation
  }
  status {
    ...captureStatus
  }
  tagId
  tags
  timestamp
  type
  updated
  userId
  userName
}
    ${AudioNoteFragmentDoc}
${CaptureDrawingPositionFragmentDoc}
${CaptureHistoryFragmentDoc}
${ImageMetaFragmentDoc}
${LabelGroupFragmentDoc}
${CaptureLocationFragmentDoc}
${PermissionFragmentDoc}
${RegistrationDrawingFragmentDoc}
${CaptureStatusFragmentDoc}`;
export const PlanLimitationsFragmentDoc = gql `
    fragment planLimitations on PlanLimitations {
  admins
  contacts
  inspectionTemplates
  registrations
  remoteInspections
  users
  watermark
}
    `;
export const CompanyFragmentDoc = gql `
    fragment company on Company {
  id
  locale
  logo
  name
  activeSubscription {
    limitations {
      ...planLimitations
    }
    stripeProductId
    status
  }
}
    ${PlanLimitationsFragmentDoc}`;
export const ReportFooterFragmentDoc = gql `
    fragment reportFooter on ReportFooter {
  content
  pageNumber
}
    `;
export const ReportPdfFragmentDoc = gql `
    fragment reportPDF on ReportPDF {
  generatedTime
  generatedBy {
    id
    name
  }
  shortUrl
  size
  url
}
    `;
export const ReportTagFieldsFragmentDoc = gql `
    fragment reportTagFields on ReportTag {
  id
  title
}
    `;
export const ReportTagFragmentDoc = gql `
    fragment reportTag on ReportTag {
  ...reportTagFields
  tags {
    ...reportTagFields
    tags {
      ...reportTagFields
      tags {
        ...reportTagFields
      }
    }
  }
}
    ${ReportTagFieldsFragmentDoc}`;
export const ReportFragmentDoc = gql `
    fragment report on Report {
  captureCount
  captures {
    ...capture
  }
  company {
    ...company
  }
  dataContent {
    ...dataContent
  }
  footer {
    ...reportFooter
  }
  groupBy
  id
  inspectionId
  latestPDF
  labelGroups {
    ...labelGroup
  }
  localisation {
    ...reportLocalisation
  }
  pdfs {
    ...reportPDF
  }
  registrationDrawings {
    ...registrationDrawing
  }
  reportDate
  reportLanguage
  reportName
  reportProject
  structure {
    ...reportStructure
  }
  tags {
    ...reportTag
  }
}
    ${CaptureFragmentDoc}
${CompanyFragmentDoc}
${DataContentFragmentDoc}
${ReportFooterFragmentDoc}
${LabelGroupFragmentDoc}
${ReportLocalisationFragmentDoc}
${ReportPdfFragmentDoc}
${RegistrationDrawingFragmentDoc}
${ReportStructureFragmentDoc}
${ReportTagFragmentDoc}`;
export const ReportTemplateFragmentDoc = gql `
    fragment reportTemplate on ReportTemplate {
  id
  title
  updatedAt
}
    `;
export const TagPermissionFragmentDoc = gql `
    fragment tagPermission on TagPermission {
  userId
  tags
}
    `;
export const UserFragmentDoc = gql `
    fragment user on User {
  adminRole
  companyId
  company {
    id
  }
  copyCapturesToMediaLibrary
  email
  id
  invitationNotifications
  locale
  name
  phoneNumber
  recoveryMode
  title
}
    `;
export const AcceptInvitationsDocument = gql `
    mutation acceptInvitations($input: AcceptInvitationsInput!) {
  acceptInvitations(input: $input) {
    success
  }
}
    `;
export const AddInspectionLabelDocument = gql `
    mutation addInspectionLabel($input: AddInspectionLabelInput!) {
  addInspectionLabel(input: $input) {
    inspection {
      id
    }
  }
}
    `;
export const AddInspectionLabelGroupDocument = gql `
    mutation addInspectionLabelGroup($input: AddInspectionLabelGroupInput!) {
  addInspectionLabelGroup(input: $input) {
    inspection {
      id
    }
  }
}
    `;
export const AddInspectionRegistrationDrawingsDocument = gql `
    mutation addInspectionRegistrationDrawings($input: AddInspectionRegistrationDrawingsInput!) {
  addInspectionRegistrationDrawings(input: $input) {
    inspection {
      id
    }
  }
}
    `;
export const AddInspectionTagPermissionsDocument = gql `
    mutation addInspectionTagPermissions($input: AddInspectionTagPermissionsInput!) {
  addInspectionTagPermissions(input: $input) {
    inspection {
      id
    }
  }
}
    `;
export const AddProjectRegistrationDrawingsDocument = gql `
    mutation addProjectRegistrationDrawings($input: AddProjectRegistrationDrawingsInput!) {
  addProjectRegistrationDrawings(input: $input) {
    project {
      id
      registrationDrawings {
        ...registrationDrawing
      }
    }
  }
}
    ${RegistrationDrawingFragmentDoc}`;
export const ChangePasswordDocument = gql `
    mutation changePassword($input: ChangePasswordInput!) {
  changePassword(input: $input) {
    customToken
  }
}
    `;
export const CheckIfUserExistsDocument = gql `
    query checkIfUserExists($input: CheckIfUserExistsInput!) {
  checkIfUserExists(input: $input) {
    userExists
  }
}
    `;
export const CreateAssignmentDocument = gql `
    mutation createAssignment($input: CreateAssignmentInput!) {
  createAssignment(input: $input) {
    assignment {
      archived
      assigneeId
      assigneeName
      assigneeEmail
      assigneeGroup
      assigneePhoneNumber
      captureIds
      companyId
      companyName
      id
      inspectionId
      inspectionTitle
      permissionIds
      permissions {
        email
        exist
        name
        phoneNumber
        role
        userId
      }
      projectId
      projectImage
      projectTitle
      tasks {
        id
        status
        responseId
      }
      status
      updated
    }
  }
}
    `;
export const CreateCompanyDocument = gql `
    mutation createCompany($input: CreateCompanyInput!) {
  createCompany(input: $input) {
    company {
      ...company
    }
  }
}
    ${CompanyFragmentDoc}`;
export const CreateContactDocument = gql `
    mutation createContact($input: CreateContactInput!) {
  createContact(input: $input) {
    contact {
      ...contact
    }
  }
}
    ${ContactFragmentDoc}`;
export const CreateInspectionFromTemplateDocument = gql `
    mutation createInspectionFromTemplate($input: CreateInspectionFromTemplateInput!) {
  createInspectionFromTemplate(input: $input) {
    inspection {
      id
    }
  }
}
    `;
export const CreateInspectionNotesDocument = gql `
    mutation createInspectionNotes($input: CreateInspectionNotesInput!) {
  createInspectionNotes(input: $input) {
    inspection {
      id
    }
  }
}
    `;
export const CreateInspectionTagDocument = gql `
    mutation createInspectionTag($input: CreateInspectionTagInput!) {
  createInspectionTag(input: $input) {
    inspection {
      id
    }
  }
}
    `;
export const CreateInspectionTagsRawDocument = gql `
    mutation createInspectionTagsRaw($input: CreateInspectionTagsRawInput!) {
  createInspectionTagsRaw(input: $input) {
    inspection {
      id
    }
  }
}
    `;
export const CreateInvitationsDocument = gql `
    mutation createInvitations($input: CreateInvitationsInput!) {
  createInvitations(input: $input) {
    invitations {
      archived
      code
      id
      image
      isRevoked
      itemId
      itemTitle
      recipient {
        companyId
        email
        id
        name
        phoneNumber
      }
      role
      sender {
        companyId
        companyName
        id
        name
      }
      tagPermissions {
        tags
        updated
      }
      timestamp
      title
      type
    }
  }
}
    `;
export const CreateNoneCaptureAssignmentDocument = gql `
    mutation createNoneCaptureAssignment($input: CreateNoneCaptureAssignmentInput!) {
  createNoneCaptureAssignment(input: $input) {
    assignment {
      archived
      assigneeEmail
      assigneeGroup
      assigneeId
      assigneeName
      assigneePhoneNumber
      captureIds
      companyId
      companyName
      id
      inspectionId
      inspectionTitle
      permissionIds
      permissions {
        email
        exist
        name
        phoneNumber
        role
        userId
      }
      projectId
      projectImage
      projectTitle
      status
      tasks {
        id
        status
        responseId
      }
      updated
    }
  }
}
    `;
export const CreateProjectDocument = gql `
    mutation createProject($input: CreateProjectInput!) {
  createProject(input: $input) {
    project {
      id
    }
  }
}
    `;
export const CreateReCaptureDocument = gql `
    mutation createReCapture($input: CreateReCaptureInput!) {
  createReCapture(input: $input) {
    capture {
      id
    }
  }
}
    `;
export const CreateReportTemplateFromReportTemplateDocument = gql `
    mutation createReportTemplateFromReportTemplate($input: CreateReportTemplateFromReportTemplateInput!) {
  createReportTemplateFromReportTemplate(input: $input) {
    reportTemplate {
      id
      title
    }
  }
}
    `;
export const CreateReportV2FromReportTemplateDocument = gql `
    mutation createReportV2FromReportTemplate($input: CreateReportV2FromReportTemplateInput!) {
  createReportV2FromReportTemplate(input: $input) {
    report {
      id
      title
    }
  }
}
    `;
export const CreateUserWithEmailDocument = gql `
    mutation createUserWithEmail($input: CreateUserWithEmailInput!) {
  createUserWithEmail(input: $input) {
    user {
      id
    }
  }
}
    `;
export const DeleteCaptureDocument = gql `
    mutation deleteCapture($input: DeleteCaptureInput!) {
  deleteCapture(input: $input) {
    success
  }
}
    `;
export const DeleteCompanyDocument = gql `
    mutation deleteCompany($input: DeleteCompanyInput!) {
  deleteCompany(input: $input) {
    success
  }
}
    `;
export const DeleteContactDocument = gql `
    mutation deleteContact($input: DeleteContactInput!) {
  deleteContact(input: $input) {
    success
  }
}
    `;
export const DeleteInspectionDocument = gql `
    mutation deleteInspection($input: DeleteInspectionInput!) {
  deleteInspection(input: $input) {
    success
  }
}
    `;
export const DeleteInspectionLabelDocument = gql `
    mutation deleteInspectionLabel($input: DeleteInspectionLabelInput!) {
  deleteInspectionLabel(input: $input) {
    inspection {
      id
    }
  }
}
    `;
export const DeleteInspectionLabelGroupDocument = gql `
    mutation deleteInspectionLabelGroup($input: DeleteInspectionLabelGroupInput!) {
  deleteInspectionLabelGroup(input: $input) {
    inspection {
      id
    }
  }
}
    `;
export const DeleteInspectionNoteDocument = gql `
    mutation deleteInspectionNote($input: DeleteInspectionNoteInput!) {
  deleteInspectionNote(input: $input) {
    inspection {
      id
    }
  }
}
    `;
export const DeleteInspectionTagDocument = gql `
    mutation deleteInspectionTag($input: DeleteInspectionTagInput!) {
  deleteInspectionTag(input: $input) {
    inspection {
      id
    }
  }
}
    `;
export const DeleteInspectionTemplateDocument = gql `
    mutation deleteInspectionTemplate($input: DeleteInspectionTemplateInput!) {
  deleteInspectionTemplate(input: $input) {
    success
  }
}
    `;
export const DeleteInspectionTemplateNoteDocument = gql `
    mutation deleteInspectionTemplateNote($input: DeleteInspectionTemplateNoteInput!) {
  deleteInspectionTemplateNote(input: $input) {
    inspectionTemplate {
      id
    }
  }
}
    `;
export const DeleteInvitationDocument = gql `
    mutation deleteInvitation($input: DeleteInvitationInput!) {
  deleteInvitation(input: $input) {
    success
  }
}
    `;
export const DeleteProjectDocument = gql `
    mutation deleteProject($input: DeleteProjectInput!) {
  deleteProject(input: $input) {
    success
  }
}
    `;
export const DeleteReportDocument = gql `
    mutation deleteReport($input: DeleteReportInput!) {
  deleteReport(input: $input) {
    success
  }
}
    `;
export const DeleteReportTemplateDocument = gql `
    mutation deleteReportTemplate($input: DeleteReportTemplateInput!) {
  deleteReportTemplate(input: $input) {
    success
  }
}
    `;
export const DeleteReportV2Document = gql `
    mutation deleteReportV2($input: DeleteReportV2Input!) {
  deleteReportV2(input: $input) {
    success
  }
}
    `;
export const DeleteUserDocument = gql `
    mutation deleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    success
  }
}
    `;
export const FormatDateTimeDocument = gql `
    query formatDateTime($input: FormatDateTimeInput!) {
  formatDateTime(input: $input) {
    dateTimes {
      dateTime
      id
    }
  }
}
    `;
export const GenerateQuickReportPdfDocument = gql `
    mutation generateQuickReportPdf($input: GenerateQuickReportPdfInput!) {
  generateQuickReportPdf(input: $input) {
    publicURL
    shortURL
  }
}
    `;
export const GetAlgoliaSearchKeyDocument = gql `
    query getAlgoliaSearchKey {
  getAlgoliaSearchKey
}
    `;
export const GetCompanyDocument = gql `
    query getCompany($input: GetCompanyInput!) {
  getCompany(input: $input) {
    company {
      ...company
    }
  }
}
    ${CompanyFragmentDoc}`;
export const GetContactDocument = gql `
    query getContact($input: GetContactInput!) {
  getContact(input: $input) {
    contact {
      ...contact
    }
  }
}
    ${ContactFragmentDoc}`;
export const GetContactsDocument = gql `
    query getContacts($input: GetContactsInput!) {
  getContacts(input: $input) {
    contacts {
      ...contact
    }
  }
}
    ${ContactFragmentDoc}`;
export const GetInspectionDocument = gql `
    query getInspection($input: GetInspectionInput!) {
  getInspection(input: $input) {
    inspection {
      ...inspection
    }
  }
}
    ${InspectionFragmentDoc}`;
export const GetInspectionTemplateDocument = gql `
    query getInspectionTemplate($input: GetInspectionTemplateInput!) {
  getInspectionTemplate(input: $input) {
    inspectionTemplate {
      ...inspectionTemplate
    }
  }
}
    ${InspectionTemplateFragmentDoc}`;
export const GetInspectionTemplateReportTemplatesDocument = gql `
    query getInspectionTemplateReportTemplates($input: GetInspectionTemplateInput!) {
  getInspectionTemplate(input: $input) {
    inspectionTemplate {
      reportTemplates {
        id
        title
      }
    }
  }
}
    `;
export const GetInspectionTemplatesDocument = gql `
    query getInspectionTemplates($input: GetInspectionTemplatesInput!) {
  getInspectionTemplates(input: $input) {
    inspectionTemplates {
      ...inspectionTemplate
    }
  }
}
    ${InspectionTemplateFragmentDoc}`;
export const GetInspectionsDocument = gql `
    query getInspections($input: GetInspectionsInput!) {
  getInspections(input: $input) {
    inspections {
      ...inspection
    }
  }
}
    ${InspectionFragmentDoc}`;
export const GetProjectDocument = gql `
    query getProject($input: GetProjectInput!) {
  getProject(input: $input) {
    project {
      ...project
    }
  }
}
    ${ProjectFragmentDoc}`;
export const GetProjectsDocument = gql `
    query getProjects($input: GetProjectsInput!) {
  getProjects(input: $input) {
    projects {
      ...project
    }
  }
}
    ${ProjectFragmentDoc}`;
export const GetReportDocument = gql `
    query getReport($input: GetReportInput!) {
  getReport(input: $input) {
    report {
      ...report
    }
  }
}
    ${ReportFragmentDoc}`;
export const GetReportTemplatesDocument = gql `
    query getReportTemplates($input: GetReportTemplatesInput!) {
  getReportTemplates(input: $input) {
    reportTemplates {
      ...reportTemplate
    }
  }
}
    ${ReportTemplateFragmentDoc}`;
export const GetReportsV2Document = gql `
    query getReportsV2($input: GetReportsV2Input!) {
  getReportsV2(input: $input) {
    reports {
      createdAt
      id
      latestPdf
      pdfs {
        createdAt
        url
      }
      title
    }
  }
}
    `;
export const GetUserDocument = gql `
    query getUser($input: GetUserInput!) {
  getUser(input: $input) {
    user {
      ...user
    }
  }
}
    ${UserFragmentDoc}`;
export const MoveInspectionToProjectDocument = gql `
    mutation moveInspectionToProject($input: MoveInspectionToProjectInput!) {
  moveInspectionToProject(input: $input) {
    success
  }
}
    `;
export const RefreshCustomTokenDocument = gql `
    mutation refreshCustomToken {
  refreshCustomToken {
    customToken
  }
}
    `;
export const RejectInvitationDocument = gql `
    mutation rejectInvitation($input: RejectInvitationInput!) {
  rejectInvitation(input: $input) {
    success
  }
}
    `;
export const RemoveInspectionImageDocument = gql `
    mutation removeInspectionImage($input: RemoveInspectionImageInput!) {
  removeInspectionImage(input: $input) {
    inspection {
      id
    }
  }
}
    `;
export const RemoveInspectionPermissionsDocument = gql `
    mutation removeInspectionPermissions($input: RemoveInspectionPermissionsInput!) {
  removeInspectionPermissions(input: $input) {
    inspection {
      id
    }
  }
}
    `;
export const RemoveInspectionRegistrationDrawingsDocument = gql `
    mutation removeInspectionRegistrationDrawings($input: RemoveInspectionRegistrationDrawingsInput!) {
  removeInspectionRegistrationDrawings(input: $input) {
    inspection {
      id
    }
  }
}
    `;
export const RemoveInspectionTagPermissionsDocument = gql `
    mutation removeInspectionTagPermissions($input: RemoveInspectionTagPermissionsInput!) {
  removeInspectionTagPermissions(input: $input) {
    inspection {
      id
    }
  }
}
    `;
export const RemoveProjectImageDocument = gql `
    mutation removeProjectImage($input: RemoveProjectImageInput!) {
  removeProjectImage(input: $input) {
    project {
      id
    }
  }
}
    `;
export const RemoveProjectPermissionsDocument = gql `
    mutation removeProjectPermissions($input: RemoveProjectPermissionsInput!) {
  removeProjectPermissions(input: $input) {
    project {
      id
    }
  }
}
    `;
export const RemoveProjectRegistrationDrawingsDocument = gql `
    mutation removeProjectRegistrationDrawings($input: RemoveProjectRegistrationDrawingsInput!) {
  removeProjectRegistrationDrawings(input: $input) {
    project {
      id
      registrationDrawings {
        ...registrationDrawing
      }
    }
  }
}
    ${RegistrationDrawingFragmentDoc}`;
export const ReplaceInspectionRegistrationDrawingDocument = gql `
    mutation replaceInspectionRegistrationDrawing($input: ReplaceInspectionRegistrationDrawingInput!) {
  replaceInspectionRegistrationDrawing(input: $input) {
    inspection {
      id
    }
  }
}
    `;
export const ResetInspectionDocument = gql `
    mutation resetInspection($input: ResetInspectionInput!) {
  resetInspection(input: $input) {
    inspection {
      id
    }
  }
}
    `;
export const RevokeAssignmentCapturesDocument = gql `
    mutation revokeAssignmentCaptures($input: RevokeAssignmentCapturesInput!) {
  revokeAssignmentCaptures(input: $input) {
    success
  }
}
    `;
export const SetCaptureStatusDocument = gql `
    mutation setCaptureStatus($input: SetCaptureStatusInput!) {
  setCaptureStatus(input: $input) {
    capture {
      id
    }
  }
}
    `;
export const SetCaptureStatusMultiDocument = gql `
    mutation setCaptureStatusMulti($input: SetCaptureStatusMultiInput!) {
  setCaptureStatusMulti(input: $input) {
    success
  }
}
    `;
export const SetInspectionAdvancedSetupDocument = gql `
    mutation setInspectionAdvancedSetup($input: SetInspectionAdvancedSetupInput!) {
  setInspectionAdvancedSetup(input: $input) {
    inspection {
      advancedSetup
    }
  }
}
    `;
export const SetInspectionContributorPermissionsDocument = gql `
    mutation setInspectionContributorPermissions($input: SetInspectionContributorPermissionsInput!) {
  setInspectionContributorPermissions(input: $input) {
    inspection {
      contributorPermissions {
        ...contributorPermissions
      }
    }
  }
}
    ${ContributorPermissionsFragmentDoc}`;
export const SetInspectionDataContentUseHeadersAsTocDocument = gql `
    mutation setInspectionDataContentUseHeadersAsTOC($input: SetInspectionDataContentUseHeadersAsTOCInput!) {
  setInspectionDataContentUseHeadersAsTOC(input: $input) {
    inspection {
      dataContentUseHeadersAsTOC
    }
  }
}
    `;
export const SetInspectionDataFormValuesDocument = gql `
    mutation setInspectionDataFormValues($input: SetInspectionDataFormValuesInput!) {
  setInspectionDataFormValues(input: $input) {
    inspection {
      id
    }
  }
}
    `;
export const SetInspectionEnableCaptureCountRequirementDocument = gql `
    mutation setInspectionEnableCaptureCountRequirement($input: SetInspectionEnableCaptureCountRequirementInput!) {
  setInspectionEnableCaptureCountRequirement(input: $input) {
    inspection {
      enableCaptureCountRequirement
    }
  }
}
    `;
export const SetInspectionGroupByDocument = gql `
    mutation setInspectionGroupBy($input: SetInspectionGroupByInput!) {
  setInspectionGroupBy(input: $input) {
    inspection {
      id
    }
  }
}
    `;
export const SetInspectionHideInAppDocument = gql `
    mutation setInspectionHideInApp($input: SetInspectionHideInAppInput!) {
  setInspectionHideInApp(input: $input) {
    inspection {
      id
    }
  }
}
    `;
export const SetInspectionImageDocument = gql `
    mutation setInspectionImage($input: SetInspectionImageInput!) {
  setInspectionImage(input: $input) {
    inspection {
      id
    }
  }
}
    `;
export const SetInspectionIsHideInAppEnabledDocument = gql `
    mutation setInspectionIsHideInAppEnabled($input: SetInspectionIsHideInAppEnabledInput!) {
  setInspectionIsHideInAppEnabled(input: $input) {
    inspection {
      id
    }
  }
}
    `;
export const SetInspectionLabelNeedsReCaptureDocument = gql `
    mutation setInspectionLabelNeedsReCapture($input: SetInspectionLabelNeedsReCaptureInput!) {
  setInspectionLabelNeedsReCapture(input: $input) {
    inspection {
      id
    }
  }
}
    `;
export const SetInspectionLabelRequireApprovalDocument = gql `
    mutation setInspectionLabelRequireApproval($input: SetInspectionLabelRequireApprovalInput!) {
  setInspectionLabelRequireApproval(input: $input) {
    inspection {
      id
    }
  }
}
    `;
export const SetInspectionStructureDocument = gql `
    mutation setInspectionStructure($input: SetInspectionStructureInput!) {
  setInspectionStructure(input: $input) {
    inspection {
      id
    }
  }
}
    `;
export const SetInspectionTitleDocument = gql `
    mutation setInspectionTitle($input: SetInspectionTitleInput!) {
  setInspectionTitle(input: $input) {
    inspection {
      id
    }
  }
}
    `;
export const SetInspectionZoomDocument = gql `
    mutation setInspectionZoom($input: SetInspectionZoomInput!) {
  setInspectionZoom(input: $input) {
    inspection {
      id
    }
  }
}
    `;
export const SetProjectImageDocument = gql `
    mutation setProjectImage($input: SetProjectImageInput!) {
  setProjectImage(input: $input) {
    project {
      id
    }
  }
}
    `;
export const SetProjectTitleDocument = gql `
    mutation setProjectTitle($input: SetProjectTitleInput!) {
  setProjectTitle(input: $input) {
    project {
      id
    }
  }
}
    `;
export const SetUserAdminRoleDocument = gql `
    mutation setUserAdminRole($input: SetUserAdminRoleInput!) {
  setUserAdminRole(input: $input) {
    user {
      adminRole
    }
  }
}
    `;
export const SetUserClearCacheDocument = gql `
    mutation setUserClearCache($input: SetUserClearCacheInput!) {
  setUserClearCache(input: $input) {
    user {
      clearCache
    }
  }
}
    `;
export const SetUserColorSchemeDocument = gql `
    mutation setUserColorScheme($input: SetUserColorSchemeInput!) {
  setUserColorScheme(input: $input) {
    user {
      colorScheme
    }
  }
}
    `;
export const SetUserCompanyIdDocument = gql `
    mutation setUserCompanyId($input: SetUserCompanyIdInput!) {
  setUserCompanyId(input: $input) {
    user {
      companyId
    }
  }
}
    `;
export const SetUserCopyCapturesToMediaLibraryDocument = gql `
    mutation setUserCopyCapturesToMediaLibrary($input: SetUserCopyCapturesToMediaLibraryInput!) {
  setUserCopyCapturesToMediaLibrary(input: $input) {
    user {
      locale
    }
  }
}
    `;
export const SetUserInvitationNotificationsDocument = gql `
    mutation setUserInvitationNotifications($input: SetUserInvitationNotificationsInput!) {
  setUserInvitationNotifications(input: $input) {
    user {
      invitationNotifications
    }
  }
}
    `;
export const SetUserLocaleDocument = gql `
    mutation setUserLocale($input: SetUserLocaleInput!) {
  setUserLocale(input: $input) {
    user {
      locale
    }
  }
}
    `;
export const SetUserNameDocument = gql `
    mutation setUserName($input: SetUserNameInput!) {
  setUserName(input: $input) {
    user {
      name
    }
  }
}
    `;
export const SetUserRecoveryModeDocument = gql `
    mutation setUserRecoveryMode($input: SetUserRecoveryModeInput!) {
  setUserRecoveryMode(input: $input) {
    user {
      recoveryMode
    }
  }
}
    `;
export const SetUserTitleDocument = gql `
    mutation setUserTitle($input: SetUserTitleInput!) {
  setUserTitle(input: $input) {
    user {
      title
    }
  }
}
    `;
export const ShareQuickReportPdfDocument = gql `
    mutation shareQuickReportPdf($input: ShareQuickReportPdfInput!) {
  shareQuickReportPdf(input: $input) {
    success
  }
}
    `;
export const UpdateCaptureDocument = gql `
    mutation updateCapture($input: UpdateCaptureInput!) {
  updateCapture(input: $input) {
    capture {
      id
    }
  }
}
    `;
export const UpdateCompanyDocument = gql `
    mutation updateCompany($input: UpdateCompanyInput!) {
  updateCompany(input: $input) {
    company {
      id
    }
  }
}
    `;
export const UpdateContactDocument = gql `
    mutation updateContact($input: UpdateContactInput!) {
  updateContact(input: $input) {
    contact {
      id
    }
  }
}
    `;
export const UpdateInspectionDocument = gql `
    mutation updateInspection($input: UpdateInspectionInput!) {
  updateInspection(input: $input) {
    inspection {
      id
    }
  }
}
    `;
export const UpdateInspectionLabelColorDocument = gql `
    mutation updateInspectionLabelColor($input: UpdateInspectionLabelColorInput!) {
  updateInspectionLabelColor(input: $input) {
    inspection {
      id
    }
  }
}
    `;
export const UpdateInspectionLabelGroupTitleDocument = gql `
    mutation updateInspectionLabelGroupTitle($input: UpdateInspectionLabelGroupTitleInput!) {
  updateInspectionLabelGroupTitle(input: $input) {
    inspection {
      id
    }
  }
}
    `;
export const UpdateInspectionLabelTitleDocument = gql `
    mutation updateInspectionLabelTitle($input: UpdateInspectionLabelTitleInput!) {
  updateInspectionLabelTitle(input: $input) {
    inspection {
      id
    }
  }
}
    `;
export const UpdateInspectionNoteDocument = gql `
    mutation updateInspectionNote($input: UpdateInspectionNoteInput!) {
  updateInspectionNote(input: $input) {
    inspection {
      id
    }
  }
}
    `;
export const UpdateInspectionRegistrationDrawingAutoAddToNewInspectionsDocument = gql `
    mutation updateInspectionRegistrationDrawingAutoAddToNewInspections($input: UpdateInspectionRegistrationDrawingAutoAddToNewInspectionsInput!) {
  updateInspectionRegistrationDrawingAutoAddToNewInspections(input: $input) {
    inspection {
      id
    }
  }
}
    `;
export const UpdateInspectionRegistrationDrawingIsNoLocationDocument = gql `
    mutation updateInspectionRegistrationDrawingIsNoLocation($input: UpdateInspectionRegistrationDrawingIsNoLocationInput!) {
  updateInspectionRegistrationDrawingIsNoLocation(input: $input) {
    inspection {
      id
    }
  }
}
    `;
export const UpdateInspectionRegistrationDrawingOrderDocument = gql `
    mutation updateInspectionRegistrationDrawingOrder($input: UpdateInspectionRegistrationDrawingOrderInput!) {
  updateInspectionRegistrationDrawingOrder(input: $input) {
    inspection {
      id
    }
  }
}
    `;
export const UpdateInspectionRegistrationDrawingTitleDocument = gql `
    mutation updateInspectionRegistrationDrawingTitle($input: UpdateInspectionRegistrationDrawingTitleInput!) {
  updateInspectionRegistrationDrawingTitle(input: $input) {
    inspection {
      id
    }
  }
}
    `;
export const UpdateInspectionTagDocument = gql `
    mutation updateInspectionTag($input: UpdateInspectionTagInput!) {
  updateInspectionTag(input: $input) {
    inspection {
      id
    }
  }
}
    `;
export const UpdateInspectionTemplateDocument = gql `
    mutation updateInspectionTemplate($input: UpdateInspectionTemplateInput!) {
  updateInspectionTemplate(input: $input) {
    inspectionTemplate {
      id
    }
  }
}
    `;
export const UpdateProjectRegistrationDrawingAutoAddToNewInspectionsDocument = gql `
    mutation updateProjectRegistrationDrawingAutoAddToNewInspections($input: UpdateProjectRegistrationDrawingAutoAddToNewInspectionsInput!) {
  updateProjectRegistrationDrawingAutoAddToNewInspections(input: $input) {
    project {
      id
    }
  }
}
    `;
export const UpdateProjectRegistrationDrawingIsNoLocationDocument = gql `
    mutation updateProjectRegistrationDrawingIsNoLocation($input: UpdateProjectRegistrationDrawingIsNoLocationInput!) {
  updateProjectRegistrationDrawingIsNoLocation(input: $input) {
    project {
      id
    }
  }
}
    `;
export const UpdateProjectRegistrationDrawingOrderDocument = gql `
    mutation updateProjectRegistrationDrawingOrder($input: UpdateProjectRegistrationDrawingOrderInput!) {
  updateProjectRegistrationDrawingOrder(input: $input) {
    project {
      id
    }
  }
}
    `;
export const UpdateProjectRegistrationDrawingTitleDocument = gql `
    mutation updateProjectRegistrationDrawingTitle($input: UpdateProjectRegistrationDrawingTitleInput!) {
  updateProjectRegistrationDrawingTitle(input: $input) {
    project {
      id
    }
  }
}
    `;
export function getSdk(requester) {
    return {
        acceptInvitations(variables, options) {
            return requester(AcceptInvitationsDocument, variables, options);
        },
        addInspectionLabel(variables, options) {
            return requester(AddInspectionLabelDocument, variables, options);
        },
        addInspectionLabelGroup(variables, options) {
            return requester(AddInspectionLabelGroupDocument, variables, options);
        },
        addInspectionRegistrationDrawings(variables, options) {
            return requester(AddInspectionRegistrationDrawingsDocument, variables, options);
        },
        addInspectionTagPermissions(variables, options) {
            return requester(AddInspectionTagPermissionsDocument, variables, options);
        },
        addProjectRegistrationDrawings(variables, options) {
            return requester(AddProjectRegistrationDrawingsDocument, variables, options);
        },
        changePassword(variables, options) {
            return requester(ChangePasswordDocument, variables, options);
        },
        checkIfUserExists(variables, options) {
            return requester(CheckIfUserExistsDocument, variables, options);
        },
        createAssignment(variables, options) {
            return requester(CreateAssignmentDocument, variables, options);
        },
        createCompany(variables, options) {
            return requester(CreateCompanyDocument, variables, options);
        },
        createContact(variables, options) {
            return requester(CreateContactDocument, variables, options);
        },
        createInspectionFromTemplate(variables, options) {
            return requester(CreateInspectionFromTemplateDocument, variables, options);
        },
        createInspectionNotes(variables, options) {
            return requester(CreateInspectionNotesDocument, variables, options);
        },
        createInspectionTag(variables, options) {
            return requester(CreateInspectionTagDocument, variables, options);
        },
        createInspectionTagsRaw(variables, options) {
            return requester(CreateInspectionTagsRawDocument, variables, options);
        },
        createInvitations(variables, options) {
            return requester(CreateInvitationsDocument, variables, options);
        },
        createNoneCaptureAssignment(variables, options) {
            return requester(CreateNoneCaptureAssignmentDocument, variables, options);
        },
        createProject(variables, options) {
            return requester(CreateProjectDocument, variables, options);
        },
        createReCapture(variables, options) {
            return requester(CreateReCaptureDocument, variables, options);
        },
        createReportTemplateFromReportTemplate(variables, options) {
            return requester(CreateReportTemplateFromReportTemplateDocument, variables, options);
        },
        createReportV2FromReportTemplate(variables, options) {
            return requester(CreateReportV2FromReportTemplateDocument, variables, options);
        },
        createUserWithEmail(variables, options) {
            return requester(CreateUserWithEmailDocument, variables, options);
        },
        deleteCapture(variables, options) {
            return requester(DeleteCaptureDocument, variables, options);
        },
        deleteCompany(variables, options) {
            return requester(DeleteCompanyDocument, variables, options);
        },
        deleteContact(variables, options) {
            return requester(DeleteContactDocument, variables, options);
        },
        deleteInspection(variables, options) {
            return requester(DeleteInspectionDocument, variables, options);
        },
        deleteInspectionLabel(variables, options) {
            return requester(DeleteInspectionLabelDocument, variables, options);
        },
        deleteInspectionLabelGroup(variables, options) {
            return requester(DeleteInspectionLabelGroupDocument, variables, options);
        },
        deleteInspectionNote(variables, options) {
            return requester(DeleteInspectionNoteDocument, variables, options);
        },
        deleteInspectionTag(variables, options) {
            return requester(DeleteInspectionTagDocument, variables, options);
        },
        deleteInspectionTemplate(variables, options) {
            return requester(DeleteInspectionTemplateDocument, variables, options);
        },
        deleteInspectionTemplateNote(variables, options) {
            return requester(DeleteInspectionTemplateNoteDocument, variables, options);
        },
        deleteInvitation(variables, options) {
            return requester(DeleteInvitationDocument, variables, options);
        },
        deleteProject(variables, options) {
            return requester(DeleteProjectDocument, variables, options);
        },
        deleteReport(variables, options) {
            return requester(DeleteReportDocument, variables, options);
        },
        deleteReportTemplate(variables, options) {
            return requester(DeleteReportTemplateDocument, variables, options);
        },
        deleteReportV2(variables, options) {
            return requester(DeleteReportV2Document, variables, options);
        },
        deleteUser(variables, options) {
            return requester(DeleteUserDocument, variables, options);
        },
        formatDateTime(variables, options) {
            return requester(FormatDateTimeDocument, variables, options);
        },
        generateQuickReportPdf(variables, options) {
            return requester(GenerateQuickReportPdfDocument, variables, options);
        },
        getAlgoliaSearchKey(variables, options) {
            return requester(GetAlgoliaSearchKeyDocument, variables, options);
        },
        getCompany(variables, options) {
            return requester(GetCompanyDocument, variables, options);
        },
        getContact(variables, options) {
            return requester(GetContactDocument, variables, options);
        },
        getContacts(variables, options) {
            return requester(GetContactsDocument, variables, options);
        },
        getInspection(variables, options) {
            return requester(GetInspectionDocument, variables, options);
        },
        getInspectionTemplate(variables, options) {
            return requester(GetInspectionTemplateDocument, variables, options);
        },
        getInspectionTemplateReportTemplates(variables, options) {
            return requester(GetInspectionTemplateReportTemplatesDocument, variables, options);
        },
        getInspectionTemplates(variables, options) {
            return requester(GetInspectionTemplatesDocument, variables, options);
        },
        getInspections(variables, options) {
            return requester(GetInspectionsDocument, variables, options);
        },
        getProject(variables, options) {
            return requester(GetProjectDocument, variables, options);
        },
        getProjects(variables, options) {
            return requester(GetProjectsDocument, variables, options);
        },
        getReport(variables, options) {
            return requester(GetReportDocument, variables, options);
        },
        getReportTemplates(variables, options) {
            return requester(GetReportTemplatesDocument, variables, options);
        },
        getReportsV2(variables, options) {
            return requester(GetReportsV2Document, variables, options);
        },
        getUser(variables, options) {
            return requester(GetUserDocument, variables, options);
        },
        moveInspectionToProject(variables, options) {
            return requester(MoveInspectionToProjectDocument, variables, options);
        },
        refreshCustomToken(variables, options) {
            return requester(RefreshCustomTokenDocument, variables, options);
        },
        rejectInvitation(variables, options) {
            return requester(RejectInvitationDocument, variables, options);
        },
        removeInspectionImage(variables, options) {
            return requester(RemoveInspectionImageDocument, variables, options);
        },
        removeInspectionPermissions(variables, options) {
            return requester(RemoveInspectionPermissionsDocument, variables, options);
        },
        removeInspectionRegistrationDrawings(variables, options) {
            return requester(RemoveInspectionRegistrationDrawingsDocument, variables, options);
        },
        removeInspectionTagPermissions(variables, options) {
            return requester(RemoveInspectionTagPermissionsDocument, variables, options);
        },
        removeProjectImage(variables, options) {
            return requester(RemoveProjectImageDocument, variables, options);
        },
        removeProjectPermissions(variables, options) {
            return requester(RemoveProjectPermissionsDocument, variables, options);
        },
        removeProjectRegistrationDrawings(variables, options) {
            return requester(RemoveProjectRegistrationDrawingsDocument, variables, options);
        },
        replaceInspectionRegistrationDrawing(variables, options) {
            return requester(ReplaceInspectionRegistrationDrawingDocument, variables, options);
        },
        resetInspection(variables, options) {
            return requester(ResetInspectionDocument, variables, options);
        },
        revokeAssignmentCaptures(variables, options) {
            return requester(RevokeAssignmentCapturesDocument, variables, options);
        },
        setCaptureStatus(variables, options) {
            return requester(SetCaptureStatusDocument, variables, options);
        },
        setCaptureStatusMulti(variables, options) {
            return requester(SetCaptureStatusMultiDocument, variables, options);
        },
        setInspectionAdvancedSetup(variables, options) {
            return requester(SetInspectionAdvancedSetupDocument, variables, options);
        },
        setInspectionContributorPermissions(variables, options) {
            return requester(SetInspectionContributorPermissionsDocument, variables, options);
        },
        setInspectionDataContentUseHeadersAsTOC(variables, options) {
            return requester(SetInspectionDataContentUseHeadersAsTocDocument, variables, options);
        },
        setInspectionDataFormValues(variables, options) {
            return requester(SetInspectionDataFormValuesDocument, variables, options);
        },
        setInspectionEnableCaptureCountRequirement(variables, options) {
            return requester(SetInspectionEnableCaptureCountRequirementDocument, variables, options);
        },
        setInspectionGroupBy(variables, options) {
            return requester(SetInspectionGroupByDocument, variables, options);
        },
        setInspectionHideInApp(variables, options) {
            return requester(SetInspectionHideInAppDocument, variables, options);
        },
        setInspectionImage(variables, options) {
            return requester(SetInspectionImageDocument, variables, options);
        },
        setInspectionIsHideInAppEnabled(variables, options) {
            return requester(SetInspectionIsHideInAppEnabledDocument, variables, options);
        },
        setInspectionLabelNeedsReCapture(variables, options) {
            return requester(SetInspectionLabelNeedsReCaptureDocument, variables, options);
        },
        setInspectionLabelRequireApproval(variables, options) {
            return requester(SetInspectionLabelRequireApprovalDocument, variables, options);
        },
        setInspectionStructure(variables, options) {
            return requester(SetInspectionStructureDocument, variables, options);
        },
        setInspectionTitle(variables, options) {
            return requester(SetInspectionTitleDocument, variables, options);
        },
        setInspectionZoom(variables, options) {
            return requester(SetInspectionZoomDocument, variables, options);
        },
        setProjectImage(variables, options) {
            return requester(SetProjectImageDocument, variables, options);
        },
        setProjectTitle(variables, options) {
            return requester(SetProjectTitleDocument, variables, options);
        },
        setUserAdminRole(variables, options) {
            return requester(SetUserAdminRoleDocument, variables, options);
        },
        setUserClearCache(variables, options) {
            return requester(SetUserClearCacheDocument, variables, options);
        },
        setUserColorScheme(variables, options) {
            return requester(SetUserColorSchemeDocument, variables, options);
        },
        setUserCompanyId(variables, options) {
            return requester(SetUserCompanyIdDocument, variables, options);
        },
        setUserCopyCapturesToMediaLibrary(variables, options) {
            return requester(SetUserCopyCapturesToMediaLibraryDocument, variables, options);
        },
        setUserInvitationNotifications(variables, options) {
            return requester(SetUserInvitationNotificationsDocument, variables, options);
        },
        setUserLocale(variables, options) {
            return requester(SetUserLocaleDocument, variables, options);
        },
        setUserName(variables, options) {
            return requester(SetUserNameDocument, variables, options);
        },
        setUserRecoveryMode(variables, options) {
            return requester(SetUserRecoveryModeDocument, variables, options);
        },
        setUserTitle(variables, options) {
            return requester(SetUserTitleDocument, variables, options);
        },
        shareQuickReportPdf(variables, options) {
            return requester(ShareQuickReportPdfDocument, variables, options);
        },
        updateCapture(variables, options) {
            return requester(UpdateCaptureDocument, variables, options);
        },
        updateCompany(variables, options) {
            return requester(UpdateCompanyDocument, variables, options);
        },
        updateContact(variables, options) {
            return requester(UpdateContactDocument, variables, options);
        },
        updateInspection(variables, options) {
            return requester(UpdateInspectionDocument, variables, options);
        },
        updateInspectionLabelColor(variables, options) {
            return requester(UpdateInspectionLabelColorDocument, variables, options);
        },
        updateInspectionLabelGroupTitle(variables, options) {
            return requester(UpdateInspectionLabelGroupTitleDocument, variables, options);
        },
        updateInspectionLabelTitle(variables, options) {
            return requester(UpdateInspectionLabelTitleDocument, variables, options);
        },
        updateInspectionNote(variables, options) {
            return requester(UpdateInspectionNoteDocument, variables, options);
        },
        updateInspectionRegistrationDrawingAutoAddToNewInspections(variables, options) {
            return requester(UpdateInspectionRegistrationDrawingAutoAddToNewInspectionsDocument, variables, options);
        },
        updateInspectionRegistrationDrawingIsNoLocation(variables, options) {
            return requester(UpdateInspectionRegistrationDrawingIsNoLocationDocument, variables, options);
        },
        updateInspectionRegistrationDrawingOrder(variables, options) {
            return requester(UpdateInspectionRegistrationDrawingOrderDocument, variables, options);
        },
        updateInspectionRegistrationDrawingTitle(variables, options) {
            return requester(UpdateInspectionRegistrationDrawingTitleDocument, variables, options);
        },
        updateInspectionTag(variables, options) {
            return requester(UpdateInspectionTagDocument, variables, options);
        },
        updateInspectionTemplate(variables, options) {
            return requester(UpdateInspectionTemplateDocument, variables, options);
        },
        updateProjectRegistrationDrawingAutoAddToNewInspections(variables, options) {
            return requester(UpdateProjectRegistrationDrawingAutoAddToNewInspectionsDocument, variables, options);
        },
        updateProjectRegistrationDrawingIsNoLocation(variables, options) {
            return requester(UpdateProjectRegistrationDrawingIsNoLocationDocument, variables, options);
        },
        updateProjectRegistrationDrawingOrder(variables, options) {
            return requester(UpdateProjectRegistrationDrawingOrderDocument, variables, options);
        },
        updateProjectRegistrationDrawingTitle(variables, options) {
            return requester(UpdateProjectRegistrationDrawingTitleDocument, variables, options);
        }
    };
}
