import i18n from '@/i18n'

export const getStructureDefault = () => {
  return {
    widePages: false,
    coverPage: {
      header: i18n.t('reports:sectionNames.coverPage'),
      content: '',
      photo: null,
      enabled: true,
    },
    abstractPage: {
      header: i18n.t('reports:sectionNames.abstractPage'),
      content: '',
      enabled: false,
    },
    introductionPage: {
      header: i18n.t('reports:sectionNames.introductionPage'),
      content: '',
      enabled: true,
    },
    tocPage: {
      header: i18n.t('reports:sectionNames.tocPage'),
      itemsPerPage: 25,
      enabled: true,
    },
    actorsPage: {
      header: i18n.t('reports:sectionNames.actorsPage'),
      content: '',
      enabled: false,
    },
    capturesPages: {
      enabled: true,
    },
    plansPage: {
      header: i18n.t('reports:sectionNames.captureOverviewPages'),
      enabled: true,
    },
    closingPage: {
      header: i18n.t('reports:sectionNames.closingPage'),
      content: '',
      enabled: false,
    },
  }
}

export const getNewTemplate = () => {
  return {
    actors: [],
    companyId: '',
    count: 0,
    groupBy: 'template_tags',
    advancedSetup: false,
    isHideInAppEnabled: false,
    dataContent: [],
    labelGroups: [
      {
        title: 'Status',
        labels: [],
      },
    ],
    report: {
      footer: '',
    },
    enableCaptureCountRequirement: false,
    root: true,
    localisation: {},
    registrationDrawings: [],
    statusChangeHistoryEnabled: true,
    structure: {
      ...getStructureDefault(),
    },
    title: 'New template',
  }
}
