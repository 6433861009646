export default function permissionsFromActors(actors) {
  const permissions = []
  const tagPermissions = []

  actors
    ?.filter(({ enabled }) => enabled !== false)
    .forEach((actor) => {
      actor?.autoAssign
        ?.filter((user) => user.bypassInvitation)
        .forEach((user) => {
          // Add users to inspection permissions.
          permissions.push({
            userId: user.id || user.uid,
            role: actor.role.toUpperCase(),
          })

          // Add tag permissions from invitation and merge with existing tag permisisons
          if (actor?.tags?.length) {
            tagPermissions.push({
              userId: user.id || user.uid,
              tags: actor.tags || [],
            })
          }
        })
    })

  return { permissions, tagPermissions }
}
