export function getImgProxyUrl(url, props = {}) {
  // Base64 encode the string using btoa()
  let base64 = btoa(url.replace(':443', ''))

  // Replace characters that are not URL-safe
  base64 = base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')

  const proxyUrl = import.meta.env.VUE_APP_IMAGE_PROXY_URL

  const args = Object.entries(props)
    .map(([key, value]) => `${key}:${value}`)
    .join('/')

  return `${proxyUrl}/sig/${args}/${base64}`
}
