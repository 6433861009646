import { firestore } from '@/firebase'
import i18n from '@/i18n'

const state = {
  resources: [],
}

const getters = {
  getSharedResources: (state) => state.resources,
}

const mutations = {
  SET_RESOURCES(state, payload) {
    state.resources = payload
  },
}

let sharedResourceUnsubscription

const actions = {
  async logResourceAccess({}, payload) {
    const openHistory = payload.openHistory || []
    openHistory.push({
      timestamp: Date.now(),
    })

    firestore.collection('sharedResources').doc(payload.id).update({
      openHistory,
    })
  },
  updateSharedResource(context, payload) {
    console.log(payload)
    firestore.collection('sharedResources').doc(payload.id).set(payload)
  },
  async getSharedInspection({ dispatch, rootState }, payload) {
    // FIX BECAUSE OF PERMISSIONS/RULES
    let report = {}
    let company = {}
    const captures = []
    await firestore
      .collection('captures')
      .where('inspectionId', '==', payload.inspectionId)
      .where('companyId', '==', payload.companyId)
      .get()
      .then((snap) => {
        snap.docs.forEach((doc) => {
          if (!doc.data().disabled) captures.push({ ...doc.data(), id: doc.id })
        })
      })
    await firestore
      .collection('companies')
      .doc(payload.companyId)
      .get()
      .then((doc) => {
        company = { ...doc.data(), id: doc.id }
      })
    await firestore
      .collection('inspections')
      .doc(payload.inspectionId)
      .get()
      .then((doc) => {
        const docData = { ...doc.data(), id: doc.id }
        report = {
          ...docData,
          captures,
          company,
          footer: {
            content: company ? company.report.footer : '',
            pageNumber: true,
          },
          localisation: null,
        }
      })
    return report
  },
  getSharedResource({ dispatch, rootState }, payload) {
    return new Promise((resolve, reject) => {
      firestore
        .collection('sharedResources')
        .where('id', '==', payload.id)
        .where('pin', '==', payload.pin)
        .where('enabled', '==', true)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.docs.length > 0) {
            const docData = {
              ...querySnapshot.docs[0].data(),
              id: querySnapshot.docs[0].id,
            }
            const { id, openHistory } = docData
            dispatch('addToast', {
              message: i18n.t('sharedResources:toast.accessGranted'),
              type: 'good',
            })

            dispatch('logResourceAccess', { id, openHistory }).then(() => {
              dispatch('getSharedInspection', {
                inspectionId: docData.resourceId,
                companyId: docData.companyId,
              }).then((report) => {
                resolve({ ...docData, report })
              })
            })
          } else {
            const err = i18n.t('sharedResources:toast.failedToGetResource')
            // Resource failed. Bad pin, not existing resource, or other reason
            dispatch('addToast', {
              message: err,
              type: 'bad',
            })
            reject(err)
          }
        })
        .catch((err) => {
          dispatch('addToast', {
            message: err,
            type: 'bad',
          })
          reject(err)
        })
    })
  },
  async shareResource({ rootState }, payload) {
    const sharableLink = {
      resourceCollection: payload.resourceCollection,
      resourceId: payload.resourceId,
      pin: payload.pin,
      resourceTitle: payload.resourceTitle,
      linkTitle: payload.linkTitle,
      companyId: rootState.User.user.companyId,
      createdBy: rootState.User.user.uid,
      timestamp: Date.now(),
      openHistory: [],
      enabled: true,
    }
    const docRef = firestore.collection('sharedResources').doc()
    firestore
      .collection('sharedResources')
      .doc(docRef.id)
      .set({ id: docRef.id, ...sharableLink })
  },
  deleteSharedResource({ dispatch }, linkId) {
    firestore
      .collection('sharedResources')
      .doc(linkId)
      .delete()
      .then(() => {
        dispatch('addToast', {
          message: i18n.t('sharedResources:toast.deleted'),
          type: 'good',
        })
      })
  },
  subscribeToSharedResources({ commit, rootState }, payload) {
    sharedResourceUnsubscription = firestore
      .collection('sharedResources')
      .where('companyId', '==', rootState.User.user.companyId)
      .where('resourceCollection', '==', payload.resourceCollection)
      .where('resourceId', '==', payload.resourceId)
      .onSnapshot((querySnapshot) => {
        const all = querySnapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id }
        })
        console.log('Got', all, querySnapshot)
        commit('SET_RESOURCES', all)
      })
  },
  unsubscribeToSharedResources() {
    sharedResourceUnsubscription()
  },
}

export default {
  namespaced: false,
  state,
  getters,
  mutations,
  actions,
}
