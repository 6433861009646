<template>
  <transition :name="direction">
    <div
      v-if="panel"
      ref="draggableContainer"
      :key="id"
      class="sidepanel"
      :style="calculateOffset"
      :left="true"
      :data-panelid="id"
      :class="{
        floatable: floating,
        processing: processing,
        noscroll: noscroll,
        wide: wide,
        xwide: xwide,
      }"
      @keyup.ctrl.enter="logKey"
      @mouseenter="mouseenter"
      @mouseleave="mouseleave"
    >
      <div
        class="sidepanel-wrapper"
        :style="{ 'background-color': backgroundColor }"
      >
        <transition name="processing">
          <div v-if="processing" class="processing-info">
            <loading-spinner />
          </div>
        </transition>
        <div
          class="sidepanel-header"
          :style="headerStyle"
          @mousedown="dragMouseDown"
          @dblclick="resetPosition"
        >
          <slot name="extras"></slot>
          <span>{{ title }}</span>
          <button type="button" @click="done(false)">
            <RiCloseLine width="18" height="18" />
          </button>
          <button
            v-if="saveIcon"
            id="sidepanel-save-icon"
            class=""
            :disabled="saveIconDisabled"
            type="button"
            @click="$emit('save')"
          >
            <CheckIcon width="30" height="30" />
          </button>
        </div>
        <div class="sidepanel-contents" :class="{ nopad: nopadding }">
          <slot :payload="panel.payload"></slot>
        </div>
        <div v-if="doneButton" class="sidepanel-actions">
          <button type="button" @click="done(true)">Done</button>
        </div>
        <slot name="absoluteItems"></slot>
      </div>
    </div>
  </transition>
</template>

<script>
import CheckIcon from '@/icons/check.svg'
import { RiCloseLine } from 'vue-remix-icons'

export default {
  name: 'Sidepanel',
  components: {
    CheckIcon,
    RiCloseLine,
  },
  props: {
    title: {
      type: String,
    },
    id: {
      type: String,
    },
    left: {
      type: Boolean,
    },
    doneButton: {
      type: Boolean,
    },
    saveIcon: {
      type: Boolean,
      default: false,
      required: false,
    },
    saveIconDisabled: {
      tyype: Boolean,
      default: false,
      required: false,
    },
    colorFG: {
      type: String,
    },
    colorBG: {
      type: String,
    },
    processing: {
      type: Boolean,
    },
    nopadding: {
      type: Boolean,
      required: false,
      default: false,
    },
    wide: {
      type: Boolean,
      required: false,
      default: false,
    },
    xwide: {
      type: Boolean,
      required: false,
      default: false,
    },
    noscroll: {
      type: Boolean,
      required: false,
      default: false,
    },
    backgroundColor: {
      type: String,
      required: false,
      default: '#ffffff',
    },
    floating: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      floatActive: false,
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0,
      },
    }
  },
  computed: {
    direction: function () {
      if (!this.left) return 'sidepanel'
      return 'sidepanel-left'
    },
    calculateOffset: function () {
      if (!this.left)
        return {
          right: 5 * this.panelDepth + 'px',
        }
      return {
        left: '0',
      }
    },
    panelDepth: function () {
      return this.$store.getters.panelDepth(this.id)
    },
    panel: function () {
      return this.$store.getters.sidepanel(this.id)
    },
    headerStyle() {
      const styleObj = {}
      if (this.colorBG) styleObj['background-color'] = this.colorBG
      if (this.colorFG) styleObj.color = this.colorFG
      return styleObj
    },
  },
  watch: {
    panel: {
      handler(to, from) {
        const fromId = from ? from.id : ''
        if (to !== null && to.id !== fromId) {
          this.$emit('open')
          setTimeout(() => {
            const panel = document.querySelector(`[data-panelid='${this.id}']`)
            if (panel) {
              const inputs = panel.querySelectorAll('input, select, textarea')
              if (inputs.length > 0) {
                inputs[0].focus()
              }
            }
          }, 225)
        }
        if (to === null && from !== null) this.$emit('close')
      },
    },
  },
  methods: {
    logKey(e) {
      this.$emit('save')
    },
    enableFloat() {
      this.floatActive = true
      document.body.style = 'overflow: auto'
      document.getElementById('overlay').style.display = 'none'

      const container = this.$refs.draggableContainer
      container.classList.add('float-mode')
      container.classList.add('float-mode')
      container.style.top = '20px'
      container.style.right = '20px'
    },
    disableFloat() {
      this.floatActive = false
      document.body.style = 'overflow: hidden'
      document.getElementById('overlay').style.display = 'block'

      const container = this.$refs.draggableContainer
      container.classList.remove('float-mode')
      container.style.top = '0px'
      container.style.right = '0px'
      container.style.left = 'auto'
    },
    mouseenter() {
      if (!this.floatActive) return
      document.body.style = 'overflow: hidden'
    },
    mouseleave() {
      if (!this.floatActive) return
      document.body.style = 'overflow: auto'
    },
    dragMouseDown: function (event) {
      if (!this.floatActive) return
      this.$refs.draggableContainer.classList.add('float-mode-dragging')
      event.preventDefault()
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      document.onmousemove = this.elementDrag
      document.onmouseup = this.closeDragElement
    },
    elementDrag: function (event) {
      event.preventDefault()
      this.$refs.draggableContainer.classList.add('float-mode')
      this.positions.movementX = this.positions.clientX - event.clientX
      this.positions.movementY = this.positions.clientY - event.clientY
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      // set the element's new position:
      this.$refs.draggableContainer.style.top =
        this.$refs.draggableContainer.offsetTop -
        this.positions.movementY +
        'px'
      this.$refs.draggableContainer.style.left =
        this.$refs.draggableContainer.offsetLeft -
        this.positions.movementX +
        'px'
    },
    closeDragElement() {
      this.$refs.draggableContainer.classList.remove('float-mode-dragging')
      document.onmouseup = null
      document.onmousemove = null
    },
    resetPosition() {
      if (!this.floating) return
      if (this.floatActive) {
        this.disableFloat()
      } else {
        this.enableFloat()
      }
    },
    done(doneButton) {
      this.$emit('done', doneButton)
      this.removePanel()
    },
  },
}
</script>

<style lang="less">
.sidepanel {
  position: fixed;
  width: 100%;
  background-color: var(--color-white);
  top: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  z-index: var(--layer-sidepanel);
  box-shadow: 0 0 5px var(--color-black-fade20);
  transition: right 0.2s ease-out, opacity 0.2s ease 0.1s;

  /* 100vh height fix for iOS devices */
  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }

  &.floatable {
    & > .sidepanel-wrapper {
      & > .sidepanel-header {
        cursor: move;
      }
    }
    &.float-mode {
      height: calc(100vh - var(--space-x4));
      box-shadow: 0 0 5px var(--color-black-fade20),
        0 0 20px var(--color-black-fade20);

      &-dragging {
        opacity: 0.5;
      }
    }
  }

  & .processing-info {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: var(--color-white-fade80);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  & .sidepanel-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
  }

  & .sidepanel-header {
    flex: 0 var(--space-x6);
    display: flex;
    font-size: var(--text-sm);
    align-items: center;
    background-color: var(--color-brand);
    color: var(--color-white);
    z-index: 0;

    & > span {
      flex: 1;
      display: flex;
      height: 100%;
      width: 100%;
      align-items: center;
      padding-left: var(--space-x2);
      font-weight: var(--font-weight-bd);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      user-select: none;
    }

    & > button:not(.button-plain) {
      flex: 0 var(--space-x6);
      height: var(--space-x6);
      background-color: transparent;
      color: inherit;
      font-size: var(--text-xs);
      border: none;
      appearance: none;
      border-radius: 0;

      &#sidepanel-save-icon {
        background-color: var(--color-black-fade10);

        svg {
          width: var(--space-x4);
          height: var(--space-x4);
        }
      }
    }

    svg {
      width: var(--space-x3);
      height: var(--space-x3);
      fill: currentColor;
    }
  }

  & .text-header {
    margin: 0 0 0.75rem 0;
  }

  & .panel-object {
    padding: var(--space-x1);
    background-color: var(--color-grey-lighter-darken5);
    margin: var(--space-x1) calc(-1 * var(--space-x1)) var(--space-x1)
      calc(-1 * var(--space-x1));

    &:first-child {
      margin-top: calc(-1 * var(--space-x1));
    }

    & > div {
      &:not(.no-object-padding) {
        padding: calc(1.5 * var(--space-x1));
      }

      border-radius: var(--radius-sm);
      background-color: var(--color-white);
      box-shadow: 0 1px 3px var(--color-black-fade10);
      color: var(--color-black);
    }

    &.white {
      background-color: var(--color-white);

      & > div {
        box-shadow: 0 1px 5px var(--color-black-fade20);
      }
    }
  }

  &:not(.noscroll) {
    .sidepanel-contents {
      -webkit-overflow-scrolling: touch;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  &.noscroll {
    .sidepanel-contents {
      overflow: hidden;
      position: relative;
    }
  }

  & .fill-panel {
    width: 100%;
    height: 100%;
  }

  & .sidepanel-contents {
    flex: 1;

    &:not(.nopad) {
      padding: 1.5rem 1rem;
    }

    & .no-padding {
      margin: calc(-1 * var(--space-x1));
    }

    & .panel-note {
      font-size: var(--text-sm);
      margin: calc(-1 * var(--space-x1));
      margin-bottom: var(--space-x1);
      padding: var(--space-x2);
      background-color: var(--color-yellow);
    }

    & .panel-explanation {
      margin: calc(-1 * var(--space-x1));
      margin-bottom: var(--space-x1);
      padding: var(--space-x1) var(--space-x2);
      background-color: var(--color-blue-fade10);
      color: var(--color-blue);
      font-size: var(--text-xs);
      border-top: 1px solid var(--color-blue-fade10);
    }

    & .panel-actions {
      margin-top: 1rem;

      & hr {
        margin: var(--space-x2) 0;
        height: 1px;
        border: none;
        border-top: 1px solid var(--color-grey-lighter);
      }

      & button:not(.button-plain),
      & .router-button {
        display: flex;
        align-items: center;
        width: 100%;
        height: var(--space-x4);
        text-align: center;
        text-transform: uppercase;
        font-size: var(--text-xs);
        justify-content: center;
        font-weight: var(--font-weight-bd);
        border-radius: var(--radius-sm);

        &:active {
          transform: translateY(1px);
          opacity: 0.7;
        }

        &.good {
          background-color: var(--color-brand);
          color: var(--color-white);

          &.outline {
            background-color: var(--color-white);
            border: 1px solid var(--color-brand);
            color: var(--color-brand);
          }
        }

        &.secondary-button {
          background-color: var(--color-white);
          border: 1px solid var(--color-black);
          color: var(--color-black);
        }

        &:disabled {
          background-color: var(--color-grey-light);
          color: var(--color-grey);
        }

        & + button:not(.button-plain),
        & + .router-button {
          margin-top: var(--space-half);
        }
      }
    }

    & .panel-options {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      margin-bottom: var(--space-x2);

      & button:not(.button-plain),
      & .router-button {
        &.panel-option-icon-and-column {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          height: auto;
          padding: var(--space-x1) var(--space-x2);

          & svg {
            flex: 0 var(--space-x4);
          }

          & > span {
            margin-left: var(--space-half);
            display: flex;
            flex-direction: column;

            & > span {
              &:first-child {
                font-weight: var(--font-weight-bd);
              }
            }
          }
        }

        border-radius: 0;

        &:first-of-type {
          border-radius: var(--radius-sm) var(--radius-sm) 0 0;
        }

        &:last-of-type {
          border-radius: 0 0 var(--radius-sm) var(--radius-sm);

          &:first-of-type {
            border-radius: var(--radius-sm);
          }
        }

        appearance: none;
        display: flex;
        align-items: center;
        height: var(--space-x4);
        margin-bottom: 1px;
        text-align: left;
        padding: 0 var(--space-x1);
        background-color: var(--color-grey-lighter);
        border: none;
        position: relative;

        &.checkable {
          padding-left: var(--space-x4);

          &:before {
            content: '';
            width: calc(var(--space-x1) - 2);
            height: calc(var(--space-x1) - 2);
            position: absolute;
            left: calc(1.5 * var(--space-x1));
            top: calc(1.5 * var(--space-x1));
            border: 1px solid var(--color-grey-dark);
          }
        }

        &.active {
          color: var(--color-brand-darken20);
          background-color: var(--color-brand-lighten30);
          font-weight: var(--font-weight-bd);

          &:before {
            content: '';
            border-color: var(--color-brand-darken20);
            background-color: var(--color-brand-darken20);
          }
        }

        &.icon-button {
          padding-left: var(--space-x4);

          & svg {
            position: absolute;
            top: var(--space-x2);
            left: var(--space-x2);
            transform: translate(-50%, -50%);
          }
        }

        &:not(.active):hover {
          background-color: var(--color-grey-lighter-darken5);
        }

        &.two-levels {
          min-height: var(--space-x5);
          height: auto;

          & > div {
            &:last-child {
              font-size: var(--text-xs);
            }
          }
        }

        &.three-levels {
          flex-direction: column;
          padding: var(--space-half) var(--space-x1);
          min-height: var(--space-x5);
          height: auto;
          text-align: center;
          white-space: normal;

          & > div {
            &:nth-child(2) {
              font-size: var(--text-xs);
            }

            &:last-child {
              font-size: var(--text-xs);
              opacity: 0.5;
            }
          }
        }

        &.split-button {
          display: flex;
          padding: 0;
          justify-content: space-between;
          align-content: stretch;

          & > div {
            &:first-child {
              padding: var(--space-x1);
              display: flex;
              flex: 0 1 100%;
            }

            &:last-child {
              width: var(--space-x4);
              display: flex;
              justify-content: center;
              align-items: center;
              height: var(--space-x4);
              color: #800;

              &:hover {
                color: var(--color-red);
              }
            }
          }
        }
      }

      & + .panel-options {
        margin-top: var(--space-x2);
      }
    }

    .button-list {
      display: flex;
      flex-direction: column;
      gap: 1px;

      & button {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        height: auto;
        padding: var(--space-half) var(--space-x1);

        &:first-child {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }

        &:not(:first-child):not(:last-child) {
          border-radius: 0;
        }

        &:last-child {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
      }
    }
  }

  & .sidepanel-actions {
    padding: var(--space-x1);

    & button:not(.button-plain) {
      appearance: none;
      width: 100%;
      height: var(--space-x4);
      border-radius: var(--radius-sm);
      border: none;

      &.green {
        background-color: var(--color-brand);

        &.outline {
          color: var(--color-brand);
          background-color: transparent;
          border: 1px solid var(--color-brand);
        }
      }

      &.red {
        background-color: var(--color-red);

        &.outline {
          color: var(--color-red);
          background-color: transparent;
          border: 1px solid var(--color-red);
        }
      }
    }
  }
}

.panel-section + .panel-section,
.panel-section + .panel-actions {
  margin-top: 2rem;

  .panel-options {
    margin: 0;
  }
}

@media (--bp-above-sm) {
  .sidepanel {
    width: var(--size-sidepanel-rg);
    max-width: 100%;

    &.wide {
      width: var(--size-sidepanel-lg);
    }

    &.xwide {
      width: var(--size-sidepanel-xl);
    }
  }
}

#app.split-mode {
  & .sidepanel,
  & .sidepanel.wide,
  & .sidepanel.xwide {
    width: var(--size-splitmode-panel);
    box-shadow: 0 0 var(--space-x2) var(--color-black-fade30),
      0 0 var(--space-half) var(--color-black-fade50);
  }
}

.sidepanel-enter-active,
.sidepanel-leave-active {
  transition: all 0.2s ease-out;
}

.sidepanel-enter,
.sidepanel-leave-to {
  opacity: 0.5;
  transform: translateX(100%);
}

.sidepanel-left-enter-active,
.sidepanel-left-leave-active {
  transition: all 0.2s ease-out;
}

.sidepanel-left-enter,
.sidepanel-left-leave-to {
  opacity: 0.5;
  transform: translateX(-100%);
}

.processing-enter-active,
.processing-leave-active {
  transition: all 0.2s ease-out;
}

.processing-enter,
.processing-leave-to {
  opacity: 0;
}
</style>
