import { asyncForEach } from '../../vuexHelpers.js'

export const revokeBatchPermissions = async (
  { dispatch },
  { usersWithInspections }
) => {
  const actions = []
  usersWithInspections.forEach((user) => {
    user.inspections.forEach((inspectionId) => {
      actions.push({
        itemType: 'inspections',
        itemId: inspectionId,
        userId: user.userId,
      })
    })
  })
  await asyncForEach(actions, async (action) => {
    await dispatch('removePermissions', {
      type: action.itemType,
      itemId: action.itemId,
      userIds: [action.userId],
    })
  })
}
