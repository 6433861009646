import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/signin',
      name: 'signin',
      beforeEnter() {
        window.location.replace(`${import.meta.env.VUE_APP_MANAGE_URL}/signout`)
      },
    },
    {
      path: '/inspections/timeline/:inspectionId',
      name: 'InspectionTimeline',
      component: () => import('./pages/InspectionTimeline'),
      meta: {
        protected: true,
      },
      props: true,
    },
    {
      path: '/inspectionreport/:inspectionId?/:reportId?',
      name: 'InspectionReport',
      component: () => import('./pages/InspectionReport/index.vue'),
      meta: {
        protected: true,
      },
      props: true,
    },
    {
      path: '/reports/new/:inspectionId/:title',
      meta: {
        protected: true,
      },
      beforeEnter(from) {
        window.location.replace(
          `${import.meta.env.VUE_APP_MANAGE_URL}/reports/?inspectionId=${
            from.params.inspectionId
          }&title=${from.params.title}`
        )
      },
      props: true,
    },
    {
      path: '/reports/v2/:reportId',
      meta: {
        protected: true,
      },
      beforeEnter(from) {
        window.location.replace(
          `${import.meta.env.VUE_APP_MANAGE_URL}/reports/v2/${
            from.params.reportId
          }?layoutMode=preview_report`
        )
      },
      props: true,
    },
    {
      path: '/inspections/capturecreator/:inspectionId',
      name: 'CaptureCreator',
      component: () => import('./pages/CaptureCreator'),
      meta: {
        protected: true,
      },
      props: true,
    },
    {
      path: '/access-control/:projectId?',
      name: 'AccessControl',
      component: () => import('./pages/AccessControl'),
      meta: {
        protected: true,
      },
      props: true,
    },
    {
      path: '/incoming',
      name: 'Incoming',
      component: () => import('./pages/Incoming.vue'),
      meta: {
        protected: true,
      },
    },
    {
      path: '/',
      name: 'AdminDashboard',
      component: () => import('./pages/AdminDashboard'),
      meta: {
        protected: true,
      },
    },
    {
      path: '/signout',
      name: 'signout',
      component: () => import('./pages/Signout.vue'),
    },
    {
      path: '/signup',
      name: 'signup',
      beforeEnter() {
        window.location.replace(`${import.meta.env.VUE_APP_MANAGE_URL}/signout`)
      },
    },
    {
      path: '/templates',
      name: 'templates',
      component: () => import('./pages/Templates.vue'),
      meta: {
        protected: true,
      },
      props: false,
    },
    {
      path: '/template/:id?/:subtab?/:pagetab?/:pagesubtab?',
      name: 'edit template',
      component: () => import('./pages/Template'),
      meta: {
        protected: true,
      },
      props: true,
    },
    {
      path: '/projects/:subtab?',
      name: 'projects',
      component: () => import('./pages/ProjectsList'),
      meta: {
        protected: true,
      },
      props: true,
    },
    {
      path: '/projects/edit/:id?/:subsection?',
      name: 'project',
      component: () => import('./pages/ProjectEdit'),
      meta: {
        protected: true,
      },
      props: true,
    },
    {
      path: '/accounts/:subtab?',
      name: 'accounts',
      component: () => import('./pages/Accounts'),
      meta: {
        protected: true,
      },
      props: true,
    },
    {
      path: '/company/plans',
      meta: {
        protected: true,
      },
      beforeEnter() {
        window.location.replace(
          `${import.meta.env.VUE_APP_MANAGE_URL}/company/plans`
        )
      },
      props: true,
    },
    {
      path: '/company/subscription',
      meta: {
        protected: true,
      },
      beforeEnter() {
        window.location.replace(
          `${import.meta.env.VUE_APP_MANAGE_URL}/company/subscription`
        )
      },
      props: true,
    },
    {
      path: '/company/:subtab?',
      name: 'company',
      component: () => import('./pages/Company'),
      meta: {
        protected: true,
      },
      props: true,
    },
    {
      path: '/profile/:subtab?',
      name: 'profile',
      component: () => import('./pages/Profile'),
      meta: {
        protected: true,
      },
      props: true,
    },
    {
      path: '/inspections/:projectId?',
      name: 'inspections',
      component: () => import('./pages/Inspections'),
      meta: {
        protected: true,
      },
      props: true,
    },
    {
      path: '/inspections/view/:id',
      name: 'inspection',
      component: () => import('./pages/Inspection'),
      meta: {
        protected: true,
        collection: 'inspections',
      },
      props: true,
    },
    {
      path: '/reports/view/:id',
      name: 'reports-view',
      component: () => import('./pages/Reports-View'),
      meta: {
        protected: true,
      },
      props: true,
    },
    {
      path: '/reports/view-editable/:id',
      name: 'reports-view-editable',
      component: () => import('./pages/Inspection'),
      meta: {
        protected: true,
        collection: 'reports',
      },
      props: true,
    },
    {
      path: '/inspections/overview/:inspectionId/:panel?',
      name: 'inspectionoverview',
      component: () => import('./pages/InspectionOverview'),
      meta: {
        protected: true,
      },
      props: true,
    },
    {
      path: '/inspectionsettings/:id/:subtab?/:subtabactor?',
      component: () => import('./pages/InspectionSettings/index.vue'),
      meta: {
        protected: true,
      },
      props: true,
    },
    {
      path: '/assignmentplanner/:inspectionId/:assignmentId?',
      name: 'assignmentplanner',
      component: () => import('./pages/AssignmentPlanner'),
      meta: {
        protected: true,
      },
      props: true,
    },
    {
      path: '/assignmenthandler/:inspectionId/:assignmentId?',
      name: 'assignmenthandler',
      component: () => import('./pages/AssignmentHandler'),
      meta: {
        protected: true,
      },
      props: true,
    },
    {
      path: '/shared/:id',
      name: 'sharedResource',
      component: () => import('./pages/SharedResource/index.vue'),
      meta: {
        protected: false,
      },
      props: true,
    },
    {
      path: '/report-templates',
      name: 'reportTemplates',
      component: () => import('./pages/ReportTemplates.vue'),
      meta: {
        protected: true,
      },
      props: true,
    },
    {
      path: '/inspectionoverview/:inspectionId/:panel',
      redirect: '/inspections/overview/:inspectionId/:panel?',
    },
    {
      path: '/inspection/:id',
      redirect: '/inspections/view/:id',
    },
    {
      path: '/project/:id',
      redirect: '/projects/edit/:id',
    },
    {
      path: '/admindashboard',
      redirect: '/',
    },
  ],
})
