<template>
  <div>
    <transition-group name="toast">
      <div
        v-for="toast in toasts"
        :key="toast.id"
        class="toast"
        :style="calculateZ"
        :class="'toast-' + toast.type"
      >
        {{ toast.message }}
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  name: 'Toasts',
  computed: {
    toasts: function () {
      return this.$store.getters.toasts
    },
    calculateOffset: function () {
      return { 'margin-top': 25 * this.toastDepth + 'px' }
    },
    calculateZ: function () {
      return {} // { 'z-index': 1000 + this.toastDepth }
    },
    toastDepth: function () {
      return this.$store.getters.toastDepth(this.id)
    },
  },
}
</script>

<style lang="less">
.toast {
  position: fixed;
  top: var(--space-x1);
  right: var(--space-x1);
  pointer-events: none;
  background-color: var(--color-grey-darker);
  color: var(--color-white);
  padding: var(--space-x1) var(--space-x2);
  z-index: 9000;
  border-radius: var(--radius-lg);
  font-weight: var(--font-weight-bd);
  border: 2px solid var(--color-white);
  box-shadow: 0 2px 15px var(--color-black-fade10);
  &.toast-good {
    background-color: var(--color-brand);
  }
  &.toast-pending {
    background-color: var(--color-orange);
  }
  &.toast-bad {
    background-color: var(--color-red);
  }
}
.toast-enter-active,
.toast-leave-active {
  transition: all 0.2s ease-out;
}
.toast-enter {
  opacity: 0;
  transform: translateY(-100%);
}
.toast-leave-to {
  opacity: 0;
  transform: translateY(-100%);
}
</style>
