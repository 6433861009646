import { firestore } from '@/firebase'
import { apolloClient } from '@/gql/authenticatedClient'
import i18n from '@/i18n'
import router from '@/router'
import { getStructureDefault } from '@/store/templates/structureDefault'
import getTrackingFields from '@/utils/getTrackingFields'
import permissionsFromActors from '@/utils/permissionsFromActors'
import firebase from 'firebase/app'
import { buildQuery, nestTags } from '../vuexHelpers.js'
import { changeInspectionProject } from './actions/changeInspectionProject'
import { revokeBatchInvitations } from './actions/revokeBatchInvitations'
import { revokeBatchPermissions } from './actions/revokeBatchPermissions'
import { setBatchPermissions } from './actions/setBatchPermissions'

// to prevent undefined we add new
const newTagFormatFields = {
  captureCountMinimum: 0,
}

const state = {
  inspections: [],
  inspection: null,
}

const getters = {
  getInspections: (state) => state.inspections,
  getInspection: (state) => state.inspection,
}

const mutations = {
  SET_INSPECTIONS(state, arr) {
    state.inspections = arr
  },
  SET_INSPECTION(state, payload) {
    if (payload && !payload.groupBy) payload.groupBy = 'template_tags'
    state.inspection = payload
  },
  REMOVE_INSPECTION(state, id) {
    state.inspections = state.inspections.filter(
      (inspection) => inspection.id !== id
    )
  },
}

let inspectionUnsubscription

const actions = {
  changeInspectionProject,
  setBatchPermissions,
  revokeBatchPermissions(context, payload) {
    revokeBatchPermissions(context, payload)
  },
  revokeBatchInvitations(context, payload) {
    revokeBatchInvitations(context, payload)
  },
  async addTagPermission(context, payload) {
    await apolloClient.addInspectionTagPermissions({ input: payload })
  },
  addTagPermissionToInvitation(context, payload) {
    console.log('Add', payload)
    firestore
      .collection('invitations')
      .doc(payload.invitationId)
      .set(
        {
          tagPermissions: {
            tags: firebase.firestore.FieldValue.arrayUnion(payload.tagId),
            updated: Date.now(),
          },
        },
        { merge: true }
      )
  },
  async removeTagPermission({ dispatch }, payload) {
    await apolloClient.removeInspectionTagPermissions({ input: payload })
  },
  removeTagPermissionFromInvitation({ dispatch }, payload) {
    console.log('removeTagPermissionFromInvitation', payload)
    firestore
      .collection('invitations')
      .doc(payload.invitationId)
      .set(
        {
          tagPermissions: {
            tags: firebase.firestore.FieldValue.arrayRemove(payload.tagId),
            updated: Date.now(),
          },
        },
        { merge: true }
      )
  },
  setCapturePending({ dispatch, rootState }, payload) {
    dispatch('addToast', {
      message: i18n.t(
        'inspections:toast.updatingCapturePendingStatus-' + payload.value
      ),
      type: 'pending',
    })
    const batch = firestore.batch()
    payload.captures.forEach((capture) => {
      const docRef = firestore.collection('captures').doc(capture.id)
      batch.update(docRef, {
        ...getTrackingFields(
          rootState.User.user.uid,
          'stores.inspections.setCapturePending'
        ),
        pending: payload.value,
      })
    })
    batch.commit().then(() => {
      dispatch('addToast', {
        message: i18n.t(
          'inspections:toast.updatedCapturePendingStatusSuccess-' +
            payload.value
        ),
        type: 'good',
      })
    })
  },
  async setCaptureStatus({ dispatch, rootState }, payload) {
    dispatch('addToast', {
      message: i18n.t('inspections:toast.updatingCaptureStatus'),
      type: 'pending',
    })
    const batch = firestore.batch()

    payload.captures.forEach((capture) => {
      const docRef = firestore.collection('captures').doc(capture.id)
      const data = {
        ...getTrackingFields(
          rootState.User.user.uid,
          'stores.inspections.setCaptureStatus'
        ),
        labelGroups: capture.labelGroups,
      }
      batch.update(docRef, data)
    })

    try {
      await batch.commit()

      dispatch('addToast', {
        message: i18n.t('inspections:toast.updatedCaptureStatus'),
        type: 'good',
      })
    } catch (error) {
      console.error(error)
    }
  },
  async deleteInspection({ dispatch, commit }, payload) {
    try {
      dispatch('addToast', {
        message: i18n.t('inspections:deleteInspection.toast.deleting'),
        type: 'pending',
      })

      await apolloClient.deleteInspection({
        input: { id: payload.inspectionId },
      })

      dispatch('addToast', {
        message: i18n.t('inspections:deleteInspection.toast.success'),
        type: 'good',
      })
      commit('REMOVE_INSPECTION', payload.inspectionId)
      router.push(`/inspections/${payload.projectId}`).catch((err) => {
        console.error(err)
      })
    } catch (err) {
      dispatch('addToast', {
        message: i18n.t('inspections:deleteInspection.toast.deleteFailed'),
        type: 'bad',
      })
    }
  },
  async deleteCapture({ dispatch }, payload) {
    dispatch('addToast', {
      message: i18n.t('inspections:toast.deletingCapture'),
      type: 'pending',
    })
    await apolloClient.deleteCapture({ input: { id: payload.id } })
    dispatch('addToast', {
      message: i18n.t('inspections:toast.deletedCapture'),
      type: 'good',
    }).catch((err) => {
      console.error(err)
      dispatch('addToast', {
        message: i18n.t('inspections:toast.deleteCaptureFailed'),
        type: 'bad',
      })
    })
  },
  async setInspectionImage({ dispatch }, payload) {
    try {
      dispatch('addToast', {
        message: i18n.t('inspections:toast.savingInspection'),
        type: 'pending',
      })

      await apolloClient.setInspectionImage({
        input: payload,
      })

      dispatch('addToast', {
        message: i18n.t('inspections:toast.savedInspection'),
        type: 'good',
      })
    } catch (err) {
      console.log(payload, err)
      dispatch('addToast', {
        message: i18n.t('inspections:toast.failedToSaveInspection'),
        type: 'bad',
      })
    }
  },
  async removeInspectionImage({ dispatch }, payload) {
    try {
      dispatch('addToast', {
        message: i18n.t('inspections:toast.savingInspection'),
        type: 'pending',
      })

      await apolloClient.removeInspectionImage({
        input: payload,
      })

      dispatch('addToast', {
        message: i18n.t('inspections:toast.savedInspection'),
        type: 'good',
      })
    } catch (err) {
      console.log(payload, err)
      dispatch('addToast', {
        message: i18n.t('inspections:toast.failedToSaveInspection'),
        type: 'bad',
      })
    }
  },
  async updateInspectionTitle({ dispatch }, payload) {
    await apolloClient.setInspectionTitle({
      input: payload,
    })
  },
  async updateInspectionDataForm({ dispatch }, payload) {
    await apolloClient.setInspectionDataFormValues({
      input: payload,
    })
  },
  async updateInspectionGroupBy({ dispatch }, payload) {
    await apolloClient.setInspectionGroupBy({
      input: payload,
    })
  },
  async updateInspection({ dispatch }, payload) {
    // TODO: move to GQL
    if (!payload.silent) {
      dispatch('addToast', {
        message: i18n.t('inspections:toast.savingInspection'),
        type: 'pending',
      })
    }

    try {
      const docRef = firestore.collection('inspections').doc(payload.id)
      await docRef.update({
        ...payload.fields,
        id: payload.id,
        ...(!payload.keepTimeStamps && { updated: Date.now() }),
        updatedFrom: 'admin:updateInspection',
      })

      if (payload.tagsEdited)
        await dispatch('recalculateCaptureTags', { inspectionId: payload.id })

      if (!payload.silent) {
        dispatch('addToast', {
          message: i18n.t('inspections:toast.savedInspection'),
          type: 'good',
        })
      }
    } catch (err) {
      console.log(payload, err)
      dispatch('addToast', {
        message: i18n.t('inspections:toast.failedToSaveInspection'),
        type: 'bad',
      })
    }
  },
  async updateReport({ dispatch }, payload) {
    if (!payload.silent) {
      dispatch('addToast', {
        message: i18n.t('inspections:toast.savingInspection'),
        type: 'pending',
      })
    }

    try {
      const docRef = firestore.collection('reports').doc(payload.id)
      await docRef.update({
        ...payload.fields,
        id: payload.id,
        ...(!payload.keepTimeStamps && { updated: Date.now() }),
        updatedFrom: 'admin:updateInspection',
      })

      if (payload.tagsEdited)
        await dispatch('recalculateCaptureTags', { inspectionId: payload.id })

      if (!payload.silent) {
        dispatch('addToast', {
          message: i18n.t('inspections:toast.savedInspection'),
          type: 'good',
        })
      }
    } catch (err) {
      console.log(payload, err)
      dispatch('addToast', {
        message: i18n.t('inspections:toast.failedToSaveInspection'),
        type: 'bad',
      })
    }
  },
  async addInspection({ rootState }, inspection) {
    try {
      const { permissions, tagPermissions } = permissionsFromActors(
        inspection.actors
      )

      const selectedActors = inspection.actors.map((actor) => {
        const autoAssign = actor.autoAssign?.map((autoAssignee) => {
          return {
            assigneeGroup: autoAssignee.assigneeGroup.toUpperCase(),
            bypassInvitation: autoAssignee.bypassInvitation,
            id: autoAssignee.uid ?? autoAssignee.id,
            email: autoAssignee.email,
            name: autoAssignee.name,
            phoneNumber: autoAssignee.phone,
          }
        })
        return {
          autoAssign,
          enabled: actor.enabled,
          role: actor.role.toUpperCase(),
          tagIds: actor.tags,
          title: actor.title,
        }
      })

      const input = {
        addTemplateActors: false,
        permissions,
        projectId: inspection.projectId,
        selectedActors,
        tagPermissions,
        templateId: inspection.templateId,
        title: inspection.title,
      }

      // Create inspection.
      await apolloClient.createInspectionFromTemplate({
        input,
      })
    } catch (err) {
      throw new Error(err)
    }
  },
  async getProjectInspections(
    { commit, rootState },
    { projectId, returnResults }
  ) {
    let query = firestore.collection('inspections')

    if (projectId) query = query.where('projectId', '==', projectId)
    if (!projectId) query = query.limit(10)

    query = query.where('archived', '==', false)
    query = query.orderBy('updated', 'desc')

    const query1 = query.where(
      'permissionIds',
      'array-contains',
      rootState.User.user.uid
    )

    const snap1 = await query1.get()
    let inspections = snap1.docs.map((doc) => {
      return { ...doc.data(), id: doc.id }
    })

    if (
      rootState.User.user.adminRole === 'super_admin' ||
      rootState.User.user.adminRole === 'admin'
    ) {
      const query2 = query.where(
        'companyId',
        '==',
        rootState.User.user.companyId
      )
      const snap2 = await query2.get()
      const snap2docs = snap2.docs.map((doc) => {
        return { ...doc.data(), id: doc.id }
      })
      for (var i = 0; i < snap2docs.length; i++) {
        if (inspections.findIndex((doc) => doc.id === snap2docs[i].id) < 0) {
          inspections.push(snap2docs[i])
        }
      }
    }
    inspections = inspections.sort((a, b) => {
      const ta = a.updated || a.timestamp
      const tb = b.updated || b.timestamp
      if (ta < tb) return 1
      if (ta > tb) return -1
      if (ta === tb) return 0
    })
    if (returnResults) return inspections
    commit('SET_INSPECTIONS', inspections)
  },
  resetProjectInspections({ commit }) {
    commit('SET_INSPECTIONS', [])
  },
  resetInspection({ commit }) {
    commit('SET_INSPECTION', null)
  },
  subscribeToInspections({ commit, rootState }, { projectId }) {
    // console.log('Subscribing to inspections', rootState.User.user.companyId)

    let query = firestore.collection('inspections')

    if (projectId) query = query.where('projectId', '==', projectId)
    if (!projectId) query = query.limit(10)

    query = buildQuery(query, {
      scope: 'shared',
      rootState,
    })

    query = query.orderBy('updated', 'desc')

    inspectionUnsubscription = query.onSnapshot((querySnapshot) => {
      const all = querySnapshot.docs
        .map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          }
        })
        .sort((a, b) => {
          const aTime = a.updated || a.timestamp
          const bTime = b.updated || b.timestamp
          if (aTime > bTime) return -1
          if (aTime < bTime) return 1
          return 0
        })
      commit('SET_INSPECTIONS', all)
    })
  },
  unsubscribeToInspections({ commit }) {
    inspectionUnsubscription()
    commit('SET_INSPECTIONS', [])
  },
  /*,
  fetchInspections({ commit, rootState }) {
    let query = firestore
      .collection("inspections")
      .where("companyId", "==", rootState.User.user.companyId);

    if (
      rootState.User.user.adminRole !== "super_admin" &&
      rootState.User.user.adminRole !== "admin"
    )
      query = query.where(
        "permissionIds",
        "array-contains",
        rootState.User.user.uid
      );

    query = query.orderBy("updated", "desc");
    query.get().then(snap => {
      let inspections = [];
      snap.docs.forEach(tmp => {
        // console.log(tmp.data());
        inspections.push({ ...tmp.data(), id: tmp.id });
      });
      commit("SET_INSPECTIONS", inspections);
    });
  } */
  async fetchInspectionFields(context, payload) {
    const fieldsSnapshot = await firestore
      .collection('inspections')
      .doc(payload.id)
      .collection('fields')
      .get()

    if (fieldsSnapshot.empty) {
      return []
    }

    return fieldsSnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }))
  },
  async getTagNotes(context, { inspectionId, tagId, labelId }) {
    const notesSnapshot = await firestore
      .collection('inspections')
      .doc(inspectionId)
      .collection('notes')
      .where('labelId', '==', labelId)
      .where('tagId', '==', tagId)
      .get()
    return notesSnapshot.docs.map((note) => {
      return { ...note.data(), id: note.id }.sort((a, b) => a.order - b.order)
    })
  },
  async fetchInspectionTag(context, { inspectionId, tagId }) {
    const tagQuery = firestore
      .collection('inspections')
      .doc(inspectionId)
      .collection('tags')
      .doc(tagId)
    const tagSnap = await tagQuery.get()
    return { ...newTagFormatFields, ...tagSnap.data(), id: tagSnap.id }
  },
  async fetchInspectionTags(context, payload) {
    const tagsSnapshot = await firestore
      .collection('inspections')
      .doc(payload.inspectionId)
      .collection('tags')
      .get()
    if (tagsSnapshot.empty) return []

    const tags = tagsSnapshot.docs.map((tag) => ({
      ...newTagFormatFields,
      ...tag.data(),
      id: tag.id,
    }))
    if (payload.nest) return nestTags(tags).tags
    return tags
  },
  async fetchInspection({ commit, dispatch }, payload) {
    try {
      const docRef = firestore.collection('inspections').doc(payload.id)
      const inspection = await docRef.get() // CAUSES ERROR ON USER
      const tags = await dispatch('fetchInspectionTags', {
        inspectionId: payload.id,
        nest: true,
      })
      const structure = {
        ...getStructureDefault(),
        ...inspection.data().structure,
      }
      const inspectionWithTags = {
        statusChangeHistoryEnabled: true,
        ...inspection.data(),
        id: docRef.id,
        structure,
        tags,
      }

      commit('SET_INSPECTION', inspectionWithTags)
      return inspectionWithTags
    } catch (err) {
      throw new Error(err)
    }
  },
  async getStandardNotes(context, { inspection, tagId, statusId }) {
    // Flatten tags and create parent paths
    // Fix: This can be improved. Right now all tags are flattened and the correct one filtered out. Could simplify
    const flattenWithParentPath = (obj, tagPath) => {
      const array = Array.isArray(obj) ? obj : [obj]
      return array.reduce((acc, value) => {
        acc.push({ id: value.id, title: value.title, tagPath })
        if (value.tags) {
          acc = acc.concat(
            flattenWithParentPath(value.tags, [...tagPath, value.id])
          )
        }
        return acc
      }, [])
    }
    const tagsWithPaths = flattenWithParentPath(
      JSON.parse(JSON.stringify(inspection.tags)),
      []
    )
    const tagWithPath = tagsWithPaths.find((tag) => tag.id === tagId)

    // Now we have the tag with it's path, combine the tag IDs and sort them with the end tag id first and most root tag last. We use this order for sorting the notes so the tag notes come first and more distant (towards root tag) notes come later
    let tagIds = [tagId]
    if (tagWithPath) tagIds = [...tagWithPath.tagPath, tagId].reverse()

    // Build the query
    let noteQuery = firestore
      .collection('inspections')
      .doc(inspection.id)
      .collection('notes')
      .where('tagId', 'in', tagIds)
    if (statusId) noteQuery = noteQuery.where('labelId', '==', statusId)
    const noteSnapshot = await noteQuery.get()

    // Create the notes object array
    const notes = noteSnapshot.docs
      .map((note) => ({ ...note.data(), id: note.id }))
      .sort((a, b) => {
        return tagIds.indexOf(a.tagId) - tagIds.indexOf(b.tagId)
      })
    return notes
  },
  async searchInspections({ rootGetters, rootState }, { query, hitsPerPage }) {
    const algoliaClient = rootGetters['Algolia/client']
    const searchIndex = algoliaClient.initIndex(
      `inspections__${
        import.meta.env.VUE_APP_PROJECT_ID === 'captego-prod-eu'
          ? 'production'
          : 'development'
      }`
    )
    try {
      let filters
      if (rootState.User.user.adminRole === 'user') {
        // USER (non-admin)
        filters = `permissionIds:${rootState.User.user.uid}`
      } else {
        // ADMIN
        filters = `permissionIds:${rootState.User.user.uid} OR companyId:${rootState.User.user.companyId}`
      }
      const result = await searchIndex.search(query, {
        page: 0,
        hitsPerPage,
        filters,
      })

      return result.hits.map((inspection) => {
        if (inspection.id == null && inspection.objectID != null) {
          inspection.id = inspection.objectID
        }
        return inspection
      })
    } catch (err) {
      console.log('Failed to search', err)
      return err
    }
  },
  async searchInspectionsv2(
    { rootGetters, rootState },
    {
      query,
      perPage,
      page = 0,
      permissionIds = null,
      projectIds = null,
      includeCurrentUserPermissions = false,
    }
  ) {
    const algoliaClient = rootGetters['Algolia/client']
    const searchIndex = algoliaClient.initIndex(
      `inspections__${
        import.meta.env.VUE_APP_PROJECT_ID === 'captego-prod-eu'
          ? 'production'
          : 'development'
      }`
    )
    const hitsPerPage = perPage

    const createFilter = (key, values) => {
      if (!values || values.length === 0) return ''
      let filter = ''
      if (values && values.length > 0) {
        filter += ` AND (`
        values.forEach((pid, count) => {
          if (count > 0) filter += ` OR `
          filter += `${key}:${pid} `
        })
        filter += `)`
      }
      return filter
    }

    try {
      let filters
      if (includeCurrentUserPermissions) {
        filters = `(permissionIds:${rootState.User.user.uid} OR companyId:${rootState.User.user.companyId})`
      } else {
        filters = `(companyId:${rootState.User.user.companyId})`
      }

      filters += createFilter('permissionIds', permissionIds)
      filters += createFilter('projectId', projectIds)

      const params = {
        hitsPerPage,
        filters,
      }
      if (page > 0) params.page = page
      const result = await searchIndex.search(query, params)
      return result
    } catch (err) {
      console.log('Failed to search', err)
      return err
    }
  },
  async toggleHideInApp(context, { inspectionId, hideInApp }) {
    await apolloClient.setInspectionHideInApp({
      input: {
        inspectionId,
        hideInApp,
      },
    })
  },
}

export default {
  namespaced: false,
  state,
  getters,
  mutations,
  actions,
}
