export function redirectToManage() {
  const searchParams = new URLSearchParams()
  let hasUTMParams = false
  try {
    const url = new URL(document.location.href)
    ;[
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_term',
      'utm_content',
    ].forEach((searchParam) => {
      const searchParamValue = url.searchParams.get(searchParam)
      if (searchParamValue != null) {
        hasUTMParams = true
        searchParams.set(searchParam, searchParamValue)
      }
    })
  } catch (error) {
    console.error('Error while parsing utm variables', error)
  }

  document.location.href = `${import.meta.env.VUE_APP_MANAGE_URL}/signout${
    hasUTMParams ? '?' + searchParams.toString() : ''
  }`
}
