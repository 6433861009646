import { getCookie, setCookie } from '@/utils/cookies'

const COOKIE_NAME = 'navigation-expanded'

const state = {
  autoHideCategoryFieldPopper: true,
  isRootNavigationVisible: true,
  isRootNavigationExpanded: getCookie(COOKIE_NAME) !== 'false',
  sidepanels: [],
  toasts: [],
  signatureItem: null,
  currentLanguage: null,
  splitMode: false,
}

const getters = {
  autoHideCategoryFieldPopper: (state) => state.autoHideCategoryFieldPopper,
  isRootNavigationVisible: (state) => state.isRootNavigationVisible,
  isRootNavigationExpanded: (state) => state.isRootNavigationExpanded,
  sidepanels: (state) => state.sidepanels,
  sidepanelCount: (state) => state.sidepanels.length,
  panelDepth: (state) => (id) => {
    return (
      state.sidepanels.length -
      1 -
      state.sidepanels.findIndex((panel) => panel.id === id)
    )
  },
  sidepanel: (state) => (id) => {
    const panels = state.sidepanels.filter((panel) => panel.id === id)
    if (panels.length > 0) return panels[0]
    return null
  },
  toasts: (state) => state.toasts,
  toastDepth: (state) => (id) => {
    return (
      state.toasts.length -
      1 -
      state.toasts.findIndex((toast) => toast.id === id)
    )
  },
  getSignatureItem: (state) => state.signatureItem,
  getSplitMode: (state) => state.splitMode,
}

const mutations = {
  SET_AUTO_HIDE_CATEGORY_FIELD_POPPER(state, val) {
    state.autoHideCategoryFieldPopper = val
  },
  SET_ROOTNAVIGATION_VISIBILITY(state, val) {
    state.isRootNavigationVisible = val
  },
  TOGGLE_ROOTNAVIGATION_VISIBILITY(state) {
    state.isRootNavigationVisible = !state.isRootNavigationVisible
  },
  TOGGLE_ROOTNAVIGATION_EXPANDED(state) {
    state.isRootNavigationExpanded = !state.isRootNavigationExpanded
    setCookie(COOKIE_NAME, state.isRootNavigationExpanded)
  },
  ADD_PANEL(state, payload) {
    if (
      state.sidepanels.filter((elem) => elem.id === payload.id).length === 0
    ) {
      state.sidepanels.push(payload)
    } else {
      console.log(payload)
      state.sidepanels = state.sidepanels.filter(
        (panel) => panel.id !== payload.id
      )
      setTimeout(() => {
        state.sidepanels.push(payload)
      }, 200)
    }
  },
  REMOVE_PANEL(state, payload) {
    if (payload.panelId) {
      state.sidepanels = state.sidepanels.filter(
        (panel) => panel.id !== payload.panelId
      )
    } else {
      if (state.sidepanels.length > 0) state.sidepanels.splice(-1, 1)
    }
  },
  REMOVE_ALL_PANELS(state) {
    state.sidepanels.splice(0, state.sidepanels.length)
  },
  ADD_TOAST(state, payload) {
    payload.id = Math.floor(Math.random() * 100000000)
    state.toasts.push(payload)
    setTimeout(() => {
      const index = state.toasts.findIndex((toast) => toast.id === payload.id)
      state.toasts.splice(index, 1)
    }, 2000)
  },
  REMOVE_TOAST(state) {
    state.toasts.splice(-1, 1)
  },
  REMOVE_ALL_TOASTS(state) {
    state.toasts.splice(0, state.toasts.length)
  },
  SET_SIGNATURE_ITEM(state, payload) {
    state.signatureItem = payload
  },
  SET_SIGNATURE_ITEM_VALUE(state, payload) {
    state.signatureItem.value = payload || ''
    const theTime = new Date()
    state.signatureItem.meta.timestamp = theTime.getTime()
  },
  TOGGLE_SPLITMODE(state, value) {
    if (value) return (state.splitMode = value)
    state.splitMode = !state.splitMode
  },
}

const actions = {
  toggleSplitMode({ commit }, value) {
    if (value) return commit('TOGGLE_SPLITMODE', value)
    commit('TOGGLE_SPLITMODE')
  },
  setLanguage({ commit }, payload) {
    commit('SET_LANGUAGE', payload)
  },
  setSignatureItem(context, payload) {
    context.commit('SET_SIGNATURE_ITEM', payload)
  },
  setSignatureItemValue(context, payload) {
    context.commit('SET_SIGNATURE_ITEM_VALUE', payload)
  },
  setAutoHideCategoryFieldPopper(context, payload) {
    setTimeout(() => {
      context.commit('SET_AUTO_HIDE_CATEGORY_FIELD_POPPER', payload)
    }, 100)
  },
  setAutoHideCategoryFieldPopperImmediate(context, payload) {
    context.commit('SET_AUTO_HIDE_CATEGORY_FIELD_POPPER', payload)
  },
  toggleRootNavigationVisibility(context) {
    context.commit('TOGGLE_ROOTNAVIGATION_VISIBILITY')
  },
  showRootNavigation(context) {
    context.commit('SET_ROOTNAVIGATION_VISIBILITY', true)
  },
  hideRootNavigation(context) {
    context.commit('SET_ROOTNAVIGATION_VISIBILITY', false)
  },
  toggleRootNavigationSize(context) {
    context.commit('TOGGLE_ROOTNAVIGATION_EXPANDED')
  },
  addPanel: function (context, payload) {
    context.commit('ADD_PANEL', payload)
  },
  removePanel: function (context, payload) {
    context.commit('REMOVE_PANEL', payload)
  },
  removeAllPanels: function (context) {
    context.commit('REMOVE_ALL_PANELS')
  },
  addToast: function (context, payload) {
    context.commit('ADD_TOAST', payload)
  },
  removeToast: function (context) {
    context.commit('REMOVE_TOAST')
  },
  removeAllToasts: function (context) {
    context.commit('REMOVE_ALL_TOASTS')
  },
}

export default {
  namespaced: false,
  state,
  getters,
  mutations,
  actions,
}
