const state = {
  ranges: [],
  tagsMap: null,
}

function getTagChildren(tagId, tagsFlattened) {
  // Get all child tag IDs into an array
  let result = [tagId]
  tagsFlattened[tagId].tags.forEach((tag) => {
    result.push(tag.id)
    if (tag.tags) {
      result = result.concat(getTagChildren(tag.id, tagsFlattened))
    }
  })
  return result
}

const getters = {
  getCaptures: (state, getters, rootState) => (includeChildren) => {
    let captures = []

    // Go through all ranges and filter out the captures that are within each range
    state.ranges.forEach((range) => {
      const rangeCaptures = rootState.Captures.captures
        .filter(
          (capture) =>
            (includeChildren
              ? getTagChildren(range.tagId, state.tagsMap).indexOf(
                  capture.tagId
                ) > -1
              : range.tagId === capture.tagId) &&
            capture.timestamp < range.timestampEnd &&
            capture.timestamp > range.timestampStart
        )
        .sort((a, b) => a.timestamp - b.timestamp)

      captures = captures.concat(rangeCaptures)
    })
    // Remove duplicates
    return captures.reduce((acc, current) => {
      const x = acc.find((item) => item.id === current.id)
      if (!x) {
        return acc.concat([current])
      } else {
        return acc
      }
    }, [])
  },
  getRanges: (state) => (tagId) =>
    state.ranges.filter((range) => range.tagId === tagId),
  getTagsMap: (state) => state.tagsMap,
}

const mutations = {
  ADD_RANGE(state, range) {
    state.ranges.push(range)
  },
  REMOVE_RANGE(state, rangeId) {
    state.ranges = state.ranges.filter((range) => range.id !== rangeId)
  },
  CLEAR_RANGES(state) {
    state.ranges = []
  },
  SET_TAGS_MAP(state, tagsMap) {
    state.tagsMap = tagsMap
  },
}

const actions = {
  addTagRange({ commit }, range) {
    commit('ADD_RANGE', range)
  },
  removeTagRange({ commit }, { rangeId }) {
    commit('REMOVE_RANGE', rangeId)
  },
  clearRanges({ commit }) {
    commit('CLEAR_RANGES')
  },
  createTagMap({ commit }, inspection) {
    // Flatten the entire nested tag array so it's ready to become flat documents and add order attribute
    function flat(array) {
      let result = []
      array.forEach(function (a, index) {
        result.push({ ...a, order: index })
        if (Array.isArray(a.tags)) {
          result = result.concat(flat(a.tags))
        }
      })
      return result
    }
    const tagsFlattened = flat(inspection.tags).reduce((obj, item) => {
      return {
        ...obj,
        [item.id]: item,
      }
    }, {})
    commit('SET_TAGS_MAP', tagsFlattened)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
